.wrapper {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}



.backdrop {
  position: fixed;
  z-index: -1;
  background: rgba(0, 0, 0, .2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.open {
  display: block;

}

.modalScroll {
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  position: relative;
  display: flex;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.modal {
  max-width: 680px;
  width: 100%;
  background: #ffffff;
  padding: 25px;
  z-index: 2;
  margin: auto;
  border-radius: 20px;
}
.closeWrapper {
  display: flex;
  justify-content: flex-end;
  button {
    background: transparent;
    cursor: pointer;
    border: none;
  }
}

.content {
  display: flex;
}

.buttons {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-right: 20px;
  button {
    margin-bottom: 10px;
    background: transparent;
    border: none;
    border-left: 5px solid #ffffff;
    font-size: 14px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    cursor: pointer;
    &.active {
      border-left: 5px solid green;
    }
  }
}

.text {
  flex: 1 1 auto;
  font-size: 14px;
}

.title {
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    min-width: 150px;
    margin-left: 5px;
    background: #000000;
    color: #ffffff;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    border-radius: 26px;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 1;
    &.outlined {
      background: #ffffff;
      color: #000000;
      border: 1px solid #000000;
    }
  }
}

.moreRow {
  display: flex;
}
.moreTitle {
  font-weight: 700;
  margin-right: 10px;
}

.moreButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.moreButton {
  display: block;
  background: transparent;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
}
