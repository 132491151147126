@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-tabs/style/react-tabs.css";

i {
    font-style: italic;
}
/**
* ----------------------------------------------
* Spinner
* ----------------------------------------------
**/
.ipl-progress-indicator.available {
    opacity: 0;
}
.ipl-progress-indicator {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    pointer-events: none;
    -webkit-transition: opacity cubic-bezier(.4, 0, .2, 1) 436ms;
    -moz-transition: opacity cubic-bezier(.4, 0, .2, 1) 436ms;
    transition: opacity cubic-bezier(.4, 0, .2, 1) 436ms;
    z-index: 9999;
}
.insp-logo-frame {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-animation: fadein 436ms;
    -moz-animation: fadein 436ms;
    animation: fadein 436ms;
    height: 98%;
}
.insp-logo-frame-img{
    width: 100px;
    -webkit-align-self: center;
    -moz-align-self: center;
    align-self: center;
}
.ipl-progress-indicator-head {
    background-color: #ffb9c8;
    height: 4px;
    overflow: hidden;
    position: relative;
}
.ipl-progress-indicator .first-indicator, .ipl-progress-indicator .second-indicator {
    background-color: #C8002A;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    transform: scaleX(0);
}
.ipl-progress-indicator .first-indicator {
    -webkit-animation: first-indicator 2000ms linear infinite;
    -moz-animation: first-indicator 2000ms linear infinite;
    animation: first-indicator 2000ms linear infinite;
}
.ipl-progress-indicator .second-indicator {
    -webkit-animation: second-indicator 2000ms linear infinite;
    -moz-animation: second-indicator 2000ms linear infinite;
    animation: second-indicator 2000ms linear infinite;
}
.ipl-progress-indicator .insp-logo {
    animation: App-logo-spin infinite 20s linear;
    border-radius: 50%;
    -webkit-align-self: center;
    -moz-align-self: center;
    align-self: center;
}
@keyframes App-logo-spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
@-webkit-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
}
@-moz-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes first-indicator {
    0% {transform: translate(0%) scaleX(0);}
    25% {transform: translate(0%) scaleX(0.5);}
    50% {transform: translate(25%) scaleX(0.75);}
    75% {transform: translate(100%) scaleX(0);}
    100% {transform: translate(100%) scaleX(0);}
}
@keyframes second-indicator {
    0% {transform: translate(0%) scaleX(0);}
    60% {transform: translate(0%) scaleX(0);}
    80% {transform: translate(0%) scaleX(0.6);}
    100% {transform: translate(100%) scaleX(0.1);}
}
/**
* ----------------------------------------------
* Global
* ----------------------------------------------
**/
ul{
    vertical-align: bottom;
}
.logo__img {
    margin-top: 10px;
}
a.link {
    line-height: 2rem;
    font-size: 1.3rem;
    color: #C8002A;
    text-decoration: underline;
}
a.product-table-item-title__link {
    line-height: 2rem;
}
.order-summary-item__value-small {
    display: block;
    font-size: 14px;
}
.section-header__recommended {
    padding-bottom: 1rem;
}
.articleGroupsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.page-header__img {
    width: 20%;
    margin-right: 1.5rem;
}
.page-header__desc {
    width: 80%;
}
@media (max-width: 768px) {
    .articleGroupsContainer {
        flex-direction: column;
    }
    .page-header {
        padding-top: 10px;
        padding-bottom: 27px;
    }
    .page-header__img {
        width: 100%;
        margin-right: 0;
    }
    .page-header__desc {
        width: 100%;
    }
}
.category-nav {
    max-width: 1100px;
    margin: 0 auto;
}
a.ant-notification-notice-close {
    text-decoration: none;
}

@media screen and (min-width: 48em) {
    .logo {
        width: 150px;
        position: absolute;
        bottom: -3px;
        z-index: 99; }
    .logo__img {
        margin-top: 0;
    }
}
/**
* ----------------------------------------------
* Buttons and links
* ----------------------------------------------
**/
.link-button.active {
    background: #F4F5F5;
    color: #686868;
}
.product-card-actions__col-two, .product-actions__col-two, .confirm-content__button, .sidecart-content-action__button, .icon-list, .icon-gallery, .field-content__button, .link-button, .product-table-item-add-remove__svg {
    cursor: pointer;
}
.category-nav .link-button {
    margin: 0.5rem 0.3rem;
}
.sidecart-close {
    cursor: pointer;
}
.link-button-tickets {
    margin-top: 30px;
}
.information-address__button:hover .link-button__name {
    color: #C8002A;
}
.information-address__button:hover {
    border: 1px solid #C8002A;
    cursor: pointer;
}
.save-button {
    margin-right: 2em;
}
.button__signup {
    display: block;
    width: 100%;
    text-decoration: none;
}
.button__signup:hover {
    color: #ffffff;
}
.edit-button {
    margin-top: 1.5rem;
}
.link-button__loadmore {
    margin-top: 20px;
}

.panel-header__link:hover {
    cursor: pointer;
    text-decoration: underline;
}
.ant-btn:hover, .ant-btn:focus {
    color: #40a9ff;
    border: 1px solid #40a9ff;
}
/**
* ----------------------------------------------
* Spinner
* ----------------------------------------------
**/
.spinner {
    text-align: center;
    padding: 20px 0;
}
.ant-spin-dot i {
    background-color: #C8002A;
}
.ant-spin {
    color: #C8002A;
}
/**
* ----------------------------------------------
* Header
* ----------------------------------------------
**/
.help-nav-list__item-col {
    display: inline-block;
}
.help-nav-list.language-list {
    display: inline-block;
    padding-top: 10px;
}
.help-nav-list.loomis {
    display: inline-block;
}
.language-list a {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    padding: 0 5px;
    border-right: 1px solid #ffffff;
}
.language-list a:last-child {
    border-right: none;
}
.language-list a.active, .language-list a:hover {
    color: #ffffff;
    text-decoration: underline;
}
/**
* ----------------------------------------------
* Footer
* ----------------------------------------------
**/
.footer-contact-list-icon__svg {
    height: auto;
    width: 15px;
    vertical-align: middle;
    margin-right: 0.3rem;
}
.footer-cookie__link {
    color: #ffffff;
}
.footer-cookie__link:hover {
    text-decoration: underline;
}
/**
* ----------------------------------------------
* Mobile menu
* ----------------------------------------------
**/
.mobile-menu {
    display: none;
    font-size: 1.5rem;
    line-height: 1.8;
}
.mobile-menu a {
    text-decoration: none;
}
.mobile-menu__top {
    margin-bottom: 1.8rem;
}
.mobile-menu__bottom {
    /* position: absolute; */
    bottom: 0;
    padding: 16px 0;
}
.mobile-menu__link {
    display: block;
    color: #2A2A2A;
    padding: 5px 0;
    /*border-bottom: 1px solid #eee;*/
}
.mobile-menu__link:hover, .mobile-menu__link:active {
    color: #C8002A;
}
.mobile-menu__top .language-list {
    float: right;
}
.mobile-menu__top .language-list a {
    color: #686868;
    border-right: 1px solid #686868;
}
.mobile-menu__top .language-list a.active, .mobile-menu__top .language-list a:hover {
    color: #C8002A;
}
.mobile-menu__top .language-list a:last-child {
    border-right: none;
}
.mobile-menu__top-item {
    display: inline-block;
}
.mobile-menu__top-item .help-nav-list-icon {
    display: inline-block;
    margin-right: 5px;
}
.mobile-menu__top-item .help-nav-list__text {
    margin-bottom: 12px;
}
.mobile-menu__close {
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .disableOnMobile {
        display: none;
    }
    .mobile-menu {
        display: block;
        position: fixed;
        top: 0;
    }
    .bm-overlay {
        position: fixed;
        top: 0;
    }
}
/**
* ----------------------------------------------
* Sidebar
* ----------------------------------------------
**/
.main-content__sidebar .order-actions a.link {
    color: #ffffff;
}
.order-actions-wrapper {
    display: flex;
}
.order-actions-section {
    flex: 1 0 0;
    margin-right: 1rem;
}
.order-actions__title {
    margin-bottom: 0;
}
.order-actions-section {
    padding-top: 0;
}
@media (max-width: 768px) {
    .order-actions-wrapper {
        flex-direction: column;
    }
}
/**
* ----------------------------------------------
* Modal window
* ----------------------------------------------
**/
.ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover, .ant-btn-primary:active {
    background-color: #C8002A;
    border: 1px solid #C8002A;
    color: #ffffff;
}
/*Modal window end*/
.ant-menu-submenu-title {
    color: #000000;
    font-weight: 700;
    font-size: 1.6rem;
}
.ant-menu-submenu-title:hover, .ant-menu-item:hover {
    color: #000000;
}
/**
* ----------------------------------------------
* Alerts
* ----------------------------------------------
**/
.checkAddressAlert {
    margin-bottom: 20px;
}

/**
* ----------------------------------------------
* Accordion
* ----------------------------------------------
**/
.product-information .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #000;
    line-height: 1.5rem;
    font-size: 1.198rem;
    font-weight: 700;
}

/**
* ----------------------------------------------
* NavTabs
* ----------------------------------------------
**/
.nav-tabs-list-text {
    font-size: 1.2rem;
}
.nav-tabs-icon {
    margin-right: 0.5rem;
    vertical-align: middle;
}
.react-tabs__tab {
    bottom: -1px;
}
@-moz-document url-prefix() {
    .react-tabs__tab {
        bottom: 0;
    }
}
/**
* ----------------------------------------------
* Product card
* ----------------------------------------------
**/
/*Fix product cart hover*/
.products--gallery .product-card:hover .product-card-actions {
    position: absolute;
    bottom: -1.1rem;
    margin-bottom: 20px;
}
.products--gallery .product-card:hover {
    box-shadow: 0px 17px 35px rgba(74, 74, 115, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.07);
}
.products--slider .product-card:hover .product-card-actions {
    position: absolute;
    bottom: -1.1rem;
    margin-bottom: 20px;
}
.products--slider .product-card:hover {
    box-shadow: 0px 17px 35px rgba(74, 74, 115, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.07);
}
/*Fix product cart hover end*/
.product-card-image {
    display: flex;
    align-items: center;
    max-height: 300px;
    height: 300px;
    margin-bottom: 10px;
    overflow: hidden;
    margin-top: 1.57143rem;
}
.product-card-image img {
    display: block;
    width: 100%;
    height: auto;
}
@media screen and (max-width: 992px) {
    .product-card-image {
        height: 250px;
    }
}
@media screen and (max-width: 768px) {
    .product-card-image {
        height: 220px;
        margin-bottom: 5px;
    }
}

.main-content__secondary--bg {
    padding-bottom: 80px;
}
.nopad {
    padding-bottom: 0;
}
/**
* ----------------------------------------------
* Image gallery
* ----------------------------------------------
**/
.image-gallery-image {
    position: relative;
    cursor: pointer;
}

.image-gallery-image:hover::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    width: 50px;
    height: 50px;
    background: url(../img/magnifier.svg) center center no-repeat;
    background-size: cover;
    z-index: 10;
}
.image-gallery-image:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    background-size: 100%;
    z-index: 9;
}

.image-gallery-image img {
    display: block;
    margin: 0 auto;
    width: auto;
    max-height: 400px;
}
.image-gallery-thumbnails {
    margin-top: 1.5rem;
}
.image-gallery-thumbnail {
    width: 6rem!important;
    max-height: 6rem;
    overflow: hidden;
}
@media screen and (max-width: 500px) {
    .image-gallery-image img {
        max-height: 300px;
    }
}
.prev-thumb, .next-thumb {
    z-index: 10;
    cursor: pointer;
    background: transparent;
}
.next-thumb {
    right: 0;
}
.prev-thumb {
    left: 0;
}
.badge {
    margin-bottom: 5px;
}
.product-card-actions {
    position: absolute;
    bottom: -0.1rem;
}
.section-header__sub-title {
    font-size: 1.93829rem;
    margin-bottom: 0.13962rem;
    font-weight: 400;
}
.service-link {
    color: #686868;
    text-decoration: underline;
}
.service-link:hover {
    text-decoration: none;
}
.sidebar-nav {
    display: block;
}
/**
* ----------------------------------------------
* Form
* ----------------------------------------------
**/
.ant-input {
    height: auto;
    border-radius: 0;
}
.ant-input:focus {
    box-shadow: none;
}
.ant-select-selection {
    border-radius: 0;
    border: 1px solid #C9CDCF;
    line-height: 2.25rem;
    font-size: 1.45238rem;
    color: #2A2A2A;
    font-weight: 400;
}
.ant-select-lg .ant-select-selection--single {
    padding: 0.7rem .95238rem;
    height: auto;
    line-height: 2.25rem;
}
.ant-select-selection__rendered {
    margin-right: 0;
}
.ant-select-selection:hover, .ant-select-focused .ant-select-selection:focus, .ant-select-focused .ant-select-selection:active  {
    border-color: #FD4F00;
    box-shadow: none;
}
.ant-checkbox-wrapper{
    margin-right: 5px;
}

div.has-error {
    position: relative;
}
div.has-error .field-content__input {
    border: 1px solid #ffa39e;
    background: #fff1f0;
}
div.has-error .help-block, .input-required {
    position: absolute;
    display: block;
    top: 30%;
    right: 0;
    color: red;
    font-size: 1.2rem;
    margin-right: 10px;
    vertical-align: middle;
}
.help-block:after {
    content: '\0021';
    display: inline-block;
    margin-left: 5px;
    background: #f5222d;
    width: 20px;
    height: 20px;
    color: #ffffff;
    font-size: 10px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    text-align: center;
}
.input-required:after {
    content: '\0021';
    display: inline-block;
    margin-left: 5px;
    background: #FD4F00;
    width: 20px;
    height: 20px;
    color: #ffffff;
    font-size: 10px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    text-align: center;
}
#postalAddressModal div.has-error .help-block, #deliveryAddressModal div.has-error .help-block {
    font-size: 0;
}
.checkout-step-content-wrapper__col-two {
    margin-top: -5px;
}
.error-label {
    display: block;
    margin-top: -15px;
    color: red;
    font-size: 1.2rem;
}

.field-content--25, .field-content--50, .field-content--75 {
    float: none;
    display: inline-block;
}

.field-content__input + label {
    position: absolute;
    top: 20px;
    width: 100%;
    transition: 0.2s;
    font-size: 1.45238rem;
    font-weight: 400;
    opacity: 1;
}

.field-content__input:focus + label {
    position: absolute;
    top: 1px;
    font-size: 12px;
}
.field-content__input:valid + label {
    position: absolute;
    top: 1px;
    font-size: 12px;
}
input[required] {
    border: 1px #C9CDCF solid;
    outline: none;
    box-shadow: none;
}

/**
* ----------------------------------------------
* Products slider
* ----------------------------------------------
**/
/*Fix product cart hover*/
.products-slider .product-card:hover .product-card-actions {
    position: absolute;
    bottom: -1.1rem;
    margin-bottom: 20px;
}
.products-slider .product-card:hover {
    box-shadow: 0px 17px 35px rgba(74, 74, 115, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.07);
}
.products-slider .product-card:hover .product-card-actions {
    position: absolute;
    bottom: -1.1rem;
    margin-bottom: 20px;
}
.products-slider .product-card:hover {
    box-shadow: 0px 17px 35px rgba(74, 74, 115, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.07);
}
/*Fix product cart hover end*/

.products-slider .product-card {
    width: 80%;
    max-width: 295px;
    float: none;
    margin: 20px auto 50px auto;
}
/* Center products in the recommended section (Home page) */
.products--gallery .products--recommended {
    display: flex!important;
    justify-content: center!important;
}
.products--gallery .products--recommended > .product-card {
    margin-right: 15px!important;
    margin-left: 15px!important;
}
@media (max-width: 768px) {
    .products--gallery .products--recommended {
        display: block!important;
    }
    .products--gallery .products--recommended > .product-card {
        margin-right: 5px!important;
        margin-left: 5px!important;
    }
}
@media (max-width: 575px) {
    .products--gallery .products--recommended > .product-card {
        width: 70%;
        margin-right: auto!important;
        margin-left: auto!important;
        float: none!important;
    }
}

/**
* ----------------------------------------------
* Sidenav
* ----------------------------------------------
**/
.bm-burger-button {
    display: none;
}
.bm-menu-wrap {
    position: fixed;
}
.bm-menu {
    background: #ffffff;
    padding: 1.90476rem 1.90476rem;
}
.bmOverlay {
    background: rgba(0, 0, 0, 0.3);
}
/**
* ----------------------------------------------
* Accordion
* ----------------------------------------------
**/
.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item--has-icon {
    position: relative;
}

.accordion__title {
    cursor: pointer;
    padding: 0.5934rem 0;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__body {
    display: block;
    animation: fadein 0.35s ease-in;
}

.accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
    margin-bottom: 0;
}

.accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
}

.svg-plus {
    display: block;
}
.svg-minus {
    display: none;
}

[aria-selected='true'] .svg-plus {
    display: none;
}
[aria-selected='true'] .svg-minus {
    display: block;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes move-down {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

.accordion__title--animated:hover .accordion__arrow {
    animation-name: move-down;
    animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    animation-name: move-up;
    animation-duration: 1.5s;
}

/**
* ----------------------------------------------
* Account/ orders page
* ----------------------------------------------
**/
.panel--order-items .panel-header__col-one,
.panel--order-items .panel-header__col-two,
.panel--order-items .panel-header__col-three,
.panel--order-items .panel-header__col-four,
.panel--order-items .panel-header__col-five,
.panel--order-items .panel-header__col-six,
.panel--order-items .panel-header__col-seven {
    width: 15.666%;
    display: table-cell;
    vertical-align: top;
    padding: 1.42857rem;
}
@media screen and (max-width: 768px) {
    .panel--order-items .panel-header__col-one,
    .panel--order-items .panel-header__col-two,
    .panel--order-items .panel-header__col-three,
    .panel--order-items .panel-header__col-four,
    .panel--order-items .panel-header__col-five,
    .panel--order-items .panel-header__col-six,
    .panel--order-items .panel-header__col-seven {
        width: 100%;
        display: block;
    }
}
/**
* ----------------------------------------------
* Login page
* ----------------------------------------------
**/
.my-account-login::before {
    display: none;
}
.my-account-login {
    margin-top: 50px;
}
/**
* ----------------------------------------------
* IE 11 fix
* ----------------------------------------------
**/
@media all and (-ms-high-contrast:none)
{
    .nav-tabs-list-icon__svg { height: auto } /* other browsers */
    *::-ms-backdrop, .nav-tabs-list-icon__svg { height: 15px } /* IE11 */

    .paging-list__svg { height: auto } /* other browsers */
    *::-ms-backdrop, .paging-list__svg { height: 8px } /* IE11 */

    .help-nav-list-icon {height: auto } /* other browsers */
    *::-ms-backdrop, .help-nav-list-icon { height: 24px} /* IE11 */

    .link-button__svg { height: auto } /* other browsers */
    *::-ms-backdrop, .link-button__svg { height: 9px} /* IE11 */

    .footer-contact-list-icon__svg {height: auto} /* other browsers */
    *::-ms-backdrop, .footer-contact-list-icon__svg { height: 15px} /* IE11 */
}
/**
* ----------------------------------------------
* Home page / active campaigns
* ----------------------------------------------
**/
.spot-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.spot-item {
    max-width: 45%;
    width: 45%;
    padding: 2rem;
    margin: 1rem;
    border: 1px solid #C9CDCF;
    border-radius: 4px;
}
.spot-image {
  margin: 0 auto;
}
.spot-image > .spot-img {
    display: block;
    max-height: 300px;
    margin: 0 auto;
}
.spot-content {
    text-align: center;
    padding-top: 2rem;
}
.sidecart-campaigns__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 3rem 0;
}

@media screen and (max-width: 992px) {
    .spot-wrapper, .sidecart-campaigns__wrapper {
        flex-direction: column;
    }
    .spot-item, .sidecart-campaigns__wrapper > .spot-item {
        max-width: 100%;
        width: 100%;
        margin: 1rem 0;
    }
}
/**
* ----------------------------------------------
* Cookie warning
* ----------------------------------------------
**/
.cookie-warning.react-cookie-banner {
    display: flex;
    justify-content: space-between;
    font-family: Lato, sans-serif!important;
    width: 100%!important;
    height: auto!important;
    padding: 30px!important;
    background-color: rgba(0, 0, 0, 0.8)!important;
    background-image: url(../img/cookie.png);
    background-position: 20px 50%;
    background-repeat: no-repeat;
    background-size: 30px 30px!important;
    position: fixed!important;
    bottom: 0!important;
    left: 0!important;
}
.cookie-warning.react-cookie-banner .button-close {
    flex: 0 1 auto!important;
    position: static!important;
    margin-top: 0!important;
    padding: 10px 30px!important;
    background-color: #C8002A!important;
    color: #fff!important;
}
.cookie-warning.react-cookie-banner .cookie-message {
    flex: 2 1 auto!important;
    text-align: left!important;
    padding: 0 80px;
    font-size: 16px;
    line-height: 25px!important;
}
@media (max-width: 768px) {
    .cookie-warning.react-cookie-banner {
        flex-direction: column;
        background-image: none;
    }
    .cookie-warning.react-cookie-banner .cookie-message {
        width: 100%!important;
        display: block!important;
        position: static!important;
        padding: 0 20px;
    }
    .cookie-warning.react-cookie-banner .button-close {
        margin: 0 20px!important;
        display: block!important;
        position: static!important;
        margin-top: 20px!important;
    }
}
/**
* ----------------------------------------------
* Select store Page
* ----------------------------------------------
**/
.selectStore-form {
    position: relative;
    width: 100%;
}
.selectStore-input {
    width: 100%;
    height: auto;
    border: 1px solid #dddddd;
    font-size: 16px;
    font-weight: 400;
    padding: 15px;
}
.selectStore-input:focus, .selectStore-input:hover, .selectStore-input:active {
    border: 1px solid #FD4F00;
}
.selectStore-submit {
    position: absolute;
    top: 50%;
    right: 15px;
    background-color: transparent;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}
.selectStore_results {
    margin: 70px 0 0 0;
}
.results__item {
    display: flex;
    width: 100%;
    flex-direction: row;
    color: #000;
    background-color: #ffffff;
    border-top: 1px solid #dddddd;
    padding: 7px 7px 7px 20px;
}
.results__item-bg {
    background-color: #F4F5F5;
}
.results__header {
    text-decoration: none;
}
.results__header-id, .results__header-name {
    color: #000;
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
}
.results__item .col__one {
    width: 60%;
}
/**
* ----------------------------------------------
* Pagination
* ----------------------------------------------
**/
.pagination-wrapper {
    margin-top: 50px;
    text-align: center;
}
.ant-pagination-item {
    width: 45px;
    height: 45px;
    margin: 0;
    border-radius: 0;
    border: 1px solid #CACDCF;
    border-left: 0;
    line-height: 20px!important;
}
.ant-pagination-item a {
    font-family: 'Lato', sans-serif!important;
    color: #2A2A2A;
    font-size: 13px;
    line-height: 32px;
    font-weight: 700;
    text-decoration: underline;
}
/** Prev/next buttons **/
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    width: 45px;
    height: 45px;
    margin: 0;
    border-radius: 0;
    border: 1px solid #CACDCF;
}
.ant-pagination-prev {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.ant-pagination-next {
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.ant-pagination-jump-prev, .ant-pagination-jump-next {
    border-left: 0;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    font-family: 'Lato', sans-serif!important;
    color: #2A2A2A;
    text-decoration: none!important;
    border: none;
}
/** Active item **/
.ant-pagination-item-active {
    background-color: #d9d9d9!important;
    border-color: #d9d9d9!important;
}
.ant-pagination-item-active a {
    color: #ffffff!important;
}
/** Hovers **/
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    background-color: #d9d9d9!important;
    border-color: #d9d9d9!important;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
    color: #ffffff!important;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:active .ant-pagination-item-link,
.ant-pagination-next:active .ant-pagination-item-link {
    background-color: transparent;
    color: #2A2A2A;
}
.ant-pagination-prev:hover,
.ant-pagination-next:hover,
.ant-pagination-prev:active,
.ant-pagination-next:active {
    background-color: #d9d9d9!important;
    border-color: #d9d9d9!important;
}
.ant-pagination-jump-prev:focus:after,
.ant-pagination-jump-next:focus:after,
.ant-pagination-jump-prev:hover:after,
.ant-pagination-jump-next:hover:after {
    color: #2A2A2A;
}
/**
* ----------------------------------------------
* Homepage Slider
* ----------------------------------------------
**/
.hero {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
}
.hero-content {
    position: relative;
    width: 33%;
    order: 1;
    padding: 90px 0;
}
.hero-image {
    display: block;
    width: 67%;
    height: auto;
}
.hero-image-content {
    display: block;
}

.hero-content__certificate {
    margin-top: 30px;
}

.hero-image:before {
    padding-top: 0;
    display: none;
}
.hero-slider {
    height: 100%;
}
.hero-slider .slick-list, .hero-slider .slick-track, .hero-slider .slick-slider .slick-track {
    height: 100%!important;
}
.hero-slider .prev-thumb {
    left: 55px;
}
.hero-slider .slick-dots {
    bottom: 5px;
    z-index: 99;
}
.hero-slider .slick-prev::before, .hero-slider .slick-next::before {
    color: #565656;
}

.hero-image__slide {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.hero.hero--solid {
    background: #C8002A;
}
@media(max-width: 768px) {
    .hero {
        flex-direction: column;
    }
    .hero-content, .hero-image {
        width: 100%;
        height: 100%
    }
    .hero-image {
        display: none;
    }
    .hero-content {
        width: 100%;
    }
    .hero-content__certificate {
        left: 0;
    }
}

/**
 Tabs
 */
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    background: #3F4C52 !important;
    border-color: #e8e8e8;
    color: #fff !important;
    padding-bottom: 1px;
}
/**
 Sidebar categories
 */
.main-content__products {
    display: flex;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
}
.primary-products {
    width: 75%;
}
.sidebar-categories {
    border-radius: 12px;
    margin: 40px 40px 0 0;
    width: 25%;
    overflow: hidden;
}
.sidebar-categories .ant-radio-wrapper {
    white-space: initial;
}
.sidebar-categories .category {
    margin-bottom: 40px;
}
.sidebar-categories .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
}
.sidebar-categories .category-title {
    font-size: 18px;
}
@media(max-width: 768px) {
    .main-content__products {
        flex-direction: column;
    }
    .sidebar-categories, .primary-products {
        width: 100%;
    }
}

.main-content__primary--page {
    border-right: none;
}
/**
* ----------------------------------------------
* Contact Page
* ----------------------------------------------
**/
.contact-info__wrapper {
    display: flex;
    justify-content: space-between;
}
.contact-info__col {
    flex: 1 auto auto;
    padding: 0 3rem;
}
.contact-info__col .info-item {
    margin-bottom: 3rem;
}
@media (max-width: 768px) {
    .contact-info__wrapper {
        flex-direction: column;
    }
}
.footer-copyright {
    border-top: 1px #1E252B solid;
    text-align: center;
    margin-right: 1.9059rem;
    padding-top: 1.9059rem;
    padding-bottom: 1.9059rem;
    color: #949B8F;
    line-height: 1.5rem;
    font-size: 1.198rem
}
.footer-copyright a {
    color: #fff;
}

video {
    width: 100% !important;
    height: auto !important;
}

.video {
    margin: 0 auto;
    display: block;
    text-align: center
}

/* Link buttons */
.btn-link {
    white-space: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    border: none;
}
    .btn-link:hover {
        color: rgba(0, 0, 0, 0.65);
        border: none;
    }

.btn-link.btn-link__active {
    color: #c8002a;
    border: none;
}


/* Customer Type/Settings Modal */

.type-modal .ant-modal-body {
    display: none ;
}

.settings-modal .ant-modal-close .ant-modal-close-x{
    display: none;
}
