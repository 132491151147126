@import '~antd/dist/antd.css';

.small-caps, .upper {
    letter-spacing: .1rem;
}
.contact-links-nav-list, .footer-list, .help-nav-list, .main-nav-list, .paging-list, .sidebar-nav-list {
    list-style-type: none;
}
.alert-box__title, .page-hero-content__title, .sidecart-action__button, .sidecart-offer__title, .upper {
    text-transform: uppercase;
}
.products--gallery .product-card {
    width: 48.19277%;
    float: left;
    margin-bottom: 3.61446%}
.products--gallery .product-card:nth-child(2n+1) {
    margin-right: -100%;
    clear: both;
    margin-left: 0;
}
.products--gallery .product-card:nth-child(2n+2) {
    margin-left: 51.80723%;
    margin-right: -100%;
    clear: none;
}
@media screen and (min-width:31.25em) {
    .products--gallery .product-card {
        width: 47.82609%;
        float: left;
        margin-bottom: 4.34783%}
    .products--gallery .product-card:nth-child(2n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery .product-card:nth-child(2n+2) {
        margin-left: 52.17391%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:48em) {
    .products--gallery .product-card {
        width: 23.40426%;
        float: left;
        margin-bottom: 2.12766%}
    .products--gallery .product-card:nth-child(4n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery .product-card:nth-child(4n+2) {
        margin-left: 25.53191%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery .product-card:nth-child(4n+3) {
        margin-left: 51.06383%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery .product-card:nth-child(4n+4) {
        margin-left: 76.59574%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:64em) {
    .products--gallery .product-card {
        width: 22.85714%;
        float: left;
        margin-bottom: 2.85714%}
    .products--gallery .product-card:nth-child(4n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery .product-card:nth-child(4n+2) {
        margin-left: 25.71429%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery .product-card:nth-child(4n+3) {
        margin-left: 51.42857%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery .product-card:nth-child(4n+4) {
        margin-left: 77.14286%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:83.5em) {
    .products--gallery .product-card {
        width: 23.52941%;
        float: left;
    }
    .products--gallery .product-card:nth-child(4n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery .product-card:nth-child(4n+2) {
        margin-left: 25.4902%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery .product-card:nth-child(4n+3) {
        margin-left: 50.98039%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery .product-card:nth-child(4n+4) {
        margin-left: 76.47059%;
        margin-right: -100%;
        clear: none;
    }
}.products--gallery-2 .product-card {
     width: 48.19277%;
     float: left;
     margin-bottom: 3.61446%}
.products--gallery-2 .product-card:nth-child(2n+1) {
    margin-right: -100%;
    clear: both;
    margin-left: 0;
}
.products--gallery-2 .product-card:nth-child(2n+2) {
    margin-left: 51.80723%;
    margin-right: -100%;
    clear: none;
}
@media screen and (min-width:31.25em) {
    .products--gallery-2 .product-card {
        width: 47.82609%;
        float: left;
        margin-bottom: 4.34783%}
    .products--gallery-2 .product-card:nth-child(2n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery-2 .product-card:nth-child(2n+2) {
        margin-left: 52.17391%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:48em) {
    .products--gallery-2 .product-card {
        width: 32.07547%;
        float: left;
        margin-bottom: 1.88679%}
    .products--gallery-2 .product-card:nth-child(3n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery-2 .product-card:nth-child(3n+2) {
        margin-left: 33.96226%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(3n+3) {
        margin-left: 67.92453%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:64em) {
    .products--gallery-2 .product-card {
        width: 14.28571%;
        float: left;
        margin-bottom: 2.85714%}
    .products--gallery-2 .product-card:nth-child(6n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery-2 .product-card:nth-child(6n+2) {
        margin-left: 17.14286%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(6n+3) {
        margin-left: 34.28571%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(6n+4) {
        margin-left: 51.42857%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(6n+5) {
        margin-left: 68.57143%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(6n+6) {
        margin-left: 85.71429%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:83.5em) {
    .products--gallery-2 .product-card {
        width: 15.03268%;
        float: left;
    }
    .products--gallery-2 .product-card:nth-child(6n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery-2 .product-card:nth-child(6n+2) {
        margin-left: 16.99346%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(6n+3) {
        margin-left: 33.98693%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(6n+4) {
        margin-left: 50.98039%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(6n+5) {
        margin-left: 67.97386%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-2 .product-card:nth-child(6n+6) {
        margin-left: 84.96732%;
        margin-right: -100%;
        clear: none;
    }
}.promotions-content:after, .promotions:after, .spots:after {
     clear: both;
 }
.products--gallery-main-content .product-card {
    width: 48.19277%;
    float: left;
    margin-bottom: 3.61446%}
.products--gallery-main-content .product-card:nth-child(2n+1) {
    margin-right: -100%;
    clear: both;
    margin-left: 0;
}
.products--gallery-main-content .product-card:nth-child(2n+2) {
    margin-left: 51.80723%;
    margin-right: -100%;
    clear: none;
}
@media screen and (min-width:31.25em) {
    .products--gallery-main-content .product-card {
        width: 47.82609%;
        float: left;
        margin-bottom: 4.34783%}
    .products--gallery-main-content .product-card:nth-child(2n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery-main-content .product-card:nth-child(2n+2) {
        margin-left: 52.17391%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:48em) {
    .products--gallery-main-content .product-card {
        width: 32.07547%;
        float: left;
        margin-bottom: 1.88679%}
    .products--gallery-main-content .product-card:nth-child(3n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery-main-content .product-card:nth-child(3n+2) {
        margin-left: 33.96226%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-main-content .product-card:nth-child(3n+3) {
        margin-left: 67.92453%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:64em) {
    .products--gallery-main-content .product-card {
        width: 22.85714%;
        float: left;
        margin-bottom: 2.85714%}
    .products--gallery-main-content .product-card:nth-child(4n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery-main-content .product-card:nth-child(4n+2) {
        margin-left: 25.71429%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-main-content .product-card:nth-child(4n+3) {
        margin-left: 51.42857%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-main-content .product-card:nth-child(4n+4) {
        margin-left: 77.14286%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:83.5em) {
    .products--gallery-main-content .product-card {
        width: 23.52941%;
        float: left;
    }
    .products--gallery-main-content .product-card:nth-child(4n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .products--gallery-main-content .product-card:nth-child(4n+2) {
        margin-left: 25.4902%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-main-content .product-card:nth-child(4n+3) {
        margin-left: 50.98039%;
        margin-right: -100%;
        clear: none;
    }
    .products--gallery-main-content .product-card:nth-child(4n+4) {
        margin-left: 76.47059%;
        margin-right: -100%;
        clear: none;
    }
}.bottom-promotion-list, .nav-tabs-list, .product-choices-list, .sub-nav-list {
     list-style-type: none;
     margin: 0;
     padding: 0;
 }
.hero-image, .page-hero, .teaser-image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
    border-radius: 4px;
    margin-bottom: 3.9375rem;
    padding: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
        padding: 1.24965rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
        padding: 1.24965rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
        padding: 1.24965rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
        margin-bottom: 3.87465rem;
        padding: 2.625rem;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
        padding: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
        padding: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
        padding: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .alert-box, .cart, .checkout-step-content, .checkout-step-header, .order-actions, .order-summary, .pop-up, .search-address {
        padding: 2.56215rem;
    }
}.cart, .checkout-step-content, .pop-up {
     border: 1px solid #C9CDCF;
 }
*, :after, :before {
    -moz-box-sizing: border-box;
    -box-sizing: border-box;
    -ms-box-sizing: border-box;
}
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
body, html {
    height: 100%}
a img {
    border: none;
}
blockquote {
    quotes: none;
}
blockquote:after, blockquote:before {
    content: '';
    content: none;
}
.c:after, .c:before, .products:after, .products:before, .promotions-content:after, .promotions-content:before, .promotions:after, .promotions:before, .spots:after, .spots:before, .teasers:after, .teasers:before {
    display: table;
    content: ""}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
caption, td, th {
    text-align: left;
    font-weight: 400;
    vertical-align: middle;
}
[type=email], [type=submit], [type=tel], [type=text], button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    outline: 0;
    border: 0;
}
[type=email]:active, [type=email]:focus, [type=email]:hover, [type=submit]:active, [type=submit]:focus, [type=submit]:hover, [type=tel]:active, [type=tel]:focus, [type=tel]:hover, [type=text]:active, [type=text]:focus, [type=text]:hover, button:active, button:focus, button:hover {
    outline: 0;
    border: 0;
}
embed, iframe, img, object, video {
    max-width: 100%}
img {
    width: auto;
}
html {
    font-size: 65.625%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
@media screen and (min-width:31.25em) {
    html {
        font-size: 65.625%}
}@media screen and (min-width:48em) {
    html {
        font-size: 65.625%}
}@media screen and (min-width:64em) {
    html {
        font-size: 65.625%}
}@media screen and (min-width:83.5em) {
    html {
        font-size: 68%}
    body {
        font-size: 2.46552rem;
    }
}body {
     font-size: 2.46552rem;
     font-family: Lato, sans-serif;
     font-style: normal;
     font-weight: 400;
     line-height: 2rem;
     color: #565656;
     background: #2A383F;
 }
*, :after, :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
    background-color: #f3f3f3;
}
::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #4a4a4a;
}
.promotions {
    padding-top: 2.625rem;
    padding-bottom: 5.25rem;
    margin-bottom: 3.9375rem;
    margin-top: 3.9375rem;
    background: #F4F5F5;
}
@media screen and (min-width:31.25em) {
    body {
        font-size: 2.46552rem;
    }
    .promotions {
        padding-top: 2.56215rem;
        padding-bottom: 5.18715rem;
        margin-bottom: 3.87465rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    body {
        font-size: 2.46552rem;
    }
    .promotions {
        padding-top: 2.56215rem;
        padding-bottom: 5.18715rem;
        margin-bottom: 3.87465rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    body {
        font-size: 2.46552rem;
    }
    .promotions {
        padding-top: 2.56215rem;
        padding-bottom: 5.18715rem;
        margin-bottom: 3.87465rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .promotions {
        padding-top: 2.56215rem;
        padding-bottom: 5.18715rem;
        margin-bottom: 3.87465rem;
        margin-top: 3.87465rem;
    }
}.promotions-content__col-one {
     margin-bottom: 2.625rem;
     padding-bottom: 2.625rem;
     border-bottom: solid 1px #C9CDCF;
 }
@media screen and (min-width:31.25em) {
    .promotions-content__col-one {
        margin-bottom: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .promotions-content__col-one {
        margin-bottom: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .promotions-content__col-one {
        margin-bottom: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .promotions-content__col-one {
        width: 31.42857%;
        float: left;
        margin-right: 2.85714%;
        padding-right: 2.85714%;
        border-right: solid 1px #979797;
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}.promotions-content__col-two {
     margin-bottom: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .promotions-content__col-two {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .promotions-content__col-two {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .promotions-content__col-two {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .promotions-content__col-two {
        width: 48.93617%;
        float: left;
        margin-right: 2.12766%;
        margin-bottom: 0;
    }
}@media screen and (min-width:64em) {
    .promotions-content__col-two {
        width: 31.42857%;
        float: left;
        margin-right: 2.85714%}
}.promotions__text {
     color: #666;
 }
.spots {
    margin-bottom: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .spots {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .promotions-content__col-three {
        width: 48.93617%;
        float: right;
        margin-right: 0;
    }
    .spots {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .promotions-content__col-three {
        width: 31.42857%;
        float: right;
        margin-right: 0;
    }
    .spots {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .spots {
        margin-bottom: 1.24965rem;
    }
}.spots .spot {
     margin-bottom: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .spots .spot {
        margin-bottom: 2.56215rem;
    }
    .teasers--bg {
        padding-top: 5%}
}@media screen and (min-width:64em) {
    .spots .spot {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .spots .spot {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .spots .spot {
        width: 32.39437%;
        float: left;
        margin-bottom: 0;
    }
    .spots .spot:nth-child(3n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .spots .spot:nth-child(3n+2) {
        margin-left: 33.80282%;
        margin-right: -100%;
        clear: none;
    }
    .spots .spot:nth-child(3n+3) {
        margin-left: 67.60563%;
        margin-right: -100%;
        clear: none;
    }
    .teasers--bg {
        padding-top: 5%;
        padding-bottom: 5%}
}@media screen and (min-width:64em) {
    .spots .spot {
        width: 31.42857%;
        float: left;
        margin-bottom: 0;
    }
    .spots .spot:nth-child(3n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .spots .spot:nth-child(3n+2) {
        margin-left: 34.28571%;
        margin-right: -100%;
        clear: none;
    }
    .spots .spot:nth-child(3n+3) {
        margin-left: 68.57143%;
        margin-right: -100%;
        clear: none;
    }
}.teasers:after {
     clear: both;
 }
.teasers--bg {
    background-color: #F4F5F5;
    padding-top: 5%}
.teasers .teaser {
    margin-bottom: 5%}
@media screen and (min-width:31.25em) {
    .teasers .teaser {
        margin-bottom: 5%}
}@media screen and (min-width:48em) {
    .teasers .teaser {
        width: 48.93617%;
        float: left;
        margin-bottom: 0;
    }
    .teasers .teaser:nth-child(2n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .teasers .teaser:nth-child(2n+2) {
        margin-left: 51.06383%;
        margin-right: -100%;
        clear: none;
    }
}@media screen and (min-width:64em) {
    .teasers .teaser {
        width: 48.57143%;
        float: left;
        margin-bottom: 0;
    }
    .teasers .teaser:nth-child(2n+1) {
        margin-right: -100%;
        clear: both;
        margin-left: 0;
    }
    .teasers .teaser:nth-child(2n+2) {
        margin-left: 51.42857%;
        margin-right: -100%;
        clear: none;
    }
}.c, .grid--visible .c {
     max-width: 92.5%;
     margin-left: auto;
     margin-right: auto;
 }
.products {
    padding-top: 2.625rem;
}
.products:after {
    clear: both;
}
.c:after, .grid--visible .c:after {
    content: " ";
    display: block;
    clear: both;
}
.grid--visible .c {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(102, 102, 255, .25)), color-stop(93.02326%, rgba(179, 179, 255, .25)), color-stop(93.02326%, transparent));
    background-image: linear-gradient(to right, rgba(102, 102, 255, .25), rgba(179, 179, 255, .25) 93.02326%, transparent 93.02326%);
    background-size: 25.44379%;
    background-origin: content-box;
    background-clip: content-box;
    background-position: left top;
}
@media screen and (min-width:31.25em) {
    .products {
        padding-top: 2.56215rem;
    }
    .c, .grid--visible .c {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .c:after, .grid--visible .c:after {
        content: " ";
        display: block;
        clear: both;
    }
    .grid--visible .c {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(102, 102, 255, .25)), color-stop(83.33333%, rgba(179, 179, 255, .25)), color-stop(83.33333%, transparent));
        background-image: linear-gradient(to right, rgba(102, 102, 255, .25), rgba(179, 179, 255, .25) 83.33333%, transparent 83.33333%);
        background-size: 26.08696%;
        background-origin: content-box;
        background-clip: content-box;
        background-position: left top;
    }
}@media screen and (min-width:48em) {
    .products {
        padding-top: 2.56215rem;
    }
    .c, .grid--visible .c {
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
    .c:after, .grid--visible .c:after {
        content: " ";
        display: block;
        clear: both;
    }
    .grid--visible .c {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(102, 102, 255, .25)), color-stop(83.33333%, rgba(179, 179, 255, .25)), color-stop(83.33333%, transparent));
        background-image: linear-gradient(to right, rgba(102, 102, 255, .25), rgba(179, 179, 255, .25) 83.33333%, transparent 83.33333%);
        background-size: 12.76596%;
        background-origin: content-box;
        background-clip: content-box;
        background-position: left top;
    }
}@media screen and (min-width:64em) {
    .products {
        padding-top: 2.56215rem;
    }
    .c, .grid--visible .c {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .c:after, .grid--visible .c:after {
        content: " ";
        display: block;
        clear: both;
    }
    .grid--visible .c {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(102, 102, 255, .25)), color-stop(66.66667%, rgba(179, 179, 255, .25)), color-stop(66.66667%, transparent));
        background-image: linear-gradient(to right, rgba(102, 102, 255, .25), rgba(179, 179, 255, .25) 66.66667%, transparent 66.66667%);
        background-size: 8.57143%;
        background-origin: content-box;
        background-clip: content-box;
        background-position: left top;
    }
}@media screen and (min-width:83.5em) {
    .products {
        padding-top: 2.56215rem;
    }
    .c, .grid--visible .c {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .c:after, .grid--visible .c:after {
        content: " ";
        display: block;
        clear: both;
    }
    .grid--visible .c {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(102, 102, 255, .25)), color-stop(76.92308%, rgba(179, 179, 255, .25)), color-stop(76.92308%, transparent));
        background-image: linear-gradient(to right, rgba(102, 102, 255, .25), rgba(179, 179, 255, .25) 76.92308%, transparent 76.92308%);
        background-size: 8.49673%;
        background-origin: content-box;
        background-clip: content-box;
        background-position: left top;
    }
}@media screen and (min-width:100em) {
    .c {
        max-width: 1268px;
    }
    .grid--visible .c {
        max-width: 1268px!important;
    }
}.header {
     position: relative;
     background: #2A383F;
     padding-bottom: .98438rem;
 }
.header:after, .header:before {
    display: table;
    content: ""}
.header:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .header {
        padding-bottom: .92153rem;
    }
}@media screen and (min-width:64em) {
    .header {
        padding-bottom: .92153rem;
    }
}@media screen and (min-width:83.5em) {
    .header {
        padding-bottom: .92153rem;
    }
}.header__top {
     height: 80px;
     height: 7.61905rem;
 }
@media screen and (min-width:48em) {
    .header {
        padding-bottom: .92153rem;
    }
    .header__top {
        height: auto;
        min-height: 80px;
        min-height: 7.61905rem;
    }
}.header__bottom {
     background: #2A383F;
     padding-bottom: 1.96875rem;
 }
@media screen and (min-width:31.25em) {
    .header__bottom {
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .header__bottom {
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .header__bottom {
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .header__bottom {
        padding-bottom: 0;
    }
    .header {
        height: auto;
        padding-top: .98438rem;
        padding-bottom: 0;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .header {
        padding-top: .92153rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .header {
        padding-top: .92153rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .header {
        padding-top: .92153rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .header {
        padding-top: .92153rem;
    }
}.header-nav {
     float: right;
     width: calc(100% - 75px);
     position: relative;
     height: 80px;
     height: 7.61905rem;
 }
@media screen and (min-width:48em) {
    .header-nav {
        float: none;
        width: 100%;
        height: auto;
    }
}.logo {
     float: left;
     height: 80px;
     height: 7.61905rem;
     width: 75px;
 }
@media screen and (min-width:48em) {
    .logo {
        width: 150px;
        position: absolute;
        bottom: -5px;
        z-index: 99;
    }
}.logo__link {
     display: table;
     height: 100%;
     width: 100%}
.logo__svg-wrapper {
    display: table-cell;
    vertical-align: middle;
    width: 100%}
.logo__svg {
    width: 75px;
}
@media screen and (min-width:48em) {
    .logo__svg {
        width: 150px;
    }
}.search-form-wrapper {
     display: relative;
     position: relative;
     width: 100%}
@media screen and (min-width:48em) {
    .search-form {
        width: 300px;
        width: 28.57143rem;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .help-nav-list__item.menu-button {
        display: none;
    }
}.bottom-promotion {
     padding-top: .32812rem;
     padding-bottom: .65625rem;
     background-color: #C8002A;
     text-align: center;
 }
.bottom-promotion:after, .bottom-promotion:before {
    display: table;
    content: ""}
.bottom-promotion:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .bottom-promotion {
        padding-top: .26528rem;
        padding-bottom: .5934rem;
    }
}@media screen and (min-width:48em) {
    .bottom-promotion {
        padding-top: .26528rem;
        padding-bottom: .5934rem;
    }
}@media screen and (min-width:64em) {
    .bottom-promotion {
        padding-top: .26528rem;
        padding-bottom: .5934rem;
    }
}@media screen and (min-width:83.5em) {
    .bottom-promotion {
        padding-top: .26528rem;
        padding-bottom: .5934rem;
    }
}.bottom-promotion-list {
     display: inline-block;
 }
.bottom-promotion-list__item {
    padding-left: 1.90476rem;
    padding-right: 1.90476rem;
    font-weight: 700;
    color: #FFF;
    margin-bottom: 0!important;
    display: inline-block;
    line-height: 1rem;
    font-size: 1.198rem;
    padding-top: .10467rem;
}
@media screen and (min-width:31.25em) {
    .bottom-promotion-list__item {
        font-size: 1.198rem;
        margin-bottom: -.10467rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:48em) {
    .bottom-promotion-list__item {
        font-size: 1.198rem;
        margin-bottom: -.10467rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:64em) {
    .bottom-promotion-list__item {
        font-size: 1.198rem;
        margin-bottom: -.10467rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:83.5em) {
    .bottom-promotion-list__item {
        font-size: 1.198rem;
        margin-bottom: -.10467rem;
        padding-top: .10467rem;
    }
}.bottom-promotion-list__svg {
     display: inline-block;
     vertical-align: middle;
     width: 9px;
     position: relative;
     top: -1px;
     height: 20px;
 }
.footer {
    background-color: #2A383F;
}
.footer:after, .footer:before {
    display: table;
    content: ""}
.footer:after {
    clear: both;
}
.footer--landing-page {
    background-color: #1E252B;
    padding-top: 3.9375rem;
    padding-bottom: 3.9375rem;
}
@media screen and (min-width:31.25em) {
    .footer--landing-page {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .footer--landing-page {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .footer--landing-page {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .footer--landing-page {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
    .footer__headline {
        font-size: 1.45238rem;
        margin-bottom: .97928rem;
        padding-top: .02072rem;
    }
}.footer__headline {
     color: #FFF;
     font-style: normal;
     font-weight: 400;
     line-height: 1rem;
     font-size: 1.45238rem;
     margin-bottom: .97928rem;
     padding-top: .02072rem;
 }
@media screen and (min-width:31.25em) {
    .footer__headline {
        font-size: 1.45238rem;
        margin-bottom: .97928rem;
        padding-top: .02072rem;
    }
}@media screen and (min-width:64em) {
    .footer__headline {
        font-size: 1.45238rem;
        margin-bottom: .97928rem;
        padding-top: .02072rem;
    }
}.footer__inner {
     display: table;
     width: 100%;
     height: 100%}
.footer__col-one {
    padding-left: 2.5%;
    padding-right: 2.5%}
@media screen and (min-width:48em) {
    .footer__headline {
        font-size: 1.45238rem;
        margin-bottom: .97928rem;
        padding-top: .02072rem;
    }
    .footer__col-one {
        display: table-cell;
        vertical-align: top;
        padding-left: 5%;
        padding-right: 0;
        width: 61.70213%}
}.footer__col-two {
     position: relative;
     padding-left: 5%;
     padding-right: 5%;
     background-color: #1E252B;
     padding-top: 3.28125rem;
 }
.main, .main-content {
    background: #FFF;
}
@media screen and (min-width:31.25em) {
    .footer__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .footer__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .footer__col-one {
        width: 66.01307%}
    .footer__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .footer__col-two {
        display: table-cell;
        vertical-align: top;
        width: 36.17021%;
        padding-top: 3.28125rem;
    }
}.footer-links-bottom:after, .footer-links-bottom:before, .footer-links-top:after, .footer-links-top:before, .footer-links:after, .footer-links:before, .main-content:after, .main-content:before, .placeholder:after, .placeholder:before {
     display: table;
     content: ""}
@media screen and (min-width:48em) and (min-width:31.25em) {
    .footer__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .footer__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .footer__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .footer__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .footer__col-two {
        width: 40%}
}.footer-links:after {
     clear: both;
 }
.footer-links-bottom, .footer-links-top {
    float: left;
    width: 50%;
    padding: 0 2.5%}
.footer-links-bottom:after, .footer-links-top:after {
    clear: both;
}
.footer-links-bottom__col-one, .footer-links-bottom__col-two, .footer-links-top__col-one, .footer-links-top__col-two {
    padding-top: 3.28125rem;
}
@media screen and (min-width:31.25em) {
    .footer-links-bottom__col-one, .footer-links-bottom__col-two, .footer-links-top__col-one, .footer-links-top__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .footer-links-bottom, .footer-links-top {
        width: 100%;
        float: none;
    }
    .footer-links-bottom__col-one, .footer-links-bottom__col-two, .footer-links-top__col-one, .footer-links-top__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .footer-links-bottom, .footer-links-top {
        float: left;
        width: 50%}
    .footer-links-bottom__col-one, .footer-links-bottom__col-two, .footer-links-top__col-one, .footer-links-top__col-two {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .footer-links-bottom__col-one, .footer-links-bottom__col-two, .footer-links-top__col-one, .footer-links-top__col-two {
        padding-top: 3.2184rem;
    }
}.footer h2 {
     line-height: 3.5rem;
     font-size: 1.93829rem;
     margin-bottom: -.61038rem;
     padding-top: 1.11038rem;
 }
.footer-cookie-alert {
    margin-top: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .footer h2 {
        line-height: 3.5rem;
        font-size: 1.93829rem;
        margin-bottom: -.61038rem;
        padding-top: 1.11038rem;
    }
    .footer-cookie-alert {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .footer-links-bottom__col-one, .footer-links-top__col-one {
        width: 47.82609%;
        float: left;
        margin-right: 4.34783%}
    .footer-links-bottom__col-two, .footer-links-top__col-two {
        width: 47.82609%;
        float: right;
        margin-right: 0;
    }
    .footer h2 {
        line-height: 4.5rem;
        font-size: 1.93829rem;
        margin-bottom: -1.11038rem;
        padding-top: 1.61038rem;
    }
    .footer-cookie-alert {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .footer h2 {
        line-height: 4.5rem;
        font-size: 1.93829rem;
        margin-bottom: -1.11038rem;
        padding-top: 1.61038rem;
    }
    .footer-cookie-alert {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .footer h2 {
        line-height: 4.5rem;
        font-size: 1.93829rem;
        margin-bottom: -1.11038rem;
        padding-top: 1.61038rem;
    }
    .footer-cookie-alert {
        margin-top: 1.24965rem;
    }
    .footer-cookie-alert__text {
        font-size: 1.198rem;
        margin-bottom: -.60467rem;
        padding-top: .60467rem;
    }
}.footer-cookie-alert__text {
     line-height: 2rem;
     font-size: 1.198rem;
     margin-bottom: -.60467rem;
     padding-top: .60467rem;
 }
.footer-cookie-alert__link {
    color: #7F7F7F;
    text-decoration: none;
}
.main {
    min-height: 75vh;
    padding-bottom: 3.28125rem;
}
@media screen and (min-width:31.25em) {
    .footer-cookie-alert__text {
        font-size: 1.198rem;
        margin-bottom: -.60467rem;
        padding-top: .60467rem;
    }
    .main {
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .footer-cookie-alert__text {
        font-size: 1.198rem;
        margin-bottom: -.60467rem;
        padding-top: .60467rem;
    }
    .main {
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .footer-cookie-alert__text {
        font-size: 1.198rem;
        margin-bottom: -.60467rem;
        padding-top: .60467rem;
    }
    .main {
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .main {
        padding-bottom: 3.2184rem;
    }
}.main--no-padding, .product-article .main {
     padding-bottom: 0;
 }
.placeholder:after {
    clear: both;
}
.main-content:after {
    clear: both;
}
.main-content--padding-top {
    padding-top: 3.9375rem;
}
.main-content--padding-bottom {
    padding-bottom: 3.9375rem;
}
@media screen and (min-width:31.25em) {
    .main-content--padding-top {
        padding-top: 3.87465rem;
    }
    .main-content--padding-bottom {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .main-content--padding-top {
        padding-top: 3.87465rem;
    }
    .main-content--padding-bottom {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .main-content--padding-top {
        padding-top: 3.87465rem;
    }
    .main-content--padding-bottom {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .main-content--padding-top {
        padding-top: 3.87465rem;
    }
    .main-content--padding-bottom {
        padding-bottom: 3.87465rem;
    }
}.main-content__secondary--bg {
     background-color: #F4F5F5;
     padding-top: 0;
     padding-bottom: 3.28125rem;
 }
@media screen and (min-width:31.25em) {
    .main-content__secondary--bg {
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .main-content__secondary--bg {
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .main-content__secondary--bg {
        padding-bottom: 3.2184rem;
    }
    .main-content__primary {
        width: 74.46809%;
        float: left;
        margin-right: 2.12766%}
}@media screen and (min-width:64em) {
    .main-content__secondary--bg {
        padding-bottom: 3.2184rem;
    }
    .main-content__primary {
        width: 65.71429%;
        float: left;
        margin-right: 2.85714%}
}.main-content__primary--full {
     width: 100%;
     float: none;
 }
.main-content__primary--page {
    padding-bottom: 2.625rem;
}
@media screen and (min-width:31.25em) {
    .main-content__primary--page {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .main-content__primary--page {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .main-content__primary--page {
        padding-bottom: 2.56215rem;
        width: 75%;
        float: left;
        margin-right: 0;
        border-right: solid 1px #EEE;
        padding-left: 0;
        padding-right: 6.38298%}
}img, select {
     border: none;
 }
@media screen and (min-width:64em) {
    .main-content__primary--page {
        padding-bottom: 2.56215rem;
        padding-left: 8.57143%;
        padding-right: 8.57143%;
        width: 75%;
        float: left;
        margin-right: 0;
    }
}.main-content__primary--page-two {
     padding-top: 2.625rem;
     padding-bottom: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .main-content__primary--page-two {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .main-content__primary--page-two {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .main-content__primary--page-two {
        width: 75%;
        float: left;
        margin-right: 0;
        padding: 2.56215rem 6.38298% 2.56215rem 0;
    }
}@media screen and (min-width:64em) {
    .main-content__primary--page-two {
        width: 66.66667%;
        float: left;
        margin-right: 0;
        padding: 2.56215rem 8.57143%}
}.main-content__sidebar--page {
     padding-bottom: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .main-content__sidebar--page {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .main-content__sidebar {
        width: 23.40426%;
        float: right;
        margin-right: 0;
    }
    .main-content__sidebar--page {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .main-content__sidebar {
        width: 31.42857%;
        float: right;
        margin-right: 0;
    }
    .main-content__sidebar--page {
        padding-bottom: 2.56215rem;
        width: 25%;
        float: left;
        margin-right: 0;
    }
}@media screen and (min-width:83.5em) {
    .main-content__sidebar--page {
        padding-bottom: 2.56215rem;
    }
}.main-content__sidebar--page-two {
     padding-bottom: 2.625rem;
     text-align: center;
 }
@media screen and (min-width:31.25em) {
    .main-content__sidebar--page-two {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .main-content__sidebar--page-two {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .main-content__sidebar--page {
        width: 25%;
        float: left;
        margin-right: 0;
    }
    .main-content__sidebar--page-two {
        padding-bottom: 2.56215rem;
        padding-top: 2.625rem;
        width: 25%;
        float: left;
        margin-right: 0;
    }
    .mob {
        display: none!important;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .main-content__sidebar--page-two {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .main-content__sidebar--page-two {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .main-content__sidebar--page-two {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .main-content__sidebar--page-two {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .main-content__sidebar--page-two {
        padding-bottom: 2.56215rem;
        width: 33.33333%;
        float: left;
        margin-right: 0;
    }
}img {
     max-width: 100%;
     height: auto;
 }
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%}
figure {
    position: relative;
}
figure:hover figcaption {
    opacity: 1;
}
figure img {
    display: block;
}
figcaption {
    padding-left: 1.90476rem;
    padding-right: 1.90476rem;
    width: 100%;
    color: #FFF;
    margin: 0;
    -webkit-font-feature-settings: 'dlig', 'liga', 'lnum', 'kern';
    font-feature-settings: 'dlig', 'liga', 'lnum', 'kern';
    font-style: normal;
    position: absolute;
    bottom: 0;
    line-height: 2rem;
    font-size: 1.93829rem;
    background-color: rgba(0, 0, 0, .7);
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    margin-top: 0!important;
    margin-bottom: 0!important;
    padding-top: 5px!important;
    padding-bottom: 5px!important;
}
.product-actions__icon, .product-card-actions__icon {
    height: 3.28125rem;
    width: 36px;
    shape-rendering: geometricPrecision;
}
@media screen and (min-width:31.25em) {
    figcaption {
        font-size: 1.93829rem;
        margin-bottom: -.36038rem;
        padding-top: .36038rem;
    }
    .product-actions__icon, .product-card-actions__icon {
        height: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    figcaption {
        font-size: 1.93829rem;
        margin-bottom: -.36038rem;
        padding-top: .36038rem;
    }
    .product-actions__icon, .product-card-actions__icon {
        height: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    figcaption {
        font-size: 1.93829rem;
        margin-bottom: -.36038rem;
        padding-top: .36038rem;
    }
    .product-actions__icon, .product-card-actions__icon {
        height: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    figcaption {
        font-size: 1.93829rem;
        margin-bottom: -.36038rem;
        padding-top: .36038rem;
    }
    .product-actions__icon, .product-card-actions__icon {
        height: 3.2184rem;
    }
}.svg-sprite {
     display: none;
 }
h1, h2, h3, h4, h5, h6 {
    color: #2A2A2A;
    -webkit-font-feature-settings: 'dlig', 'liga', 'lnum', 'kern';
    font-feature-settings: 'dlig', 'liga', 'lnum', 'kern';
    font-style: normal;
    font-weight: 300;
}
.h1, .page-hero-content__info, h1 {
    line-height: 3rem;
    font-size: 3.13619rem;
    margin-bottom: .53493rem;
    padding-top: .46507rem;
}
@media screen and (min-width:31.25em) {
    .h1, .page-hero-content__info, h1 {
        line-height: 4rem;
        font-size: 3.13619rem;
        margin-bottom: .03493rem;
        padding-top: .96507rem;
    }
}@media screen and (min-width:48em) {
    .h1, .page-hero-content__info, h1 {
        line-height: 5rem;
        font-size: 3.98924rem;
        margin-bottom: .81644rem;
        padding-top: 1.18356rem;
    }
}@media screen and (min-width:64em) {
    .h1, .page-hero-content__info, h1 {
        line-height: 5rem;
        font-size: 3.98924rem;
        margin-bottom: 1.31644rem;
        padding-top: 1.18356rem;
    }
}@media screen and (min-width:83.5em) {
    .h1, .page-hero-content__info, h1 {
        line-height: 5rem;
        font-size: 3.98924rem;
        margin-bottom: 1.31644rem;
        padding-top: 1.18356rem;
    }
}.h2, .product-content__title, h2 {
     line-height: 3.5rem;
     font-size: 3.13619rem;
     margin-bottom: 1.28493rem;
     padding-top: .71507rem;
 }
@media screen and (min-width:31.25em) {
    .h2, .product-content__title, h2 {
        line-height: 3.5rem;
        font-size: 3.13619rem;
        margin-bottom: 1.28493rem;
        padding-top: .71507rem;
    }
}@media screen and (min-width:48em) {
    .h2, .product-content__title, h2 {
        line-height: 4.5rem;
        font-size: 3.98924rem;
        margin-bottom: 1.06644rem;
        padding-top: .93356rem;
    }
}@media screen and (min-width:64em) {
    .h2, .product-content__title, h2 {
        line-height: 4.5rem;
        font-size: 3.98924rem;
        margin-bottom: 1.06644rem;
        padding-top: .93356rem;
    }
}@media screen and (min-width:83.5em) {
    .h2, .product-content__title, h2 {
        line-height: 4.5rem;
        font-size: 3.98924rem;
        margin-bottom: 1.06644rem;
        padding-top: .93356rem;
    }
}.h3, .hero-content__title, .page-header__title, .promotions__title, .teaser-content__title, h3 {
     line-height: 3.25rem;
     font-size: 2.78067rem;
     margin-bottom: .79261rem;
     padding-top: .70739rem;
 }
@media screen and (min-width:31.25em) {
    .h3, .hero-content__title, .page-header__title, .promotions__title, .teaser-content__title, h3 {
        line-height: 3.25rem;
        font-size: 2.78067rem;
        margin-bottom: .79261rem;
        padding-top: .70739rem;
    }
}@media screen and (min-width:48em) {
    .h3, .hero-content__title, .page-header__title, .promotions__title, .teaser-content__title, h3 {
        line-height: 3.5rem;
        font-size: 3.13619rem;
        margin-bottom: .78493rem;
        padding-top: .71507rem;
    }
}@media screen and (min-width:64em) {
    .h3, .hero-content__title, .page-header__title, .promotions__title, .teaser-content__title, h3 {
        line-height: 3.5rem;
        font-size: 3.13619rem;
        margin-bottom: .78493rem;
        padding-top: .71507rem;
    }
}@media screen and (min-width:83.5em) {
    .h3, .hero-content__title, .page-header__title, .promotions__title, .teaser-content__title, h3 {
        line-height: 3.5rem;
        font-size: 3.13619rem;
        margin-bottom: .78493rem;
        padding-top: .71507rem;
    }
    .h4, h4 {
        line-height: 2rem;
        font-size: 2.46552rem;
        margin-bottom: 1.31361rem;
        padding-top: .18639rem;
    }
}.h4, h4 {
     line-height: 2rem;
     font-size: 2.46552rem;
     margin-bottom: 1.31361rem;
     padding-top: .18639rem;
 }
@media screen and (min-width:31.25em) {
    .h4, h4 {
        line-height: 2rem;
        font-size: 2.46552rem;
        margin-bottom: 1.31361rem;
        padding-top: .18639rem;
    }
}@media screen and (min-width:48em) {
    .h4, h4 {
        line-height: 2rem;
        font-size: 2.46552rem;
        margin-bottom: 1.31361rem;
        padding-top: .18639rem;
    }
}@media screen and (min-width:64em) {
    .h4, h4 {
        line-height: 2rem;
        font-size: 2.46552rem;
        margin-bottom: 1.31361rem;
        padding-top: .18639rem;
    }
    .h5, .product-table-footer__text, h5 {
        line-height: 2rem;
        font-size: 1.93829rem;
        margin-bottom: 1.13962rem;
        padding-top: .36038rem;
    }
}.h5, .product-table-footer__text, h5 {
     line-height: 2rem;
     font-size: 1.93829rem;
     margin-bottom: 1.13962rem;
     padding-top: .36038rem;
 }
@media screen and (min-width:31.25em) {
    .h5, .product-table-footer__text, h5 {
        line-height: 2rem;
        font-size: 1.93829rem;
        margin-bottom: 1.13962rem;
        padding-top: .36038rem;
    }
}@media screen and (min-width:48em) {
    .h5, .product-table-footer__text, h5 {
        line-height: 2rem;
        font-size: 1.93829rem;
        margin-bottom: 1.13962rem;
        padding-top: .36038rem;
    }
}@media screen and (min-width:83.5em) {
    .h5, .product-table-footer__text, h5 {
        line-height: 2rem;
        font-size: 1.93829rem;
        margin-bottom: 1.13962rem;
        padding-top: .36038rem;
    }
    .h6 {
        margin-bottom: 1.9059rem;
    }
}.h6 {
     margin-top: 1.96875rem;
 }
@media screen and (min-width:31.25em) {
    .h6 {
        margin-bottom: 1.9059rem;
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .h6 {
        margin-bottom: 1.9059rem;
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .h6 {
        margin-bottom: 1.9059rem;
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .h6 {
        margin-top: 1.9059rem;
    }
}.delta+.caption, .delta+.spot-content__read-more, .delta+.teaser-content__read-more, .epsilon+.caption, .epsilon+.spot-content__read-more, .epsilon+.teaser-content__read-more, .zeta+.caption, .zeta+.spot-content__read-more, .zeta+.teaser-content__read-more {
     margin-top: 0;
 }
.btn, .cart-item-content-title__link, .contact-links-nav-list__item, .field-content__button, .field-content__input, .field-content__select, .field-content__text, .field-content__textarea, .footer-about__link, .footer-contact-list__item, .general-information__subtitle, .h6, .hero-content__info, .information-address__subtitle, .main-nav-list__item, .order-actions-section__center-title, .order-actions-section__title, .p, .product-actions__current-price, .product-card-actions__current-price, .product-card-content__title, .product-card-list-content-title, .product-table-item-add-remove, .product-table-item-title__link, .search-address-section-results__title, .sidebar-nav-list-level-two__item, .sidebar-nav-list__item, .sub-nav-list__item, p {
    font-weight: 400;
    line-height: 2.25rem;
    font-size: 1.45238rem;
    margin-bottom: .35428rem;
    padding-top: .64572rem;
}
@media screen and (min-width:31.25em) {
    .btn, .cart-item-content-title__link, .contact-links-nav-list__item, .field-content__button, .field-content__input, .field-content__select, .field-content__text, .field-content__textarea, .footer-about__link, .footer-contact-list__item, .general-information__subtitle, .h6, .hero-content__info, .information-address__subtitle, .main-nav-list__item, .order-actions-section__center-title, .order-actions-section__title, .p, .product-actions__current-price, .product-card-actions__current-price, .product-card-content__title, .product-card-list-content-title, .product-table-item-add-remove, .product-table-item-title__link, .search-address-section-results__title, .sidebar-nav-list-level-two__item, .sidebar-nav-list__item, .sub-nav-list__item, p {
        line-height: 2.25rem;
        font-size: 1.45238rem;
        margin-bottom: .35428rem;
        padding-top: .64572rem;
    }
}@media screen and (min-width:48em) {
    .btn, .cart-item-content-title__link, .contact-links-nav-list__item, .field-content__button, .field-content__input, .field-content__select, .field-content__text, .field-content__textarea, .footer-about__link, .footer-contact-list__item, .general-information__subtitle, .h6, .hero-content__info, .information-address__subtitle, .main-nav-list__item, .order-actions-section__center-title, .order-actions-section__title, .p, .product-actions__current-price, .product-card-actions__current-price, .product-card-content__title, .product-card-list-content-title, .product-table-item-add-remove, .product-table-item-title__link, .search-address-section-results__title, .sidebar-nav-list-level-two__item, .sidebar-nav-list__item, .sub-nav-list__item, p {
        line-height: 2.25rem;
        font-size: 1.45238rem;
        margin-bottom: .35428rem;
        padding-top: .64572rem;
    }
}@media screen and (min-width:64em) {
    .btn, .cart-item-content-title__link, .contact-links-nav-list__item, .field-content__button, .field-content__input, .field-content__select, .field-content__text, .field-content__textarea, .footer-about__link, .footer-contact-list__item, .general-information__subtitle, .h6, .hero-content__info, .information-address__subtitle, .main-nav-list__item, .order-actions-section__center-title, .order-actions-section__title, .p, .product-actions__current-price, .product-card-actions__current-price, .product-card-content__title, .product-card-list-content-title, .product-table-item-add-remove, .product-table-item-title__link, .search-address-section-results__title, .sidebar-nav-list-level-two__item, .sidebar-nav-list__item, .sub-nav-list__item, p {
        line-height: 2.25rem;
        font-size: 1.45238rem;
        margin-bottom: 1.35428rem;
        padding-top: .64572rem;
    }
}@media screen and (min-width:83.5em) {
    .btn, .cart-item-content-title__link, .contact-links-nav-list__item, .field-content__button, .field-content__input, .field-content__select, .field-content__text, .field-content__textarea, .footer-about__link, .footer-contact-list__item, .general-information__subtitle, .h6, .hero-content__info, .information-address__subtitle, .main-nav-list__item, .order-actions-section__center-title, .order-actions-section__title, .p, .product-actions__current-price, .product-card-actions__current-price, .product-card-content__title, .product-card-list-content-title, .product-table-item-add-remove, .product-table-item-title__link, .search-address-section-results__title, .sidebar-nav-list-level-two__item, .sidebar-nav-list__item, .sub-nav-list__item, p {
        line-height: 2.25rem;
        font-size: 1.45238rem;
        margin-bottom: 1.35428rem;
        padding-top: .64572rem;
    }
}.btn+h2, .cart-item-content-title__link+h2, .contact-links-nav-list__item+h2, .field-content__button+h2, .field-content__input+h2, .field-content__select+h2, .field-content__text+h2, .field-content__textarea+h2, .footer-about__link+h2, .footer-contact-list__item+h2, .general-information__subtitle+h2, .h6+h2, .hero-content__info+h2, .information-address__subtitle+h2, .main-nav-list__item+h2, .order-actions-section__center-title+h2, .order-actions-section__title+h2, .p+h2, .product-actions__current-price+h2, .product-card-actions__current-price+h2, .product-card-content__title+h2, .product-card-list-content-title+h2, .product-table-item-add-remove+h2, .product-table-item-title__link+h2, .search-address-section-results__title+h2, .sidebar-nav-list-level-two__item+h2, .sidebar-nav-list__item+h2, .sub-nav-list__item+h2, p+h2 {
     padding-top: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .btn+h2, .cart-item-content-title__link+h2, .contact-links-nav-list__item+h2, .field-content__button+h2, .field-content__input+h2, .field-content__select+h2, .field-content__text+h2, .field-content__textarea+h2, .footer-about__link+h2, .footer-contact-list__item+h2, .general-information__subtitle+h2, .h6+h2, .hero-content__info+h2, .information-address__subtitle+h2, .main-nav-list__item+h2, .order-actions-section__center-title+h2, .order-actions-section__title+h2, .p+h2, .product-actions__current-price+h2, .product-card-actions__current-price+h2, .product-card-content__title+h2, .product-card-list-content-title+h2, .product-table-item-add-remove+h2, .product-table-item-title__link+h2, .search-address-section-results__title+h2, .sidebar-nav-list-level-two__item+h2, .sidebar-nav-list__item+h2, .sub-nav-list__item+h2, p+h2 {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .btn+h2, .cart-item-content-title__link+h2, .contact-links-nav-list__item+h2, .field-content__button+h2, .field-content__input+h2, .field-content__select+h2, .field-content__text+h2, .field-content__textarea+h2, .footer-about__link+h2, .footer-contact-list__item+h2, .general-information__subtitle+h2, .h6+h2, .hero-content__info+h2, .information-address__subtitle+h2, .main-nav-list__item+h2, .order-actions-section__center-title+h2, .order-actions-section__title+h2, .p+h2, .product-actions__current-price+h2, .product-card-actions__current-price+h2, .product-card-content__title+h2, .product-card-list-content-title+h2, .product-table-item-add-remove+h2, .product-table-item-title__link+h2, .search-address-section-results__title+h2, .sidebar-nav-list-level-two__item+h2, .sidebar-nav-list__item+h2, .sub-nav-list__item+h2, p+h2 {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .btn+h2, .cart-item-content-title__link+h2, .contact-links-nav-list__item+h2, .field-content__button+h2, .field-content__input+h2, .field-content__select+h2, .field-content__text+h2, .field-content__textarea+h2, .footer-about__link+h2, .footer-contact-list__item+h2, .general-information__subtitle+h2, .h6+h2, .hero-content__info+h2, .information-address__subtitle+h2, .main-nav-list__item+h2, .order-actions-section__center-title+h2, .order-actions-section__title+h2, .p+h2, .product-actions__current-price+h2, .product-card-actions__current-price+h2, .product-card-content__title+h2, .product-card-list-content-title+h2, .product-table-item-add-remove+h2, .product-table-item-title__link+h2, .search-address-section-results__title+h2, .sidebar-nav-list-level-two__item+h2, .sidebar-nav-list__item+h2, .sub-nav-list__item+h2, p+h2 {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .btn+h2, .cart-item-content-title__link+h2, .contact-links-nav-list__item+h2, .field-content__button+h2, .field-content__input+h2, .field-content__select+h2, .field-content__text+h2, .field-content__textarea+h2, .footer-about__link+h2, .footer-contact-list__item+h2, .general-information__subtitle+h2, .h6+h2, .hero-content__info+h2, .information-address__subtitle+h2, .main-nav-list__item+h2, .order-actions-section__center-title+h2, .order-actions-section__title+h2, .p+h2, .product-actions__current-price+h2, .product-card-actions__current-price+h2, .product-card-content__title+h2, .product-card-list-content-title+h2, .product-table-item-add-remove+h2, .product-table-item-title__link+h2, .search-address-section-results__title+h2, .sidebar-nav-list-level-two__item+h2, .sidebar-nav-list__item+h2, .sub-nav-list__item+h2, p+h2 {
        padding-top: 2.56215rem;
    }
}.btn+h3, .cart-item-content-title__link+h3, .contact-links-nav-list__item+h3, .field-content__button+h3, .field-content__input+h3, .field-content__select+h3, .field-content__text+h3, .field-content__textarea+h3, .footer-about__link+h3, .footer-contact-list__item+h3, .general-information__subtitle+h3, .h6+h3, .hero-content__info+h3, .information-address__subtitle+h3, .main-nav-list__item+h3, .order-actions-section__center-title+h3, .order-actions-section__title+h3, .p+h3, .product-actions__current-price+h3, .product-card-actions__current-price+h3, .product-card-content__title+h3, .product-card-list-content-title+h3, .product-table-item-add-remove+h3, .product-table-item-title__link+h3, .search-address-section-results__title+h3, .sidebar-nav-list-level-two__item+h3, .sidebar-nav-list__item+h3, .sub-nav-list__item+h3, p+h3 {
     padding-top: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .btn+h3, .cart-item-content-title__link+h3, .contact-links-nav-list__item+h3, .field-content__button+h3, .field-content__input+h3, .field-content__select+h3, .field-content__text+h3, .field-content__textarea+h3, .footer-about__link+h3, .footer-contact-list__item+h3, .general-information__subtitle+h3, .h6+h3, .hero-content__info+h3, .information-address__subtitle+h3, .main-nav-list__item+h3, .order-actions-section__center-title+h3, .order-actions-section__title+h3, .p+h3, .product-actions__current-price+h3, .product-card-actions__current-price+h3, .product-card-content__title+h3, .product-card-list-content-title+h3, .product-table-item-add-remove+h3, .product-table-item-title__link+h3, .search-address-section-results__title+h3, .sidebar-nav-list-level-two__item+h3, .sidebar-nav-list__item+h3, .sub-nav-list__item+h3, p+h3 {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .btn+h3, .cart-item-content-title__link+h3, .contact-links-nav-list__item+h3, .field-content__button+h3, .field-content__input+h3, .field-content__select+h3, .field-content__text+h3, .field-content__textarea+h3, .footer-about__link+h3, .footer-contact-list__item+h3, .general-information__subtitle+h3, .h6+h3, .hero-content__info+h3, .information-address__subtitle+h3, .main-nav-list__item+h3, .order-actions-section__center-title+h3, .order-actions-section__title+h3, .p+h3, .product-actions__current-price+h3, .product-card-actions__current-price+h3, .product-card-content__title+h3, .product-card-list-content-title+h3, .product-table-item-add-remove+h3, .product-table-item-title__link+h3, .search-address-section-results__title+h3, .sidebar-nav-list-level-two__item+h3, .sidebar-nav-list__item+h3, .sub-nav-list__item+h3, p+h3 {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .btn+h3, .cart-item-content-title__link+h3, .contact-links-nav-list__item+h3, .field-content__button+h3, .field-content__input+h3, .field-content__select+h3, .field-content__text+h3, .field-content__textarea+h3, .footer-about__link+h3, .footer-contact-list__item+h3, .general-information__subtitle+h3, .h6+h3, .hero-content__info+h3, .information-address__subtitle+h3, .main-nav-list__item+h3, .order-actions-section__center-title+h3, .order-actions-section__title+h3, .p+h3, .product-actions__current-price+h3, .product-card-actions__current-price+h3, .product-card-content__title+h3, .product-card-list-content-title+h3, .product-table-item-add-remove+h3, .product-table-item-title__link+h3, .search-address-section-results__title+h3, .sidebar-nav-list-level-two__item+h3, .sidebar-nav-list__item+h3, .sub-nav-list__item+h3, p+h3 {
        padding-top: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .btn+h3, .cart-item-content-title__link+h3, .contact-links-nav-list__item+h3, .field-content__button+h3, .field-content__input+h3, .field-content__select+h3, .field-content__text+h3, .field-content__textarea+h3, .footer-about__link+h3, .footer-contact-list__item+h3, .general-information__subtitle+h3, .h6+h3, .hero-content__info+h3, .information-address__subtitle+h3, .main-nav-list__item+h3, .order-actions-section__center-title+h3, .order-actions-section__title+h3, .p+h3, .product-actions__current-price+h3, .product-card-actions__current-price+h3, .product-card-content__title+h3, .product-card-list-content-title+h3, .product-table-item-add-remove+h3, .product-table-item-title__link+h3, .search-address-section-results__title+h3, .sidebar-nav-list-level-two__item+h3, .sidebar-nav-list__item+h3, .sub-nav-list__item+h3, p+h3 {
        padding-top: 2.56215rem;
    }
}.breadcrumb, .cart-item-price__text, .checkout-step-header__title, .confirm-content__button, .delivery-info__title, .order-actions__title, .order-confirmation__title, .order-summary-header__price, .order-summary-header__title, .order-summary-item__key--dark, .order-summary-item__value--dark, .preamble, .product-card-content__price, .product-table-item__price, .search-address-header__title, .sidecart-content-action__button, .spot-content__title {
     font-style: normal;
     font-weight: 400;
     line-height: 2.5rem;
     font-size: 1.93829rem;
     margin-bottom: .38962rem;
     padding-top: .61038rem;
 }
@media screen and (min-width:31.25em) {
    .breadcrumb, .cart-item-price__text, .checkout-step-header__title, .confirm-content__button, .delivery-info__title, .order-actions__title, .order-confirmation__title, .order-summary-header__price, .order-summary-header__title, .order-summary-item__key--dark, .order-summary-item__value--dark, .preamble, .product-card-content__price, .product-table-item__price, .search-address-header__title, .sidecart-content-action__button, .spot-content__title {
        line-height: 2.5rem;
        font-size: 1.7rem;
        margin-bottom: 1.38962rem;
        padding-top: .61038rem;
    }
}@media screen and (min-width:48em) {
    .breadcrumb, .cart-item-price__text, .checkout-step-header__title, .confirm-content__button, .delivery-info__title, .order-actions__title, .order-confirmation__title, .order-summary-header__price, .order-summary-header__title, .order-summary-item__key--dark, .order-summary-item__value--dark, .preamble, .product-card-content__price, .product-table-item__price, .search-address-header__title, .sidecart-content-action__button, .spot-content__title {
        line-height: 2rem;
        font-size: 1.93829rem;
        margin-bottom: 1.63962rem;
        padding-top: .36038rem;
    }
}@media screen and (min-width:64em) {
    .breadcrumb, .cart-item-price__text, .checkout-step-header__title, .confirm-content__button, .delivery-info__title, .order-actions__title, .order-confirmation__title, .order-summary-header__price, .order-summary-header__title, .order-summary-item__key--dark, .order-summary-item__value--dark, .preamble, .product-card-content__price, .product-table-item__price, .search-address-header__title, .sidecart-content-action__button, .spot-content__title {
        line-height: 3rem;
        font-size: 1.93829rem;
        margin-bottom: 2.13962rem;
        padding-top: .86038rem;
    }
}@media screen and (min-width:83.5em) {
    .breadcrumb, .cart-item-price__text, .checkout-step-header__title, .confirm-content__button, .delivery-info__title, .order-actions__title, .order-confirmation__title, .order-summary-header__price, .order-summary-header__title, .order-summary-item__key--dark, .order-summary-item__value--dark, .preamble, .product-card-content__price, .product-table-item__price, .search-address-header__title, .sidecart-content-action__button, .spot-content__title {
        line-height: 3rem;
        font-size: 1.93829rem;
        margin-bottom: 2.13962rem;
        padding-top: .86038rem;
    }
    .caption, .cart-item-info, .checkout-step-content__subtitle, .confirm-content, .confirm-content__small-text, .field-content__small-text, .field-wrapper__title, .filter-icon, .filter-paging, .footer-list__link, .help-nav-list__item, .link-button, .mobile-nav-content__title, .nav-tabs-list__item, .order-actions-section__text, .order-summary-item, .page-header__product-count, .paging-list__item, .panel-header__title, .product-actions__title, .product-card-content__text, .product-card-list-content-artno, .product-card-list-content-description, .product-choices-list__item, .product-choices__title, .product-information-item__title, .product-table-item-info, .product-table-item__key, .product-view, .promotion-content__text, .promotion-content__title, .search-address-header__paragraph, .search-address-item:hover:before, .search-address-section-results__paragraph, .search-field, .sidecart-content-footer__info, .sidecart-header__caption, .spot-content__read-more, .sub-nav__headline, .teaser-content__read-more, select {
        line-height: 1.5rem;
        font-size: 1.198rem;
        margin-bottom: .64533rem;
        padding-top: .35467rem;
    }
}.caption, .cart-item-info, .checkout-step-content__subtitle, .confirm-content, .confirm-content__small-text, .field-content__small-text, .field-wrapper__title, .filter-icon, .filter-paging, .footer-list__link, .help-nav-list__item, .link-button, .mobile-nav-content__title, .nav-tabs-list__item, .order-actions-section__text, .order-summary-item, .page-header__product-count, .paging-list__item, .panel-header__title, .product-actions__title, .product-card-content__text, .product-card-list-content-artno, .product-card-list-content-description, .product-choices-list__item, .product-choices__title, .product-information-item__title, .product-table-item-info, .product-table-item__key, .product-view, .promotion-content__text, .promotion-content__title, .search-address-header__paragraph, .search-address-item:hover:before, .search-address-section-results__paragraph, .search-field, .sidecart-content-footer__info, .sidecart-header__caption, .spot-content__read-more, .sub-nav__headline, .teaser-content__read-more, select {
     font-style: normal;
     font-weight: 400;
     line-height: 1.5rem;
     font-size: 1.198rem;
     margin-bottom: .64533rem;
     padding-top: .35467rem;
 }
.caption--bold, .mobile-nav-content__title, .product-actions__title, .product-choices__title, .product-information-item__title {
    font-weight: 700;
    color: #000;
}
a, li {
    font-weight: 400;
}
@media screen and (min-width:31.25em) {
    .caption, .cart-item-info, .checkout-step-content__subtitle, .confirm-content, .confirm-content__small-text, .field-content__small-text, .field-wrapper__title, .filter-icon, .filter-paging, .footer-list__link, .help-nav-list__item, .link-button, .mobile-nav-content__title, .nav-tabs-list__item, .order-actions-section__text, .order-summary-item, .page-header__product-count, .paging-list__item, .panel-header__title, .product-actions__title, .product-card-content__text, .product-card-list-content-artno, .product-card-list-content-description, .product-choices-list__item, .product-choices__title, .product-information-item__title, .product-table-item-info, .product-table-item__key, .product-view, .promotion-content__text, .promotion-content__title, .search-address-header__paragraph, .search-address-item:hover:before, .search-address-section-results__paragraph, .search-field, .sidecart-content-footer__info, .sidecart-header__caption, .spot-content__read-more, .sub-nav__headline, .teaser-content__read-more, select {
        line-height: 1.5rem;
        font-size: 1.198rem;
        margin-bottom: .64533rem;
        padding-top: .35467rem;
    }
}@media screen and (min-width:48em) {
    .caption, .cart-item-info, .checkout-step-content__subtitle, .confirm-content, .confirm-content__small-text, .field-content__small-text, .field-wrapper__title, .filter-icon, .filter-paging, .footer-list__link, .help-nav-list__item, .link-button, .mobile-nav-content__title, .nav-tabs-list__item, .order-actions-section__text, .order-summary-item, .page-header__product-count, .paging-list__item, .panel-header__title, .product-actions__title, .product-card-content__text, .product-card-list-content-artno, .product-card-list-content-description, .product-choices-list__item, .product-choices__title, .product-information-item__title, .product-table-item-info, .product-table-item__key, .product-view, .promotion-content__text, .promotion-content__title, .search-address-header__paragraph, .search-address-item:hover:before, .search-address-section-results__paragraph, .search-field, .sidecart-content-footer__info, .sidecart-header__caption, .spot-content__read-more, .sub-nav__headline, .teaser-content__read-more, select {
        line-height: 1.5rem;
        font-size: 1.198rem;
        margin-bottom: .64533rem;
        padding-top: .35467rem;
    }
}@media screen and (min-width:64em) {
    .caption, .cart-item-info, .checkout-step-content__subtitle, .confirm-content, .confirm-content__small-text, .field-content__small-text, .field-wrapper__title, .filter-icon, .filter-paging, .footer-list__link, .help-nav-list__item, .link-button, .mobile-nav-content__title, .nav-tabs-list__item, .order-actions-section__text, .order-summary-item, .page-header__product-count, .paging-list__item, .panel-header__title, .product-actions__title, .product-card-content__text, .product-card-list-content-artno, .product-card-list-content-description, .product-choices-list__item, .product-choices__title, .product-information-item__title, .product-table-item-info, .product-table-item__key, .product-view, .promotion-content__text, .promotion-content__title, .search-address-header__paragraph, .search-address-item:hover:before, .search-address-section-results__paragraph, .search-field, .sidecart-content-footer__info, .sidecart-header__caption, .spot-content__read-more, .sub-nav__headline, .teaser-content__read-more, select {
        line-height: 1.5rem;
        font-size: 1.198rem;
        margin-bottom: .64533rem;
        padding-top: .35467rem;
    }
    .alert-box__title, .cart-badge, .field-content__label, .order-summary-header__vat, .product-card-footer__text, .products-header__artno, .products-header__description, .products-header__title, .sidebar-nav__title, .sidecart-offer__title, .small, small {
        line-height: 1.5rem;
        font-size: .94181rem;
        margin-bottom: .06079rem;
        padding-top: .43921rem;
    }
}.alert-box__title, .cart-badge, .field-content__label, .order-summary-header__vat, .product-card-footer__text, .products-header__artno, .products-header__description, .products-header__title, .sidebar-nav__title, .sidecart-offer__title, .small, small {
     font-style: normal;
     font-weight: 400;
     line-height: 1.5rem;
     font-size: .94181rem;
     margin-bottom: .06079rem;
     padding-top: .43921rem;
 }
@media screen and (min-width:31.25em) {
    .alert-box__title, .cart-badge, .field-content__label, .order-summary-header__vat, .product-card-footer__text, .products-header__artno, .products-header__description, .products-header__title, .sidebar-nav__title, .sidecart-offer__title, .small, small {
        line-height: 1.5rem;
        font-size: .94181rem;
        margin-bottom: .06079rem;
        padding-top: .43921rem;
    }
}@media screen and (min-width:48em) {
    .alert-box__title, .cart-badge, .field-content__label, .order-summary-header__vat, .product-card-footer__text, .products-header__artno, .products-header__description, .products-header__title, .sidebar-nav__title, .sidecart-offer__title, .small, small {
        line-height: 1.5rem;
        font-size: .94181rem;
        margin-bottom: .06079rem;
        padding-top: .43921rem;
    }
}@media screen and (min-width:83.5em) {
    .alert-box__title, .cart-badge, .field-content__label, .order-summary-header__vat, .product-card-footer__text, .products-header__artno, .products-header__description, .products-header__title, .sidebar-nav__title, .sidecart-offer__title, .small, small {
        line-height: 1.5rem;
        font-size: .94181rem;
        margin-bottom: .06079rem;
        padding-top: .43921rem;
    }
}.btn, select {
     margin-bottom: 0;
 }
li {
    line-height: 2rem;
    font-size: 1.45238rem;
    margin-bottom: -.02072rem;
    padding-top: .52072rem;
}
@media screen and (min-width:31.25em) {
    li {
        line-height: 2rem;
        font-size: 1.45238rem;
        margin-bottom: -.02072rem;
        padding-top: .52072rem;
    }
}@media screen and (min-width:48em) {
    li {
        line-height: 2rem;
        font-size: 1.45238rem;
        margin-bottom: -.02072rem;
        padding-top: .52072rem;
    }
}@media screen and (min-width:64em) {
    li {
        line-height: 2rem;
        font-size: 1.45238rem;
        margin-bottom: -.02072rem;
        padding-top: .52072rem;
    }
}@media screen and (min-width:83.5em) {
    li {
        line-height: 2rem;
        font-size: 1.45238rem;
        margin-bottom: -.02072rem;
        padding-top: .52072rem;
    }
    blockquote p {
        font-size: 2.46552rem;
        margin-bottom: 3.31361rem;
        padding-top: .68639rem;
    }
}a {
     color: #686868;
     text-decoration: underline;
     -webkit-transition: color .1s, background-color .1s;
     transition: color .1s, background-color .1s;
 }
a:active, a:focus, a:hover {
    color: #686868;
    text-decoration: none;
}
.bold, b, strong {
    font-weight: 700;
}
.text-light, blockquote p {
    font-weight: 300;
}
blockquote p {
    border-left: none;
    font-style: normal;
    padding-left: 0;
    color: #565656;
    line-height: 3rem;
    font-size: 2.46552rem;
    margin-bottom: 3.31361rem;
    padding-top: .68639rem;
}
.btn, .white {
    color: #FFF;
}
@media screen and (min-width:31.25em) {
    blockquote p {
        font-size: 2.46552rem;
        margin-bottom: 3.31361rem;
        padding-top: .68639rem;
    }
}@media screen and (min-width:48em) {
    blockquote p {
        font-size: 2.46552rem;
        margin-bottom: 3.31361rem;
        padding-top: .68639rem;
    }
}@media screen and (min-width:64em) {
    blockquote p {
        font-size: 2.46552rem;
        margin-bottom: 3.31361rem;
        padding-top: .68639rem;
    }
}.btn {
     background-color: #C8002A;
     min-width: 300px;
     font-weight: 400;
     text-align: center;
     width: 100%;
     padding: 1.14286rem .95238rem;
 }
.btn--margin-top {
    margin-top: 2.625rem;
}
@media screen and (min-width:31.25em) {
    .btn--margin-top {
        margin-top: 2.56215rem;
    }
    code {
        font-size: 1.45238rem;
    }
}@media screen and (min-width:48em) {
    .btn {
        width: auto;
    }
    .btn--margin-top {
        margin-top: 2.56215rem;
    }
    code {
        font-size: 1.45238rem;
    }
}@media screen and (min-width:64em) {
    .btn--margin-top {
        margin-top: 2.56215rem;
    }
    code {
        font-size: 1.45238rem;
    }
}@media screen and (min-width:83.5em) {
    .btn--margin-top {
        margin-top: 2.56215rem;
    }
    code {
        font-size: 1.45238rem;
    }
}hr {
     background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), color-stop(50%, #BDC8CC));
     background-image: linear-gradient(to bottom, transparent 50%, #BDC8CC 50%);
     background-position: 0 50%;
     background-repeat: repeat-x;
     background-size: 100% .15rem;
     border: 0;
     margin: 0;
     padding-bottom: 3rem;
     padding-top: 3rem;
 }
code, pre {
    background-color: #2A2A2A;
}
pre {
    display: block;
    margin-bottom: 2rem;
    padding: 1rem;
    white-space: pre;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
}
.field-content-wrapper:after, .field-content-wrapper:before, .field-content:after, .field-content:before, .field-wrapper:after, .field-wrapper:before {
    display: table;
    content: ""}
.product-card-content__text, .product-card-content__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
code {
    font-size: 1.45238rem;
    line-height: 1rem;
}
.upper {
    -webkit-font-kerning: normal;
    font-kerning: normal;
}
.small-caps {
    -webkit-font-feature-settings: 'smcp', 'kern';
    font-feature-settings: 'smcp', 'kern';
    -webkit-font-kerning: normal;
    font-kerning: normal;
}
.lining-numerals {
    -webkit-font-feature-settings: 'lnum', 'kern';
    font-feature-settings: 'lnum', 'kern'}
.oldstyle-numerals {
    -webkit-font-feature-settings: 'onum', 'kern';
    font-feature-settings: 'onum', 'kern'}
select {
    height: 3.28125rem;
    width: 100%;
    padding: 0 .47619rem;
    outline: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url(../img/arrow-down.svg) 90% 50% no-repeat;
}
a:active, a:focus, button::-moz-focus-inner, button:focus, input[type=reset]::-moz-focus-inner, input[type=button]::-moz-focus-inner, input[type=submit]::-moz-focus-inner, input[type=file]>input[type=button]::-moz-focus-inner, select::-moz-focus-inner {
    outline: 0!important;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
.field-wrapper {
    margin-top: .65625rem;
}
@media screen and (min-width:31.25em) {
    select {
        height: 3.2184rem;
    }
    .field-wrapper {
        margin-top: .5934rem;
    }
}@media screen and (min-width:48em) {
    select {
        height: 3.2184rem;
    }
    .field-wrapper {
        margin-top: .5934rem;
    }
}@media screen and (min-width:64em) {
    select {
        height: 3.2184rem;
    }
    .field-wrapper {
        margin-top: .5934rem;
    }
}@media screen and (min-width:83.5em) {
    select {
        height: 3.2184rem;
    }
    .field-wrapper {
        margin-top: .5934rem;
    }
}.field-wrapper:after {
     clear: both;
 }
.field-wrapper--margin-top {
    margin-top: 1.96875rem;
}
.field-wrapper__title {
    color: #C8002A;
    font-weight: 700;
    clear: both;
}
.field-content {
    margin-right: 2px;
    margin-bottom: 2px;
    position: relative;
}
.field-content:after {
    clear: both;
}
.field-content--margin-top {
    padding-top: 1.96875rem;
}
@media screen and (min-width:31.25em) {
    .field-wrapper--margin-top {
        margin-top: 1.9059rem;
    }
    .field-content--margin-top {
        padding-top: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .field-wrapper--margin-top {
        margin-top: 1.9059rem;
    }
    .field-content--margin-top {
        padding-top: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .field-wrapper--margin-top {
        margin-top: 1.9059rem;
    }
    .field-content--margin-top {
        padding-top: 1.9059rem;
    }
}.field-content--25, .field-content--50, .field-content--75 {
     margin-right: 2px;
     width: calc(100% - 2px);
 }
@media screen and (min-width:48em) {
    .field-wrapper--margin-top {
        margin-top: 1.9059rem;
    }
    .field-content--margin-top {
        padding-top: 1.9059rem;
    }
    .field-content--25, .field-content--50, .field-content--75 {
        float: left;
    }
    .field-content--25 {
        width: calc(25% - 2px);
    }
    .field-content--50 {
        width: calc(50% - 2px);
    }
    .field-content--75 {
        width: calc(75% - 2px);
        margin-right: 2px;
    }
}.field-content-wrapper:after {
     clear: both;
 }
.field-content__small-text, .field-content__text {
    color: #2A2A2A;
}
.field-content__label {
    color: #BDC8CC;
    font-weight: 300;
    margin: 0;
    position: absolute;
    top: 8px;
    top: .7619rem;
    left: 10px;
    left: .95238rem;
    padding: 0!important;
    opacity: 0;
}
.is-active .field-content__label {
    opacity: 1;
}
.field-content__input {
    color: #2A2A2A;
    font-weight: 400;
    width: 100%;
    margin-bottom: 0;
    padding: 1.33333rem .95238rem;
}
.is-active .field-content__input {
    padding: 2rem .95238rem .66667rem;
}
.field-content__input--border {
    border: 1px solid #C9CDCF;
}
.field-content__input--border:focus, .field-content__input--border:hover {
    border: 1px solid #C8002A;
}
.field-content__input:disabled:focus, .field-content__input:disabled:hover {
    border: 1px solid #C9CDCF;
}
.field-content__input::-webkit-input-placeholder {
    color: #BDC8CC;
    opacity: 1;
    font-weight: 300;
}
.field-content__input:-ms-input-placeholder {
    opacity: 1;
    color: #BDC8CC;
    font-weight: 300;
}
.field-content__input::-ms-input-placeholder {
    opacity: 1;
    color: #BDC8CC;
    font-weight: 300;
}
.field-content__input::placeholder {
    color: #BDC8CC;
    opacity: 1;
    font-weight: 300;
}
.field-content__select, .field-content__textarea {
    color: #2A2A2A;
    font-weight: 400;
    margin-bottom: 0;
    width: 100%}
.field-content__select {
    padding: 0 .95238rem;
    height: 54.73px;
    height: 5.21238rem;
    line-height: 54.73px;
    line-height: 5.21238rem;
    background-color: #FFF;
    background-position: 98% 50%;
    border-radius: 0;
}
.field-content__select--border {
    border: 1px solid #C9CDCF;
}
.field-content__select--border:focus, .field-content__select--border:hover {
    border: 1px solid #C8002A;
}
.field-content__textarea {
    height: 150px;
    border: none;
    outline: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 1.33333rem .95238rem;
}
.is-active .field-content__textarea {
    padding: 2rem .95238rem .66667rem;
}
.field-content__textarea--border {
    border: 1px solid #C9CDCF;
}
.field-content__textarea--border:focus, .field-content__textarea--border:hover {
    border: 1px solid #C8002A;
}
.field-content__textarea::-webkit-input-placeholder {
    color: #BDC8CC;
    opacity: 1;
    font-weight: 300;
}
.field-content__textarea:-ms-input-placeholder {
    opacity: 1;
    color: #BDC8CC;
    font-weight: 300;
}
.field-content__textarea::-ms-input-placeholder {
    opacity: 1;
    color: #BDC8CC;
    font-weight: 300;
}
.field-content__textarea::placeholder {
    color: #BDC8CC;
    opacity: 1;
    font-weight: 300;
}
.field-content__button {
    background-color: #C8002A;
    color: #FFF;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
    padding: 1.14286rem .95238rem;
}
.label-screen {
    display: none;
}
.dashboard:after, .dashboard:before, .product:after, .product:before {
    display: table;
    content: ""}
.list {
    margin-bottom: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .list {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .list {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .list {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .list {
        margin-bottom: 1.24965rem;
    }
    .list__item--small {
        line-height: 1.5rem;
        font-size: 1.198rem;
        margin-bottom: .14533rem;
        padding-top: .35467rem;
    }
}.list__item--small {
     font-style: normal;
     font-weight: 400;
     line-height: 1.5rem;
     font-size: 1.198rem;
     margin-bottom: .14533rem;
     padding-top: .35467rem;
 }
@media screen and (min-width:31.25em) {
    .list__item--small {
        line-height: 1.5rem;
        font-size: 1.198rem;
        margin-bottom: .14533rem;
        padding-top: .35467rem;
    }
}@media screen and (min-width:64em) {
    .list__item--small {
        line-height: 1.5rem;
        font-size: 1.198rem;
        margin-bottom: .14533rem;
        padding-top: .35467rem;
    }
}.dashboard:after {
     clear: both;
 }
@media screen and (min-width:48em) {
    .list__item--small {
        line-height: 1.5rem;
        font-size: 1.198rem;
        margin-bottom: .14533rem;
        padding-top: .35467rem;
    }
    .dashboard__col-one {
        width: 66.19718%;
        float: left;
        margin-right: 1.40845%}
    .dashboard__col-two {
        width: 32.39437%;
        float: right;
        margin-right: 0;
    }
}.settings-form {
     margin-bottom: 2.625rem;
 }
.tickets-form {
    margin-top: 1.96875rem;
}
@media screen and (min-width:31.25em) {
    .settings-form {
        margin-bottom: 2.56215rem;
    }
    .tickets-form {
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .settings-form {
        margin-bottom: 2.56215rem;
    }
    .tickets-form {
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .settings-form {
        margin-bottom: 2.56215rem;
    }
    .tickets-form {
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .settings-form {
        margin-bottom: 2.56215rem;
    }
    .tickets-form {
        margin-top: 1.9059rem;
    }
}.general-information__subtitle, .information-address__subtitle {
     font-weight: 700;
     margin-bottom: 1.96875rem;
     margin-top: 1.96875rem;
 }
@media screen and (min-width:31.25em) {
    .general-information__subtitle, .information-address__subtitle {
        margin-bottom: 1.9059rem;
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .general-information__subtitle, .information-address__subtitle {
        margin-bottom: 1.9059rem;
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .general-information__subtitle, .information-address__subtitle {
        margin-bottom: 1.9059rem;
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .general-information__subtitle, .information-address__subtitle {
        margin-bottom: 1.9059rem;
        margin-top: 1.9059rem;
    }
}.general-information__button, .information-address__button {
     margin-top: 1.96875rem;
 }
@media screen and (min-width:31.25em) {
    .general-information__button, .information-address__button {
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .general-information__button, .information-address__button {
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .general-information__button, .information-address__button {
        margin-top: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .general-information__button, .information-address__button {
        margin-top: 1.9059rem;
    }
}.product {
     margin-bottom: 2.625rem;
     margin-top: 3.9375rem;
 }
.product:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .product {
        margin-bottom: 2.56215rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .product {
        margin-bottom: 2.56215rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .product {
        margin-bottom: 2.56215rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .product {
        margin-bottom: 5.25rem;
        margin-top: 3.9375rem;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .product {
        margin-bottom: 5.18715rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .product {
        margin-bottom: 5.18715rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .product {
        margin-bottom: 5.18715rem;
        margin-top: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .product {
        margin-bottom: 5.18715rem;
        margin-top: 3.87465rem;
    }
}.product-choices {
     margin-top: 1.3125rem;
     margin-bottom: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .product-choices {
        margin-top: 1.24965rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .product-choices {
        margin-top: 1.24965rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .product-choices {
        margin-top: 1.24965rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .product-choices {
        margin-top: 1.24965rem;
        margin-bottom: 2.56215rem;
    }
}.product-choices-list {
     border: 1px solid #2A383F;
     border-radius: 4px;
     display: inline-block;
 }
.product-choices-list:after, .product-choices-list:before {
    display: table;
    content: ""}
.product-choices-list:after {
    clear: both;
}
.product-choices-list__item {
    cursor: pointer;
    padding-left: 1.90476rem;
    padding-right: 1.90476rem;
    font-weight: 700;
    color: #000;
    height: 3.28125rem;
    float: left;
    display: table;
    border-right: 1px #2A383F solid;
    margin-bottom: 0!important;
}
@media screen and (min-width:31.25em) {
    .product-choices-list__item {
        height: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .product-choices-list__item {
        height: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .product-choices-list__item {
        height: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .product-choices-list__item {
        height: 3.2184rem;
    }
}.product-choices-list__item:last-child {
     border-right: none;
 }
.product-choices-list__item span {
    display: table-cell;
    vertical-align: middle;
}
.product-choices-list__item--active, .product-choices-list__item:hover {
    background-color: #2A383F;
    height: 3.28125rem;
    margin-bottom: 0;
    color: #FFF;
}
.product-choices-list__item:hover {
    background-color: #3e535e;
}
.product-choices__part {
    color: #7F7F7F;
    line-height: 1rem;
    font-size: 1.198rem;
    margin-bottom: .39533rem;
    padding-top: 10px!important;
}
@media screen and (min-width:31.25em) {
    .product-choices-list__item--active, .product-choices-list__item:hover {
        height: 3.2184rem;
    }
    .product-choices__part {
        font-size: 1.198rem;
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:48em) {
    .product-choices-list__item--active, .product-choices-list__item:hover {
        height: 3.2184rem;
    }
    .product-choices__part {
        font-size: 1.198rem;
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:64em) {
    .product-choices-list__item--active, .product-choices-list__item:hover {
        height: 3.2184rem;
    }
    .product-choices__part {
        font-size: 1.198rem;
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:83.5em) {
    .product-choices-list__item--active, .product-choices-list__item:hover {
        height: 3.2184rem;
    }
    .product-choices__part {
        font-size: 1.198rem;
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}.product-choices--no-margin {
     margin: 0;
 }
.product-actions-inner:after, .product-actions-inner:before {
    display: table;
    content: ""}
.product-actions-inner:after {
    clear: both;
}
.product-actions__col-one {
    float: left;
    border: 1px solid #2A383F;
    border-radius: 4px 0 0 4px;
    border-right: 0;
}
.product-actions__col-two {
    float: left;
    width: 36px;
    background-color: #C8002A;
    border: 1px solid #C8002A;
    border-radius: 0 4px 4px 0;
    border-left: 0;
}
.product-actions__amount {
    float: left;
    border: none;
    height: 3.28125rem;
    margin-left: 1px;
    width: 75px;
    padding-left: 10px;
    font-weight: 700;
    color: #000;
}
@media screen and (min-width:31.25em) {
    .product-actions__amount {
        height: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .product-actions__amount {
        height: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .product-actions__amount {
        height: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .product-actions__amount {
        height: 3.2184rem;
    }
}.product-actions__current-price {
     display: block;
     float: left;
     width: 75px;
     background-color: #2A383F;
     height: 3.28125rem;
     margin-bottom: 0;
     color: #FFF;
     text-align: center;
     font-size: 1.2rem;
 }
.product-actions__icon {
    display: block;
    position: relative;
    top: -1px;
    padding-left: 8px;
    padding-right: 8px;
}
.product-image {
    margin-bottom: 3.9375rem;
    background: #FFF;
    border: 1px solid #F6F6F6;
    -webkit-box-shadow: 0 20px 20px 0 rgba(220, 220, 220, .5);
    box-shadow: 0 20px 20px 0 rgba(220, 220, 220, .5);
    border-radius: 4px;
    text-align: center;
    position: relative;
    padding: .95238rem;
}
.next-thumb, .prev-thumb {
    position: absolute;
    top: calc(50% - 25px);
}
@media screen and (min-width:31.25em) {
    .product-actions__current-price {
        height: 3.2184rem;
    }
    .product-image {
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .product-actions__current-price {
        height: 3.2184rem;
    }
    .product-image {
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .product-actions__current-price {
        height: 3.2184rem;
    }
    .product-image {
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .product-actions__current-price {
        height: 3.2184rem;
    }
    .product-image {
        width: 45.74468%;
        float: left;
        margin-right: 2.12766%;
        margin-bottom: 3.80952rem;
        padding: 1.90476rem;
    }
}@media screen and (min-width:64em) {
    .product-image {
        width: 46.42857%;
        float: left;
        margin-right: 2.85714%;
        padding: 2.85714rem;
    }
}.product-image__img {
     width: auto!important;
 }
.product-image-thumbnails {
    margin-top: 1.90476rem;
}
.product-image-thumbnails:after, .product-image-thumbnails:before {
    display: table;
    content: ""}
.product-image-thumbnails:after {
    clear: both;
}
.product-image-thumbnails-inner {
    display: inline-block;
}
.product-image-thumbnail {
    padding: .47619rem;
    height: 48px;
    height: 4.57143rem;
    width: 48px;
    width: 4.57143rem;
    float: left;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.next-thumb__svg, .prev-thumb__svg {
    height: 36px;
    width: 36px;
}
.prev-thumb {
    left: 20px;
}
.next-thumb {
    right: 20px;
}
.product-content:after, .product-content:before {
    display: table;
    content: ""}
.product-content:after {
    clear: both;
}
.product-content__subtitle {
    color: accentColorThree;
    font-weight: 300;
    line-height: 2rem;
    font-size: 1.45238rem;
    margin-bottom: 2.47928rem;
    padding-top: .52072rem;
}
@media screen and (min-width:31.25em) {
    .product-content__subtitle {
        line-height: 2rem;
        font-size: 1.45238rem;
        margin-bottom: 2.47928rem;
        padding-top: .52072rem;
    }
}@media screen and (min-width:48em) {
    .product-content {
        width: 48.93617%;
        float: right;
        margin-right: 0;
    }
    .product-content__subtitle {
        line-height: 2rem;
        font-size: 1.45238rem;
        margin-bottom: 2.47928rem;
        padding-top: .52072rem;
    }
}@media screen and (min-width:64em) {
    .product-content {
        width: 48.57143%;
        float: right;
        margin-right: 0;
    }
    .product-content__subtitle {
        line-height: 2rem;
        font-size: 1.45238rem;
        margin-bottom: 2.47928rem;
        padding-top: .52072rem;
    }
}@media screen and (min-width:83.5em) {
    .product-content__subtitle {
        line-height: 2rem;
        font-size: 1.45238rem;
        margin-bottom: 2.47928rem;
        padding-top: .52072rem;
    }
}.product-information {
     margin-top: 3.9375rem;
     padding-top: .95238rem;
     padding-bottom: 3.9375rem;
     border-top: solid 1px #C9CDCF;
 }
@media screen and (min-width:31.25em) {
    .product-information {
        margin-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .product-information {
        margin-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .product-information {
        margin-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .product-information {
        margin-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}.product-information-item {
     border-bottom: solid 1px #C9CDCF;
     position: relative;
 }
.product-information-item-content {
    padding-bottom: 1.96875rem;
}
@media screen and (min-width:31.25em) {
    .product-information-item-content {
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .product-information-item-content {
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .product-information-item-content {
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .product-information-item-content {
        padding-bottom: 1.9059rem;
    }
}.product-information-item__svg {
     position: absolute;
     top: 10px;
     right: 0;
     width: 20px;
     height: 20px;
 }
.product-information-item__title {
    padding-top: 1.3125rem;
    padding-bottom: .65625rem;
}
@media screen and (min-width:31.25em) {
    .product-information-item__title {
        padding-top: 1.24965rem;
        padding-bottom: .5934rem;
    }
}@media screen and (min-width:64em) {
    .product-information-item__title {
        padding-top: 1.24965rem;
        padding-bottom: .5934rem;
    }
}@media screen and (min-width:83.5em) {
    .product-information-item__title {
        padding-top: 1.24965rem;
        padding-bottom: .5934rem;
    }
}@media (max-width:64em) {
    .checkout .main-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .checkout .main-content__primary {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        width: 100%;
        margin-right: 0;
    }
    .checkout .main-content__sidebar {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        width: 100%}
}.my-account-login {
     position: relative;
     overflow: hidden;
 }
.my-account-login:after, .my-account-login:before {
    display: table;
    content: ""}
.my-account-login:after {
    clear: both;
}
@media screen and (min-width:48em) {
    .product-information-item__title {
        padding-top: 1.24965rem;
        padding-bottom: .5934rem;
    }
    .my-account-login:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        background-color: #C9CDCF;
    }
}.my-account-login__center-text {
     display: none;
 }
@media screen and (min-width:48em) {
    .my-account-login__center-text {
        display: block;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 12px);
        padding-bottom: .47619rem;
        width: 50px;
        background-color: #FFF;
        text-align: center;
    }
    .my-account-login__col-one {
        width: 42.55319%;
        float: left;
        margin-right: 2.12766%}
}@media screen and (min-width:64em) {
    .my-account-login__col-one {
        width: 44.28571%;
        float: left;
        margin-right: 2.85714%}
}.my-account-login-action {
     margin-top: 1.96875rem;
     margin-bottom: 1.96875rem;
 }
.my-account-login-action:after, .my-account-login-action:before {
    display: table;
    content: ""}
.my-account-login-action:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .my-account-login-action {
        margin-top: 1.9059rem;
        margin-bottom: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .my-account-login__col-two {
        width: 42.55319%;
        float: right;
        margin-right: 0;
    }
    .my-account-login-action {
        margin-top: 1.9059rem;
        margin-bottom: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .my-account-login__col-two {
        width: 44.28571%;
        float: right;
        margin-right: 0;
    }
    .my-account-login-action {
        margin-top: 1.9059rem;
        margin-bottom: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .my-account-login-action {
        margin-top: 1.9059rem;
        margin-bottom: 1.9059rem;
    }
}.faq-items {
     margin-top: 1.96875rem;
     margin-bottom: 3.9375rem;
 }
@media screen and (min-width:31.25em) {
    .faq-items {
        margin-top: 1.9059rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .faq-items {
        margin-top: 1.9059rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .faq-items {
        margin-top: 1.9059rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .faq-items {
        margin-top: 1.9059rem;
        margin-bottom: 3.87465rem;
    }
}.link-button--no-border {
     text-decoration: underline;
 }
.footer-list {
    margin-top: .98438rem;
    margin-bottom: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .footer-list {
        margin-top: .92153rem;
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .footer-list {
        margin-top: .92153rem;
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .footer-list {
        margin-top: .92153rem;
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .footer-list {
        margin-top: .92153rem;
        margin-bottom: 1.24965rem;
    }
}.footer-list__link {
     color: #949B8F;
     font-weight: 300;
     text-decoration: none;
 }
.footer-contact {
    text-align: center;
    margin-top: 1.96875rem;
    padding-top: 1.96875rem;
    padding-bottom: 1.96875rem;
    border-top: 1px #1E252B solid;
    margin-right: 2.5%}
@media screen and (min-width:31.25em) {
    .footer-contact {
        margin-top: 1.9059rem;
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .footer-contact {
        margin-top: 1.9059rem;
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .footer-contact {
        margin-top: 1.9059rem;
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .footer-contact {
        margin-top: 1.9059rem;
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
    }
}.footer-contact-list {
     display: inline-block;
 }
.footer-contact-list__item {
    color: #FFF;
    display: inline-block;
    text-align: center;
    margin-bottom: 0;
}
.footer-contact-list__link {
    display: block;
    color: inherit;
    position: relative;
    padding: 0 .57143rem;
    margin: 0 1.14286rem;
    text-decoration: none;
}
.footer-contact-list__link:active, .footer-contact-list__link:focus, .footer-contact-list__link:hover {
    color: inherit;
}
.footer-contact-list-icon {
    display: block;
}
@media screen and (min-width:48em) {
    .footer-contact-list-icon {
        display: inline-block;
        vertical-align: middle;
    }
}.footer-contact-list-icon__svg {
     margin: 0 auto;
     text-align: center;
     vertical-align: top;
     width: 24px;
     width: 2.28571rem;
 }
.link-button__svg, .nav-tabs-list-icon {
    vertical-align: middle;
    position: relative;
}
.footer-contact-list__text {
    display: inline-block;
    vertical-align: middle;
}
.footer-about__text {
    margin-top: .98438rem;
    font-weight: 300;
    color: #FFF;
}
@media screen and (min-width:31.25em) {
    .footer-about__text {
        margin-top: .92153rem;
    }
}@media screen and (min-width:48em) {
    .footer-contact-list__text {
        margin-left: .28571rem;
    }
    .footer-about__text {
        margin-top: .92153rem;
    }
}@media screen and (min-width:64em) {
    .footer-about__text {
        margin-top: .92153rem;
    }
}@media screen and (min-width:83.5em) {
    .footer-about__text {
        margin-top: .92153rem;
    }
}.footer-about__links {
     padding-top: 3.28125rem;
     padding-bottom: 3.28125rem;
     margin: 0;
 }
@media screen and (min-width:31.25em) {
    .footer-about__links {
        padding-top: 3.2184rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .footer-about__links {
        padding-top: 3.2184rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .footer-about__links {
        padding-top: 3.2184rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .footer-about__links {
        position: absolute;
        padding-top: 0;
        padding-bottom: 0;
        bottom: 1.96875rem;
    }
}.footer-about__links:active, .footer-about__links:focus, .footer-about__links:hover {
     color: inherit;
 }
.footer-about__link {
    color: #bbb;
    line-height: 1.50937rem;
    display: inline-block;
    text-decoration: none;
}
.footer-about__link:active, .footer-about__link:focus, .footer-about__link:hover {
    color: inherit;
}
.link-button {
    padding: .95238rem 1.90476rem!important;
    font-weight: 700;
    color: #2A2A2A;
    display: inline-block;
    border: 1px solid #2A2A2A;
    border-radius: 4px;
    background: #FFF;
    text-decoration: none;
    text-align: center;
    margin-bottom: 0!important;
}
.link-button--neg {
    border-color: #C8002A;
    background-color: #C8002A;
}
.link-button--full {
    width: 100%}
.link-button--center {
    margin: 0 auto;
}
.link-button:hover {
    background: #F4F5F5;
    color: inherit;
}
.link-button__svg {
    display: inline-block;
    width: 9px;
    top: -1px;
}
.link-button__svg--large {
    width: 15px;
}
.link-button__svg--first {
    margin-right: .57143rem!important;
}
.link-button__svg--last {
    margin-left: .57143rem!important;
}
.link-button__name {
    font-weight: 700;
}
.link-button__name--neg {
    color: #FFF;
}
.link-button--no-border {
    border: none;
    padding-left: 0!important;
    padding-right: 0!important;
}
.link-button--no-border:hover {
    background: 0 0;
}
.link-button--underline {
    text-decoration: underline;
}
.link-button--underline:active, .link-button--underline:focus, .link-button--underline:hover, .nav-tabs-list__link {
    text-decoration: none;
}
.link-button--margin-right {
    margin-right: 20px;
}
.link-button--no-padding {
    padding: 0!important;
}
.link-button--alert {
    background: #C8002A;
    border: 1px solid #C8002A;
    color: #FFF;
}
.link-button--alert:hover {
    background: #2A383F;
    border: 1px solid #2A383F;
    color: #FFF;
}
.nav-tabs {
    margin-bottom: 2.625rem;
}
@media screen and (min-width:31.25em) {
    .footer-about__link {
        line-height: 1.44653rem;
    }
    .nav-tabs {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .footer-about__link {
        line-height: 1.44653rem;
    }
    .nav-tabs {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .footer-about__link {
        line-height: 1.44653rem;
    }
    .nav-tabs {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .footer-about__link {
        line-height: 1.44653rem;
    }
    .nav-tabs {
        margin-bottom: 2.56215rem;
    }
}.nav-tabs-list {
     min-width: 100%;
     border-bottom: 1px #C9CDCF solid;
 }
.nav-tabs-list:after, .nav-tabs-list:before {
    display: table;
    content: ""}
.nav-tabs-list:after {
    clear: both;
}
.nav-tabs-list__item {
    color: #000;
    float: left;
    margin-bottom: -1px;
}
.nav-tabs-list__item.active {
    background: #FFF;
    border: 1px solid #C9CDCF;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 0;
}
.nav-tabs-list__link {
    display: block;
    color: inherit;
    padding: .38095rem 1.71429rem .57143rem;
}
.nav-tabs-list-icon {
    display: inline-block;
}
.nav-tabs-list-icon__svg {
    width: 16px;
    width: 1.52381rem;
}
.nav-tabs-list-text {
    display: none;
}
@media screen and (min-width:48em) {
    .nav-tabs-list__link {
        padding: .38095rem 1.52381rem .57143rem;
    }
    .nav-tabs-list-icon {
        margin-right: .19048rem;
    }
    .nav-tabs-list-text {
        display: inline-block;
    }
}.cart {
     padding-bottom: 0;
 }
.cart-item {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px #C9CDCF solid;
    position: relative;
}
.cart-item:after, .cart-item:before {
    display: table;
    content: ""}
.cart-item:after {
    clear: both;
}
.cart-item--active:before {
    content: "";
    display: block;
    position: absolute;
    height: calc(100% - 20px);
    top: 0;
    margin-bottom: 2.625rem;
    left: -1.3125rem;
    width: 2px;
    background: #C8002A;
}
@media screen and (min-width:31.25em) {
    .cart-item--active:before {
        margin-bottom: 2.56215rem;
        left: -1.37535rem;
    }
}@media screen and (min-width:64em) {
    .cart-item--active:before {
        margin-bottom: 2.56215rem;
        left: -1.37535rem;
    }
}@media screen and (min-width:83.5em) {
    .cart-item--active:before {
        margin-bottom: 2.56215rem;
        left: -1.37535rem;
    }
}@media screen and (min-width:48em) {
    .cart-item--active:before {
        margin-bottom: 2.56215rem;
        left: -2.625rem;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .footer-about__links {
        bottom: 1.9059rem;
    }
    .cart-item--active:before {
        left: -2.68785rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .footer-about__links {
        bottom: 1.9059rem;
    }
    .cart-item--active:before {
        left: -2.68785rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .footer-about__links {
        bottom: 1.9059rem;
    }
    .cart-item--active:before {
        left: -2.68785rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .footer-about__links {
        bottom: 1.9059rem;
    }
    .cart-item--active:before {
        left: -2.68785rem;
    }
}.cart-item:first-child {
     margin-top: 0;
 }
.cart-item:last-child {
    border-bottom: none;
}
.cart-item-image {
    width: 20.40816%;
    float: left;
    margin-right: 6.12245%}
@media screen and (min-width:48em) {
    .cart-item-image {
        width: 14.28571%;
        float: left;
        margin-right: 2.85714%}
}@media screen and (min-width:64em) {
    .cart-item-image {
        width: 8.69565%;
        float: left;
        margin-right: 4.34783%}
}.cart-item-content {
     width: 73.46939%;
     float: right;
     margin-right: 0;
 }
.cart-item-content-row {
    margin-bottom: .65625rem;
    display: table;
}
@media screen and (min-width:31.25em) {
    .cart-item-content-row {
        margin-bottom: .5934rem;
    }
}@media screen and (min-width:48em) {
    .cart-item-content {
        width: 82.85714%;
        float: right;
        margin-right: 0;
    }
    .cart-item-content-row {
        margin-bottom: .5934rem;
    }
}@media screen and (min-width:64em) {
    .cart-item-content {
        width: 60%;
        float: left;
        margin-right: 4.34783%}
    .cart-item-content-row {
        margin-bottom: .5934rem;
    }
}@media screen and (min-width:83.5em) {
    .cart-item-content-row {
        margin-bottom: .5934rem;
    }
    .cart-item-content-title__link {
        margin-bottom: 1.97928rem;
        padding-top: .02072rem;
    }
}@media screen and (min-width:48em) {
    .cart-item-content-row__col-one {
        width: 120px;
        display: table-cell;
        vertical-align: middle;
    }
    .cart-item-content-row__col-two {
        width: calc(100% - 140px);
        padding-right-right: 20px;
        display: table-cell;
        vertical-align: middle;
    }
    .cart-item-content-title__link {
        margin-bottom: 1.97928rem;
        padding-top: .02072rem;
    }
}.cart-item-content-title__link {
     display: block;
     text-decoration: underline;
     color: #2A2A2A;
     font-weight: 700;
    //  line-height: 1rem;
     margin-bottom: 1.97928rem;
     padding-top: .02072rem;
 }
.cart-item-info__key, .cart-item-info__value, .cart-item__amount {
    display: inline-block;
}
@media screen and (min-width:31.25em) {
    .cart-item-content-title__link {
        margin-bottom: 1.97928rem;
        padding-top: .02072rem;
    }
}@media screen and (min-width:64em) {
    .cart-item-content-title__link {
        margin-bottom: 1.97928rem;
        padding-top: .02072rem;
    }
    .cart-item-info {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}.cart-item-info {
     line-height: 1rem;
     margin-bottom: .39533rem;
     padding-top: .10467rem;
 }
.cart-item-info__key {
    color: #C8002A;
    width: 50px;
    width: 4.7619rem;
}
.cart-item-info__key--dark {
    color: #1E252B;
    font-weight: 700;
}
.cart-item-info__value {
    color: #2A2A2A;
}
.cart-item-price {
    width: 73.46939%;
    float: right;
    margin-right: 0;
}
.cart-item-price__text {
    color: #C8002A;
}
.cart-item-actions {
    margin-top: 2.625rem;
}
@media screen and (min-width:48em) {
    .cart-item-info {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
    .cart-item-price {
        width: 82.85714%;
        float: right;
        margin-right: 0;
    }
    .cart-item-actions {
        margin-top: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .cart-item-price {
        width: 20%;
        float: right;
        margin-right: 0;
    }
    .cart-item-actions {
        margin-top: 2.56215rem;
    }
}.cart-item__amount {
     border: 1px solid #2A383F;
     border-radius: 4px;
     padding-left: 1.90476rem;
     padding-right: .47619rem;
     font-weight: 700;
     color: #000;
     height: 3.28125rem;
     width: 75px;
 }
@media screen and (min-width:31.25em) {
    .cart-item-info {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
    .cart-item-actions {
        margin-top: 2.56215rem;
    }
    .cart-item__amount {
        height: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .cart-item__amount {
        height: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .cart-item__amount {
        height: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .cart-item-info {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
    .cart-item-actions {
        margin-top: 2.56215rem;
    }
    .cart-item__amount {
        height: 3.2184rem;
    }
}.cart-item__amount:hover {
     border: 1px solid #2A383F;
 }
.cart-badge {
    position: absolute;
    top: -10px;
    right: calc(50% - 20px);
    width: 18px;
    height: 18px;
    line-height: 18px!important;
    margin: 0!important;
    padding: 0 1px 0 0!important;
    font-weight: 700;
    text-align: center;
    background: #C8002A;
    border-radius: 50%}
.checkout-step-header {
    background-color: #C9CDCF;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0;
    margin-bottom: 0;
}
.checkout-step-header__title {
    padding-top: 0;
    margin-bottom: 0;
}
.checkout-step-content {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 1.96875rem;
    padding-bottom: 2.625rem;
}
.checkout-step-content:after, .checkout-step-content:before {
    display: table;
    content: ""}
.checkout-step-content:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .checkout-step-content {
        padding-top: 1.9059rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .cart-badge {
        right: 20px;
        left: 20px;
    }
    .checkout-step-content {
        padding-top: 1.9059rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .checkout-step-content {
        padding-top: 1.9059rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .checkout-step-content {
        padding-top: 1.9059rem;
        padding-bottom: 2.56215rem;
    }
}.checkout-step--payment .checkout-step-content {
     padding-bottom: 4.59375rem;
 }
@media screen and (min-width:31.25em) {
    .checkout-step--payment .checkout-step-content {
        padding-bottom: 4.5309rem;
    }
}@media screen and (min-width:64em) {
    .checkout-step--payment .checkout-step-content {
        padding-bottom: 4.5309rem;
    }
}@media screen and (min-width:83.5em) {
    .checkout-step--payment .checkout-step-content {
        padding-bottom: 4.5309rem;
    }
}@media screen and (min-width:48em) {
    .checkout-step--payment .checkout-step-content {
        padding-bottom: 4.5309rem;
    }
    .checkout-step-content {
        padding-top: 1.96875rem;
        padding-bottom: 3.28125rem;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .checkout-step-content {
        padding-top: 1.9059rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .checkout-step-content {
        padding-top: 1.9059rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .checkout-step-content {
        padding-top: 1.9059rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .checkout-step-content {
        padding-top: 1.9059rem;
        padding-bottom: 3.2184rem;
    }
}.checkout-step-content__subtitle {
     font-weight: 700;
     display: block;
     color: #C8002A;
     margin-bottom: 1.3125rem;
     margin-left: 30px;
 }
@media screen and (min-width:31.25em) {
    .checkout-step-content__subtitle {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .checkout-step-content__subtitle {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .checkout-step-content__subtitle {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .checkout-step-content__subtitle {
        margin-bottom: 1.24965rem;
    }
}.checkout-step--delivery .checkout-step-content__subtitle {
     margin-bottom: 1.96875rem;
 }
@media screen and (min-width:31.25em) {
    .checkout-step--delivery .checkout-step-content__subtitle {
        margin-bottom: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .checkout-step--delivery .checkout-step-content__subtitle {
        margin-bottom: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .checkout-step--delivery .checkout-step-content__subtitle {
        margin-bottom: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .checkout-step--delivery .checkout-step-content__subtitle {
        margin-bottom: 1.9059rem;
    }
}.checkout-step-content__input {
     height: 13px;
     height: 1.2381rem;
     position: relative;
     top: 2px;
     top: .19048rem;
 }
.checkout-step-content-wrapper--card .checkout-step-content__input {
    top: 10px;
    top: .95238rem;
}
.checkout-step-content-wrapper:after, .checkout-step-content-wrapper:before {
    display: table;
    content: ""}
.checkout-step-content-wrapper:after {
    clear: both;
}
.checkout-step-content-wrapper__col-full, .checkout-step-content-wrapper__col-one, .checkout-step-content-wrapper__col-two {
    margin-bottom: 0;
}
.checkout-step-content-wrapper__col-full {
    margin-left: 30px;
    margin-top: 2.625rem;
}
@media screen and (min-width:31.25em) {
    .checkout-step-content-wrapper__col-full, .checkout-step-content-wrapper__col-one, .checkout-step-content-wrapper__col-two {
        margin-bottom: -.06285rem;
    }
    .checkout-step-content-wrapper__col-full {
        margin-top: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .checkout-step-content-wrapper__col-full, .checkout-step-content-wrapper__col-one, .checkout-step-content-wrapper__col-two {
        margin-bottom: -.06285rem;
    }
    .checkout-step-content-wrapper__col-full {
        margin-top: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .checkout-step-content-wrapper__col-full, .checkout-step-content-wrapper__col-one, .checkout-step-content-wrapper__col-two {
        margin-bottom: -.06285rem;
    }
    .checkout-step-content-wrapper__col-full {
        margin-top: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .checkout-step-content-wrapper__col-full, .checkout-step-content-wrapper__col-one, .checkout-step-content-wrapper__col-two {
        margin-bottom: -.06285rem;
    }
    .checkout-step-content-wrapper__col-full {
        margin-top: 2.56215rem;
    }
}.checkout-step-content-wrapper__col-one {
     display: block;
     float: left;
     width: 30px;
     margin: 0;
     padding: 0;
     position: relative;
 }
.delivery-info:after, .delivery-info:before, .order-summary-header:after, .order-summary-header:before, .sidecart-content-footer:after, .sidecart-content-footer:before, .sidecart-offer__inner:after, .sidecart-offer__inner:before, .sidecart:after, .sidecart:before {
    display: table;
    content: ""}
.checkout-step-content-wrapper__col-two {
    float: right;
    width: calc(100% - 30px);
}
.overlay, .sidecart {
    position: fixed;
    width: 100%;
    height: 100%}
.confirm {
    text-align: center;
    margin-bottom: 3.9375rem;
}
.confirm-content {
    margin-top: 1.3125rem;
    color: #2A2A2A;
}
@media screen and (min-width:31.25em) {
    .confirm {
        margin-bottom: 3.87465rem;
    }
    .confirm-content {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .confirm {
        margin-bottom: 3.87465rem;
    }
    .confirm-content {
        margin-top: 1.24965rem;
    }
    .sidecart {
        width: 75%}
}@media screen and (min-width:64em) {
    .confirm {
        margin-bottom: 3.87465rem;
    }
    .confirm-content {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .confirm {
        margin-bottom: 3.87465rem;
    }
    .confirm-content {
        margin-top: 1.24965rem;
    }
}.confirm-content__link {
     color: #2A2A2A;
     text-decoration: underline;
 }
.confirm-content__button {
    background-color: #C8002A;
    color: #FFF;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
    border-radius: 4px;
    padding: 1.14286rem 4.57143rem;
}
.overlay {
    z-index: 998;
    background-color: #000;
    opacity: .1;
}
.sidecart {
    padding: 1.90476rem;
    z-index: 999;
    top: 0;
    right: 0;
    background: #FFF;
    -webkit-box-shadow: -5px 5px 30px rgba(0, 0, 0, .5);
    box-shadow: -5px 5px 30px rgba(0, 0, 0, .5);
}
.sidecart:after {
    clear: both;
}
@media screen and (min-width:64em) {
    .sidecart {
        width: 50%}
}.sidecart__col-one {
     width: 20px;
     margin-right: 10px;
     float: left;
 }
.sidecart__col-two {
    width: calc(100% - 30px);
    float: right;
}
.sidecart-header {
    position: relative;
}
.sidecart-header__caption {
    margin-top: .65625rem;
}
@media screen and (min-width:31.25em) {
    .sidecart-header__caption {
        margin-top: .5934rem;
    }
}@media screen and (min-width:48em) {
    .sidecart-header__caption {
        margin-top: .5934rem;
    }
}@media screen and (min-width:64em) {
    .sidecart-header__caption {
        margin-top: .5934rem;
    }
}@media screen and (min-width:83.5em) {
    .sidecart-header__caption {
        margin-top: .5934rem;
    }
}.sidecart-close {
     position: absolute;
     top: 8px;
     left: -34px;
 }
.sidecart-close__svg {
    width: 20px;
    height: 20px;
}
.sidecart-offer {
    padding: 1.90476rem 1.90476rem 2.85714rem;
    margin-top: 2.625rem;
    margin-bottom: 2.625rem;
    border: 1px solid #C8002A;
    -webkit-box-shadow: 5px 5px 30px #ccc;
    box-shadow: 5px 5px 30px #ccc;
}
@media screen and (min-width:31.25em) {
    .sidecart-offer {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .sidecart-offer {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .sidecart-offer {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}.sidecart-offer__title {
     color: #C8002A;
 }
.sidecart-offer__inner:after {
    clear: both;
}
.sidecart-offer-image {
    width: 50px;
    margin-right: 20px;
    float: left;
}
.sidecart-offer-text {
    width: calc(100% - 70px);
    float: left;
}
@media screen and (min-width:48em) {
    .sidecart-offer {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
    .sidecart-offer-text {
        width: calc(100% - 210px);
        margin-right: 20px;
    }
}.sidecart-offer-text__info {
     color: #C8002A;
     font-weight: 700;
 }
.sidecart-action {
    margin-top: 1.3125rem;
    width: 100%;
    float: left;
}
@media screen and (min-width:31.25em) {
    .sidecart-action {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .sidecart-action {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .sidecart-action {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .sidecart-action {
        width: 100px;
        float: left;
        margin-top: 0;
    }
}.sidecart-action__button, .sidecart-content-action__button {
     color: #FFF;
     font-weight: 400;
     text-align: center;
     margin-bottom: 0;
     border-radius: 4px;
     width: 100%}
.sidecart-action__button {
    background-color: #C8002A;
    padding: .85714rem 1.33333rem;
}
.sidecart-content-action {
    padding-top: 1.3125rem;
    padding-bottom: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .sidecart-content-action {
        padding-top: 1.24965rem;
        padding-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .sidecart-content-action {
        padding-top: 1.24965rem;
        padding-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .sidecart-content-action {
        padding-top: 1.24965rem;
        padding-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .sidecart-content-action {
        padding-top: 1.24965rem;
        padding-bottom: 1.24965rem;
    }
}.sidecart-content-action__button {
     background-color: #C8002A;
     padding: 1.14286rem 4.57143rem;
 }
.sidecart-content-footer:after {
    clear: both;
}
.sidecart-content-footer__col-one {
    width: 10px;
    float: left;
    margin-right: 10px;
}
.sidecart-content-footer__col-two {
    width: calc(100% - 20px);
    float: right;
}
.sidecart-content-footer__svg {
    width: 10px;
    height: 10px;
}
.sidecart-content-footer__info {
    color: #565656;
}
.order-confirmation__title {
    font-weight: 300;
    margin-bottom: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .order-confirmation__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .order-confirmation__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .order-confirmation__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .order-confirmation__title {
        margin-bottom: 1.24965rem;
    }
}.delivery-info {
     padding-bottom: 3.9375rem;
     margin-bottom: 3.9375rem;
 }
.delivery-info:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .delivery-info {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .delivery-info {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .delivery-info {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .delivery-info {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}.delivery-info__col-one {
     margin-bottom: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .delivery-info__col-one {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .delivery-info__col-one {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .delivery-info__col-one {
        margin-bottom: 2.56215rem;
        width: 48.93617%;
        float: left;
        margin-right: 2.12766%}
}@media screen and (min-width:64em) {
    .delivery-info__col-one {
        width: 22.85714%;
        float: left;
        margin-right: 2.85714%;
        margin-bottom: 0;
    }
}.delivery-info__col-two {
     margin-bottom: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .delivery-info__col-two {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .delivery-info__col-two {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .delivery-info__col-two {
        margin-bottom: 2.56215rem;
        width: 48.93617%;
        float: right;
        margin-right: 0;
    }
}@media screen and (min-width:64em) {
    .delivery-info__col-two {
        width: 22.85714%;
        float: right;
        margin-right: 0;
        margin-bottom: 0;
    }
}.delivery-info__col-three {
     margin-bottom: 2.625rem;
 }
@media screen and (min-width:31.25em) {
    .delivery-info__col-three {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .delivery-info__col-three {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .delivery-info__col-three {
        margin-bottom: 2.56215rem;
        width: 48.93617%;
        float: left;
        margin-right: 2.12766%}
}@media screen and (min-width:64em) {
    .delivery-info__col-three {
        width: 22.85714%;
        float: left;
        margin-right: 2.85714%;
        margin-bottom: 0;
    }
}.delivery-info__title {
     font-weight: 300;
     margin-bottom: 1.3125rem;
 }
@media screen and (min-width:31.25em) {
    .delivery-info__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .delivery-info__col-four {
        width: 48.93617%;
        float: right;
        margin-right: 0;
    }
    .delivery-info__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .delivery-info__col-four {
        width: 22.85714%;
        float: right;
        margin-right: 0;
    }
    .delivery-info__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .delivery-info__title {
        margin-bottom: 1.24965rem;
    }
}.delivery-info__link {
     color: inherit;
     text-decoration: underline;
     font-weight: 300;
 }
.order-summary {
    background-color: #2A383F;
    padding-bottom: 3.9375rem;
    margin-bottom: 3.9375rem;
}
@media screen and (min-width:31.25em) {
    .order-summary {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .order-summary {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .order-summary {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .order-summary {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}.order-summary--no-bg {
     background-color: #FFF;
     padding: 0;
 }
.order-summary-header:after {
    clear: both;
}
.order-summary-header__title {
    float: left;
    color: #FFF;
    font-weight: 300;
    padding-bottom: 2.625rem;
}
.order-summary-header__price {
    float: right;
    color: #FFF;
    font-weight: 400;
    text-align: right;
}
.order-summary-header__vat {
    display: block;
    position: relative;
    top: -.16406rem;
    color: rgba(255, 255, 255, .5);
}
.order-summary-item--right:after, .order-summary-item--right:before, .order-summary-item:after, .order-summary-item:before {
    display: table;
    content: ""}
.order-summary-item {
    border-bottom: 1px rgba(255, 255, 255, .25) solid;
    line-height: 2.5rem;
    margin-bottom: -.85467rem;
    padding-top: .85467rem;
}
.order-summary-item:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .order-summary-header__title {
        padding-bottom: 2.56215rem;
    }
    .order-summary-header__vat {
        top: -.22691rem;
    }
    .order-summary-item {
        margin-bottom: -.85467rem;
        padding-top: .85467rem;
    }
}@media screen and (min-width:64em) {
    .order-summary-header__title {
        padding-bottom: 2.56215rem;
    }
    .order-summary-header__vat {
        top: -.22691rem;
    }
    .order-summary-item {
        margin-bottom: -.85467rem;
        padding-top: .85467rem;
    }
}@media screen and (min-width:83.5em) {
    .order-summary-header__title {
        padding-bottom: 2.56215rem;
    }
    .order-summary-header__vat {
        top: -.22691rem;
    }
    .order-summary-item {
        margin-bottom: -.85467rem;
        padding-top: .85467rem;
    }
}.order-summary-item--right:after {
     clear: both;
 }
.order-summary-item--right .order-summary-item__value--dark {
    width: 100px;
    text-align: right;
    float: right;
}
@media screen and (min-width:48em) {
    .order-summary-header__title {
        padding-bottom: 2.56215rem;
    }
    .order-summary-header__vat {
        top: -.22691rem;
    }
    .order-summary-item {
        margin-bottom: -.85467rem;
        padding-top: .85467rem;
    }
    .order-summary-item--right .order-summary-item__value--dark {
        width: 8.69565%;
        text-align: left;
    }
}.order-summary-item--right .order-summary-item__key--dark {
     margin-right: 1.90476rem;
     width: 150px;
     text-align: right;
     float: right;
 }
.order-summary-item:last-child {
    border: none;
}
.order-summary-item__key, .order-summary-item__value {
    display: block;
}
.order-summary-item__key {
    float: left;
    color: #FFF;
}
.order-summary-item__key--dark {
    color: #565656;
    margin-bottom: 0;
}
.order-summary-item__value {
    float: right;
    color: #FFF;
}
.order-summary-item__value--dark {
    color: #565656;
    margin-bottom: 0;
}
.order-actions {
    background-color: #1E252B;
    padding-bottom: 3.9375rem;
}
@media screen and (min-width:31.25em) {
    .order-actions {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .order-actions {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .order-actions {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .order-actions {
        padding-bottom: 3.87465rem;
    }
}.order-actions__title {
     color: #FFF;
     font-weight: 300;
     padding-bottom: 1.3125rem;
 }
.order-actions-section {
    padding-top: 1.3125rem;
}
.order-actions-section__title {
    color: #FFF;
}
.order-actions-section__form {
    margin-top: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .order-actions__title {
        padding-bottom: 1.24965rem;
    }
    .order-actions-section {
        padding-top: 1.24965rem;
    }
    .order-actions-section__form {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .order-actions__title {
        padding-bottom: 1.24965rem;
    }
    .order-actions-section {
        padding-top: 1.24965rem;
    }
    .order-actions-section__form {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .order-actions__title {
        padding-bottom: 1.24965rem;
    }
    .order-actions-section {
        padding-top: 1.24965rem;
    }
    .order-actions-section__form {
        margin-top: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .order-actions__title {
        padding-bottom: 1.24965rem;
    }
    .order-actions-section {
        padding-top: 1.24965rem;
    }
    .order-actions-section__form {
        margin-top: 1.24965rem;
    }
}.order-actions-section__center-title {
     position: relative;
     padding-top: 0;
     margin-top: 1.3125rem;
     margin-bottom: 0;
     text-align: center;
 }
.order-actions-section__center-title span {
    position: relative;
    z-index: 2;
    padding: 0 1.14286rem;
    background-color: #1E252B;
    display: inline-block;
    color: #FFF;
}
.order-actions-section__center-title:after {
    position: absolute;
    top: 50%;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, .25);
}
.breadcrumbs, .categories-nav {
    background: #F4F5F5;
    text-align: center;
}
.order-actions-section__text {
    color: #FFF;
    font-weight: 300;
}
.order-actions-section__link {
    color: inherit;
    text-decoration: underline;
    font-weight: 300;
}
.breadcrumbs {
    padding-top: 1.96875rem;
    padding-bottom: 1.96875rem;
}
@media screen and (min-width:31.25em) {
    .order-actions-section__center-title {
        margin-top: 1.24965rem;
    }
    .breadcrumbs {
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:64em) {
    .order-actions-section__center-title {
        margin-top: 1.24965rem;
    }
    .breadcrumbs {
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:83.5em) {
    .order-actions-section__center-title {
        margin-top: 1.24965rem;
    }
    .breadcrumbs {
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
    }
}@media screen and (min-width:48em) {
    .order-actions-section__center-title {
        margin-top: 1.24965rem;
    }
    .breadcrumbs {
        padding-top: 2.625rem;
        padding-bottom: 2.625rem;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .breadcrumbs {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .breadcrumbs {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .breadcrumbs {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .breadcrumbs {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}.breadcrumb {
     display: inline-block;
     margin: 0 auto;
     font-weight: 300;
 }
.breadcrumb__link {
    display: inline-block;
    vertical-align: top;
    font-weight: inherit;
    color: #C8002A;
}
.breadcrumb__link:active, .breadcrumb__link:hover, .breadcrumb__link:visited {
    color: #C8002A;
}
.breadcrumb__name {
    display: inline-block;
    vertical-align: top;
}
.breadcrumb-divider {
    font-weight: 300;
    margin: 0 1.14286rem;
}
.categories-nav {
    padding-bottom: 2.625rem;
}
@media screen and (min-width:31.25em) {
    .categories-nav {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .categories-nav {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .categories-nav {
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .categories-nav {
        padding-bottom: 3.9375rem;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .categories-nav {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .categories-nav {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .categories-nav {
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .categories-nav {
        padding-bottom: 3.87465rem;
    }
}.contact-links-nav {
     float: right;
 }
.contact-links-nav-list {
    text-align: center;
}
.contact-links-nav-list__item {
    color: #FFF;
    display: inline-block;
    margin-right: 1.90476rem;
    margin-bottom: 0;
    max-width: 100px;
}
.contact-links-nav-list__link {
    display: block;
    color: inherit;
    border-bottom: 2px transparent solid;
    text-decoration: none;
}
.contact-links-nav-list__link.active, .contact-links-nav-list__link:hover {
    color: inherit;
    border-bottom: 2px #FFF solid;
}
.contact-links-nav-list__svg {
    width: 30px;
    height: 22px;
    display: inline-block;
    float: left;
}
.contact-links-nav-list__span {
    float: left;
}
.search-form {
    border-bottom: 1px #fff solid;
}
.search-form__screen-reader-text {
    width: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
}
.search-field, .search-submit {
    height: 36px;
}
@media screen and (min-width:48em) {
    .contact-links-nav-list__item {
        max-width: none;
    }
    .search-form {
        background: 0 0;
        border-bottom: none;
    }
    .search-field, .search-submit {
        height: 46px;
    }
}.search-field {
     background-color: transparent;
     border: none;
     color: #FFF;
     cursor: pointer;
     line-height: 1rem;
     margin: 0;
     padding: 0;
     width: 80%;
     -webkit-transition: all .5s ease-in-out;
     transition: all .5s ease-in-out;
 }
@media screen and (min-width:48em) {
    .search-field {
        position: absolute;
        z-index: 999999;
        top: 0;
        right: 0;
        opacity: 0;
        width: 0;
        padding-left: 25px;
        padding-right: 61px;
    }
    .search-field:focus {
        width: 100%;
        background-color: #1E252B;
    }
}.search-field:focus {
     cursor: text;
     opacity: 1;
     outline: 0;
     z-index: 5;
 }
.search-field:focus:hover~.search-submit .icon-magnifier {
    color: #FFF;
}
.search-field:hover~.search-submit .icon-magnifier, .search-submit:hover .icon-magnifier {
    color: #fff;
}
.search-submit {
    background-color: transparent;
    border: none;
    float: right;
    position: relative;
    width: 36px;
    height: 36px;
    -webkit-transition: background-color .25s;
    transition: background-color .25s;
    z-index: 2;
    background-position: center center;
    background-repeat: no-repeat;
}
@media screen and (min-width:48em) {
    .search-submit {
        width: 46px;
        height: 46px;
    }
    .search-form:hover .search-submit {
        background-color: #14181c;
    }
}.search-field::-webkit-input-placeholder {
     color: #fff;
 }
.search-field::-moz-placeholder {
    color: #fff;
}
.search-field:-ms-input-placeholder {
    color: #fff;
}
.search-field:-moz-placeholder {
    color: #fff;
}
.help-nav {
    display: table;
    height: 100%;
    width: 100%;
    position: relative;
}
.help-nav-list {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}
.header__change-customer-type {
    display: none;
}
@media screen and (min-width:48em) {
    .help-nav-list {
        display: block;
    }

    .header__change-customer-type {
        display: inline-block;
    }
}.help-nav-list__item {
     color: #FFF;
     display: inline-block;
     max-width: 100px;
     text-align: center;
 }
@media screen and (min-width:48em) {
    .help-nav-list__item {
        max-width: none;
    }
    .help-nav-list__item:first-child {
        position: absolute;
        left: 0;
    }
}.help-nav-list__link {
     display: block;
     color: inherit;
     position: relative;
     text-decoration: none;
     padding: 0 .57143rem;
 }
.help-nav-list__link:active, .help-nav-list__link:focus, .help-nav-list__link:hover {
    color: inherit;
}
.help-nav-list-icon {
    display: block;
}
.help-nav-list-icon__svg {
    margin: 0 auto;
    text-align: center;
    width: 24px;
    width: 2.28571rem;
}
.help-nav-list__text {
    display: inline-block;
    vertical-align: middle;
}
@media screen and (min-width:48em) {
    .help-nav-list-icon {
        display: inline-block;
        vertical-align: middle;
    }
    .help-nav-list__text {
        margin-left: .28571rem;
    }
}.main-nav {
     display: none;
 }
@media screen and (min-width:48em) {
    .main-nav {
        display: block;
        padding-top: 3.28125rem;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .main-nav {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .main-nav {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .main-nav {
        padding-top: 3.2184rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .main-nav {
        padding-top: 3.2184rem;
    }
}.main-nav-list {
     text-align: center;
 }
.main-nav-list__item {
    color: #FFF;
    display: inline-block;
    margin-right: -.47619rem;
    margin-bottom: 0;
    max-width: 100px;
}
.main-nav-list__link {
    display: block;
    color: inherit;
    border-bottom: 2px transparent solid;
    padding: .95238rem 1.90476rem 1.14286rem;
    text-decoration: none;
}
.main-nav-list__link.active, .main-nav-list__link:hover {
    color: inherit;
    border-bottom: 2px #FFF solid;
}
.main-nav-list__link.active {
    background: #3F4C52;
}
.mobile-nav {
    padding: 1.3125rem;
    margin-top: 1.3125rem;
    margin-bottom: 1.3125rem;
    border: 1px solid #C9CDCF;
}
@media screen and (min-width:31.25em) {
    .mobile-nav {
        padding: 1.24965rem;
        margin-top: 1.24965rem;
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .main-nav-list__item {
        max-width: none;
    }
    .mobile-nav {
        padding: 1.24965rem;
        margin-top: 1.24965rem;
        margin-bottom: 1.24965rem;
        display: none;
    }
}@media screen and (min-width:64em) {
    .mobile-nav {
        padding: 1.24965rem;
        margin-top: 1.24965rem;
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .mobile-nav {
        padding: 1.24965rem;
        margin-top: 1.24965rem;
        margin-bottom: 1.24965rem;
    }
}.mobile-nav-content {
     position: relative;
 }
.mobile-nav-content__svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
}
.mobile-nav-content__title {
    margin: 0!important;
    padding: 0!important;
}
.sidebar-nav {
    display: none;
}
@media screen and (min-width:48em) {
    .sidebar-nav {
        display: block;
        padding-top: 1.3125rem;
        width: 100%}
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .sidebar-nav {
        padding-top: 1.24965rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .sidebar-nav {
        padding-top: 1.24965rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .sidebar-nav {
        padding-top: 1.24965rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .sidebar-nav {
        padding-top: 1.24965rem;
    }
}.sidebar-nav__title {
     color: #C8002A;
     margin-left: 1.90476rem;
 }
.sidebar-nav-list__item {
    color: #000;
    display: block;
    margin-right: -.47619rem;
    margin-bottom: 0;
    max-width: 100px;
    position: relative;
}
.sidebar-nav-list__item:before {
    content: "";
    position: absolute;
    background-image: url(../img/arrow-right.svg);
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    top: 23px;
    left: 9px;
    height: 8px;
    width: 8px;
    vertical-align: middle;
}
.sidebar-nav-list__link {
    display: block;
    color: inherit;
    font-weight: 700;
    border-bottom: 2px transparent solid;
    padding: .95238rem 1.42857rem .95238rem 1.90476rem;
    text-decoration: none;
}
.sidebar-nav-list__link.active, .sidebar-nav-list__link:hover {
    color: inherit;
}
.sidebar-nav-list__link.active {
    background: #EEE;
}
.sidebar-nav-list-level-two {
    list-style: none;
}
.sidebar-nav-list-level-two__item {
    margin-left: 3.80952rem;
    color: #000;
    position: relative;
}
.sidebar-nav-list-level-two__item:before {
    content: "–";
    position: absolute;
    margin-left: -1.1em;
}
.sidebar-nav-list-level-two__link {
    display: block;
    color: inherit;
    text-decoration: none;
}
.sidebar-nav-list-level-two__link.active, .sidebar-nav-list-level-two__link:hover {
    color: inherit;
}
.sub-nav {
    padding: 3.80952rem 1.90476rem;
    position: absolute;
    left: 0;
    background: #FFF;
    display: none;
    min-width: 600px;
    min-width: 57.14286rem;
    min-height: 250px;
    min-height: 23.80952rem;
    z-index: 9999;
    -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, .8);
    box-shadow: 5px 5px 30px rgba(0, 0, 0, .8);
}
.nav-teaser__text, .sub-nav__headline {
    text-align: left;
    line-height: 1rem;
    padding-top: .10467rem;
}
.alert-box, .product-card:before {
    -webkit-box-shadow: 5px 5px 30px #ccc;
}
@media screen and (min-width:48em) {
    .sidebar-nav-list__item {
        max-width: none;
    }
    .sub-nav {
        display: block;
    }
    .sub-nav__headline {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}.sub-nav-inner:after, .sub-nav-inner:before {
     display: table;
     content: ""}
.sub-nav-inner:after {
    clear: both;
}
.sub-nav-inner__col-one {
    float: left;
    width: 50%;
    padding-right: 20px;
}
.sub-nav-inner__col-two {
    float: right;
    width: 50%;
    text-align: left;
}
.sub-nav__headline {
    color: #C8002A;
    margin-bottom: .39533rem;
}
@media screen and (min-width:31.25em) {
    .sub-nav__headline {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:64em) {
    .sub-nav__headline {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:83.5em) {
    .sub-nav__headline {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}.sub-nav-list {
     text-align: left;
 }
.sub-nav-list__item {
    color: #000;
    margin-bottom: 0;
}
.sub-nav-list__link {
    display: block;
    color: inherit;
    text-decoration: none;
}
.sub-nav-list__link.active, .sub-nav-list__link:hover {
    color: inherit;
}
.nav-teaser__text {
    color: #000;
    font-weight: 700;
    font-size: 1.198rem;
    margin-bottom: 1.89533rem;
}
.alert-box-text, .pagination, .product-card-content, .product-card-footer, .product-table-item:first-child .product-table-item-col-four {
    text-align: center;
}
@media screen and (min-width:31.25em) {
    .nav-teaser__text {
        font-size: 1.198rem;
        margin-bottom: 1.89533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:48em) {
    .nav-teaser__text {
        font-size: 1.198rem;
        margin-bottom: 1.89533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:64em) {
    .nav-teaser__text {
        font-size: 1.198rem;
        margin-bottom: 1.89533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:83.5em) {
    .nav-teaser__text {
        font-size: 1.198rem;
        margin-bottom: 1.89533rem;
        padding-top: .10467rem;
    }
}.nav-teaser__svg {
     height: 8px;
     width: 8px;
 }
.paging-list {
    margin-top: 2.625rem;
    margin-bottom: 2.625rem;
    display: inline-block;
}
@media screen and (min-width:31.25em) {
    .paging-list {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .paging-list {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .paging-list {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .paging-list {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}.paging-list:after, .paging-list:before {
     display: table;
     content: ""}
.paging-list:after {
    clear: both;
}
.paging-list__item {
    float: left;
    color: #2A2A2A;
    margin: 0;
    border: 1px solid #CACDCF;
    border-left: 0;
}
.paging-list__item:first-child {
    border-left: 1px #CACDCF solid;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.paging-list__item:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.paging-list__item--active, .paging-list__item:hover {
    background-color: #CACDCF;
    color: #FFF;
}
.paging-list__item--active a, .paging-list__item:hover a {
    color: #FFF;
}
.paging-list__link {
    font-weight: 700;
    color: inherit;
    display: block;
    padding: .95238rem 1.71429rem 1.33333rem;
}
.paging-list__svg {
    width: 8px;
}
.product-card {
    background-color: #FFF;
    border: 1px solid #C9CDCF;
    border-radius: 4px;
    position: relative;
    z-index: 1;
}
.product-card:before {
    content: "";
    border: 1px solid #C9CDCF;
    border-radius: 4px;
    position: absolute;
    z-index: 0;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -18px;
    background-color: #fff;
    display: none;
    box-shadow: 5px 5px 30px #ccc;
}
.product-card:before:hover .product-card:before, .product-card:hover:before {
    display: block;
}
.products--gallery-2 .product-card:hover:before, .products--gallery-main-content .product-card:hover:before {
    bottom: 0;
}
.product-card__link {
    display: block;
    position: relative;
    text-decoration: none;
    z-index: 1;
    padding: 0 1.33333rem 1.71429rem;
}
@media screen and (min-width:31.25em) {
    .product-card__link {
        padding: 0 2.28571rem 1.71429rem;
    }
}@media screen and (min-width:48em) {
    .product-card__link {
        padding: 0 2.28571rem 1.71429rem;
    }
}@media screen and (min-width:64em) {
    .product-card__link {
        padding: 0 4.57143rem 1.71429rem;
    }
}.products--gallery-2 .product-card__link, .products--gallery-main-content .product-card__link {
     padding: 0 1.33333rem 1.71429rem;
 }
@media screen and (min-width:31.25em) {
    .products--gallery-2 .product-card__link, .products--gallery-main-content .product-card__link {
        padding: 0 2.28571rem 1.71429rem;
    }
}@media screen and (min-width:48em) {
    .products--gallery-2 .product-card__link, .products--gallery-main-content .product-card__link {
        padding: 0 1.33333rem 1.71429rem;
    }
    .product-card-image {
        padding-top: .57143rem;
        padding-bottom: .57143rem;
    }
}.product-card-image {
     padding-top: .57143rem;
     padding-bottom: .57143rem;
 }
@media screen and (min-width:31.25em) {
    .product-card-image {
        padding-top: .57143rem;
        padding-bottom: .57143rem;
    }
}@media screen and (min-width:64em) {
    .product-card-image {
        padding-top: .57143rem;
        padding-bottom: .95238rem;
    }
    .products--gallery-2 .product-card-image {
        padding-top: .57143rem;
        padding-bottom: .57143rem;
    }
    .product-card-content__title {
        line-height: 1.75rem;
        margin-bottom: .10428rem;
        padding-top: .39572rem;
    }
}.product-card-content__title {
     font-weight: 700;
     line-height: 1.75rem;
     margin-bottom: .10428rem;
     padding-top: .39572rem;
 }
@media screen and (min-width:31.25em) {
    .product-card-content__title {
        line-height: 1.75rem;
        margin-bottom: .10428rem;
        padding-top: .39572rem;
    }
}@media screen and (min-width:48em) {
    .product-card-content__title {
        line-height: 1.75rem;
        margin-bottom: .10428rem;
        padding-top: .39572rem;
    }
}@media screen and (min-width:83.5em) {
    .product-card-content__title {
        line-height: 1.75rem;
        margin-bottom: .10428rem;
        padding-top: .39572rem;
    }
    .product-card-content__price {
        margin-bottom: .00428rem;
        padding-top: .34572rem;
    }
}.product-card-content__price {
     color: #C8002A;
     font-weight: 700;
     line-height: 1.65rem;
     margin-bottom: .00428rem;
     padding-top: .34572rem;
 }
@media screen and (min-width:31.25em) {
    .product-card-content__price {
        margin-bottom: .00428rem;
        padding-top: .34572rem;
    }
}@media screen and (min-width:48em) {
    .product-card-content__price {
        margin-bottom: .00428rem;
        padding-top: .34572rem;
    }
}@media screen and (min-width:64em) {
    .product-card-content__price {
        margin-bottom: .00428rem;
        padding-top: .34572rem;
    }
    .product-card-footer__text {
        margin-bottom: -.27072rem;
        padding-top: .27072rem;
    }
}.product-card-footer__text {
     display: inline-block;
     line-height: 1.5rem;
     margin-bottom: -.27072rem;
     padding-top: .27072rem;
 }
@media screen and (min-width:31.25em) {
    .product-card-footer__text {
        margin-bottom: -.27072rem;
        padding-top: .27072rem;
    }
}@media screen and (min-width:48em) {
    .product-card-footer__text {
        margin-bottom: -.27072rem;
        padding-top: .27072rem;
    }
}@media screen and (min-width:83.5em) {
    .product-card-footer__text {
        margin-bottom: -.27072rem;
        padding-top: .27072rem;
    }
}.product-card-actions {
     position: absolute;
     z-index: 98;
     bottom: -.5rem;
     left: 1rem;
     right: 1rem;
     background-color: #fff;
     display: none;
 }
.product-card-actions:after, .product-card-actions:before {
    display: table;
    content: ""}
.product-card-actions:after {
    clear: both;
}
.product-card:hover .product-card-actions {
    display: block;
}
.products--gallery-2 .product-card:hover .product-card-actions, .products--gallery-main-content .product-card:hover .product-card-actions {
    display: none;
}
.product-card-actions__col-one {
    float: left;
    width: calc(100% - 36px);
    border: 1px solid #2A383F;
    border-radius: 4px 0 0 4px;
    border-right: 0;
}
.product-card-actions__col-two {
    float: left;
    width: 36px;
    background-color: #C8002A;
    border: 1px solid #C8002A;
    border-radius: 0 4px 4px 0;
    border-left: 0;
}
.product-card-actions__select {
    display: block;
    float: left;
    width: calc(100% - 75px);
}
.product-card-actions__current-price {
    display: block;
    float: left;
    width: 75px;
    background-color: #2A383F;
    height: 3.28125rem;
    margin-bottom: 0;
    color: #FFF;
    padding-left: 1.90476rem;
    padding-left: 10px;
    font-size: 1.1rem;
}
.product-card-actions__icon {
    display: block;
    position: relative;
    top: -1px;
    padding-left: 8px;
    padding-right: 8px;
}
.products-header {
    display: none;
    width: calc(100% - 210px);
    padding-left: 66px;
    margin-bottom: 1.3125rem;
}
.products-header:after, .products-header:before {
    display: table;
    content: ""}
.products-header:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .product-card-actions__current-price {
        height: 3.2184rem;
    }
    .products-header {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .product-card-actions__current-price {
        height: 3.2184rem;
    }
    .products-header {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .product-card-actions__current-price {
        height: 3.2184rem;
    }
    .products-header {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .product-card-actions__current-price {
        height: 3.2184rem;
    }
    .products-header {
        margin-bottom: 1.24965rem;
        display: block;
    }
}.products-header__description, .products-header__title {
     float: left;
     width: calc(50% - 50px);
 }
.products-header__artno {
    float: left;
}
.products-header__artno, .products-header__description, .products-header__title {
    text-transform: uppercase;
    color: #C8002A;
}
.product-card-list {
    padding: 10px;
    border: 1px solid #F4F5F5;
    border-bottom: 0;
}
.product-card-list:after, .product-card-list:before {
    display: table;
    content: ""}
.product-card-list:after {
    clear: both;
}
.product-card-list:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.product-card-list:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px #F4F5F5 solid;
}
.product-card-list-image {
    float: left;
    width: 48px;
    margin-right: 18px;
}
@media screen and (min-width:48em) {
    .product-card-list {
        padding: 0;
        height: 60px;
    }
    .product-card-list:nth-child(odd) {
        background: #F4F5F5;
    }
    .product-card-list:nth-child(odd) .product-card-list-image {
        border-bottom: 1px #F4F5F5 solid;
    }
    .product-card-list-image {
        border-right: 1px #F4F5F5 solid;
        height: 100%;
        display: table;
        background-color: #fff;
        text-align: center;
    }
    .product-card-list-image-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
}.product-card-list-image__img {
     display: block;
     background-color: #fff;
     padding: 10px;
     height: auto;
     width: auto!important;
     max-height: 59px;
 }
.product-card-list-content-artno, .product-card-list-content-description {
    padding-top: 0;
}
.product-card-list-content {
    float: left;
    width: calc(100% - 66px);
    height: 100%}
.product-card-list-content-artno, .product-card-list-content-description, .product-card-list-content-title {
    padding-right: 20px;
}
.product-card-list-content-title {
    padding-top: 0;
    text-decoration: underline;
}
@media screen and (min-width:48em) {
    .product-card-list-content {
        width: calc(100% - 266px);
        overflow: hidden;
    }
    .product-card-list-content:after, .product-card-list-content:before {
        display: table;
        content: ""}
    .product-card-list-content:after {
        clear: both;
    }
    .product-card-list-content-artno, .product-card-list-content-description, .product-card-list-content-title {
        display: table;
        height: 100%}
    .product-card-list-content-description, .product-card-list-content-title {
        float: left;
        width: calc(50% - 50px);
    }
}.product-card-list-content-title__text {
     display: table-cell;
     vertical-align: middle;
     color: #2A2A2A;
 }
.product-card-list-content-artno__text, .product-card-list-content-description__text {
    display: table-cell;
    color: #565656;
    vertical-align: middle;
}
.product-card-list-actions {
    margin-left: 66px;
}
@media screen and (min-width:48em) {
    .product-card-list-content-artno {
        float: left;
        width: 100px;
    }
    .product-card-list-actions {
        margin-left: 0;
        float: right;
        width: 200px;
        display: table;
        height: 100%;
        position: relative;
        z-index: 2;
    }
    .product-card-list-actions-cols {
        display: table-cell;
        vertical-align: middle;
    }
}.product-table-item {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     padding-bottom: .98438rem;
     margin-top: .65625rem;
     border-bottom: 1px #C9CDCF solid;
 }
.product-table-item__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.product-table-item__col-one {
    width: 20.40816%;
    float: left;
    margin-right: 6.12245%}
@media screen and (min-width:48em) {
    .product-table-item {
        padding-bottom: .92153rem;
        margin-top: .5934rem;
    }
    .product-table-item__col-one {
        width: 36px;
        margin-right: 20px;
    }
}.product-table-item__col-two {
     width: 73.46939%;
     float: right;
     margin-right: 0;
 }
@media screen and (min-width:48em) {
    .product-table-item__col-two {
        width: calc(100% - 280px);
        margin-right: 20px;
        float: left;
    }
}.product-table-item__col-three {
     width: 73.46939%;
     float: right;
     margin-right: 0;
 }
@media screen and (min-width:48em) {
    .product-table-item__col-three {
        width: 100px;
        float: left;
        margin-right: 20px;
        text-align: left;
    }
}.product-table-item__col-four {
     width: 73.46939%;
     float: right;
     margin-right: 0;
 }
.product-table-item-title__link {
    display: block;
    text-decoration: underline;
    color: #2A2A2A;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 1.47928rem;
    padding-top: .02072rem;
}
@media screen and (min-width:31.25em) {
    .product-table-item {
        padding-bottom: .92153rem;
        margin-top: .5934rem;
    }
    .product-table-item-title__link {
        margin-bottom: 1.47928rem;
        padding-top: .02072rem;
    }
}@media screen and (min-width:48em) {
    .product-table-item__col-four {
        width: 150px;
        padding-left: 10px;
        float: left;
        text-align: right;
    }
    .product-table-item-title__link {
        margin-bottom: 1.47928rem;
        padding-top: .02072rem;
    }
}@media screen and (min-width:64em) {
    .product-table-item {
        padding-bottom: .92153rem;
        margin-top: .5934rem;
    }
    .product-table-item-title__link {
        margin-bottom: 1.47928rem;
        padding-top: .02072rem;
    }
}@media screen and (min-width:83.5em) {
    .product-table-item {
        padding-bottom: .92153rem;
        margin-top: .5934rem;
    }
    .product-table-item-title__link {
        margin-bottom: 1.47928rem;
        padding-top: .02072rem;
    }
    .product-table-item-info, .product-table-item__key {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}.product-table-item-info, .product-table-item__key {
     line-height: 1rem;
     margin-bottom: .39533rem;
     padding-top: .10467rem;
 }
.product-table-item__key {
    color: rgb(200, 0, 42);
    width: 50px;
    width: 4.7619rem;
}
.product-table-item__price {
    color: rgb(200, 0, 42);
    width: 100%;
    text-align: right;
}
.product-table-item-item-actions {
    margin-top: 2.625rem;
}
@media screen and (min-width:31.25em) {
    .product-table-item-info, .product-table-item__key {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
    .product-table-item-item-actions {
        margin-top: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .product-table-item-info, .product-table-item__key {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
    .product-table-item-item-actions {
        margin-top: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .product-table-item-info, .product-table-item__key {
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
    .product-table-item-item-actions {
        margin-top: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .product-table-item-item-actions {
        margin-top: 2.56215rem;
    }
}.product-table-item-add-remove {
     position: relative;
     top: 3px;
     color: #2A2A2A;
     display: inline-block;
     background: #FFF;
 }
.product-table-item-add-remove__svg {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
}
.product-table-item-add-remove__svg--first {
    margin-right: .57143rem!important;
}
.product-table-item-add-remove__svg--last {
    margin-left: .57143rem!important;
}
.product-table-item-add-remove__name {
    margin-right: .95238rem!important;
    margin-left: .95238rem!important;
    font-weight: inherit;
}
.product-table-footer {
    margin-top: 1.96875rem;
    margin-bottom: 1.3125rem;
    text-align: right;
}
@media screen and (min-width:31.25em) {
    .product-table-footer {
        margin-top: 1.9059rem;
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .product-table-footer {
        margin-top: 1.9059rem;
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .product-table-footer {
        margin-top: 1.9059rem;
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .product-table-footer {
        margin-top: 1.9059rem;
        margin-bottom: 1.24965rem;
    }
}.product-table-footer__text {
     color: #2A383F;
     font-weight: 300;
 }
.product-table-footer__price {
    color: #2A383F;
    font-weight: 700;
    margin-left: 1.14286rem;
}
.search-address-header__paragraph, .search-address-item {
    font-weight: 300;
    color: #FFF;
}
.search-address {
    padding-bottom: 3.9375rem;
    margin-bottom: 3.9375rem;
    background-color: #2A383F;
}
@media screen and (min-width:31.25em) {
    .search-address {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .search-address {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .search-address {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .search-address {
        padding-bottom: 3.87465rem;
        margin-bottom: 3.87465rem;
    }
}.search-address-header:after, .search-address-header:before {
     display: table;
     content: ""}
.search-address-header:after {
    clear: both;
}
.search-address-header__title {
    color: #FFF;
    padding-bottom: 1.3125rem;
}
.search-address-section-form {
    margin-top: .65625rem;
}
@media screen and (min-width:31.25em) {
    .search-address-header__title {
        padding-bottom: 1.24965rem;
    }
    .search-address-section-form {
        margin-top: .5934rem;
    }
}@media screen and (min-width:48em) {
    .search-address-header__title {
        padding-bottom: 1.24965rem;
    }
    .search-address-section-form {
        margin-top: .5934rem;
    }
}@media screen and (min-width:64em) {
    .search-address-header__title {
        padding-bottom: 1.24965rem;
    }
    .search-address-section-form {
        margin-top: .5934rem;
    }
}@media screen and (min-width:83.5em) {
    .search-address-header__title {
        padding-bottom: 1.24965rem;
    }
    .search-address-section-form {
        margin-top: .5934rem;
    }
}.search-address-section-results {
     margin-top: 1.96875rem;
 }
.search-address-section-results__title {
    color: #FFF;
}
.search-address-section-results__paragraph {
    color: #FFF;
    margin-bottom: 1.96875rem;
    font-weight: 300;
}
.search-address-item {
    cursor: pointer;
    border-top: 1px rgba(255, 255, 255, .25) solid;
    position: relative;
    -webkit-transition: background-color .1s;
    transition: background-color .1s;
    padding: .98438rem 2.28571rem 1.3125rem 1.14286rem;
}
@media screen and (min-width:31.25em) {
    .search-address-section-results {
        margin-top: 1.9059rem;
    }
    .search-address-section-results__paragraph {
        margin-bottom: 1.9059rem;
    }
    .search-address-item {
        padding-top: .92153rem;
        padding-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .search-address-section-results {
        margin-top: 1.9059rem;
    }
    .search-address-section-results__paragraph {
        margin-bottom: 1.9059rem;
    }
    .search-address-item {
        padding-top: .92153rem;
        padding-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .search-address-section-results {
        margin-top: 1.9059rem;
    }
    .search-address-section-results__paragraph {
        margin-bottom: 1.9059rem;
    }
    .search-address-item {
        padding-top: .92153rem;
        padding-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .search-address-section-results {
        margin-top: 1.9059rem;
    }
    .search-address-section-results__paragraph {
        margin-bottom: 1.9059rem;
    }
    .search-address-item {
        padding-top: .92153rem;
        padding-bottom: 1.24965rem;
    }
}.search-address-item p {
     font-weight: inherit;
 }
.search-address-item:last-child {
    border-bottom: 1px rgba(255, 255, 255, .25) solid;
}
.search-address-item:before {
    display: none;
}
.search-address-item:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, .25);
}
.search-address-item:hover:before {
    display: block;
    position: absolute;
    top: calc(50% - 10px);
    right: 12px;
    right: 1.14286rem;
    content: "+";
    text-align: center;
    margin: 0;
    padding: 0;
    line-height: 14px;
    line-height: 1.33333rem;
    color: #FFF;
    border-radius: 50%;
    border: 1px solid #FFF;
    width: 15px;
    width: 1.42857rem;
    height: 15px;
    height: 1.42857rem;
}
.alert-box, .alert-box:after, .alert-box:before {
    display: table;
}
.alert-box:after, .alert-box:before, .filter:after, .filter:before, .hero-image:before, .hero:after, .hero:before, .page-header:after, .page-header:before, .page-hero:after, .page-hero:before, .teaser-image:before {
    content: ""}
.alert-box {
    padding: 1.90476rem;
    margin-top: 2.625rem;
    margin-bottom: 2.625rem;
    border: 1px solid #C8002A;
    width: 100%;
    box-shadow: 5px 5px 30px #ccc;
}
@media screen and (min-width:31.25em) {
    .alert-box {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .alert-box {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
    .alert-box-image {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
    }
}@media screen and (min-width:64em) {
    .alert-box {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .alert-box {
        margin-top: 2.56215rem;
        margin-bottom: 2.56215rem;
    }
}.alert-box:after {
     clear: both;
 }
.alert-box__title {
    color: #C8002A;
}
.alert-box-image {
    width: 100px;
    width: 9.52381rem;
    padding: 0 1.42857rem;
    margin: 0 auto;
}
.alert-box-text__info {
    color: #000;
    font-weight: 700;
    padding-top: 0;
}
.alert-box-action {
    clear: both;
    margin-top: 2.625rem;
}
@media screen and (min-width:48em) {
    .alert-box-text {
        display: table-cell;
        padding-left: 1.90476rem;
        text-align: left;
        vertical-align: middle;
    }
    .alert-box-action {
        margin-top: 2.56215rem;
    }
}.hero {
     background-image: -webkit-gradient(linear, left top, left bottom, from(#C8002A), to(#620015));
     background-image: linear-gradient(-180deg, #C8002A 0, #620015 100%);
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
     width: 100%}
.hero:after, .hero:before {
    display: table;
}
.hero:after {
    clear: both;
}
.hero-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    position: relative;
    overflow: hidden;
}
@media screen and (min-width:48em) {
    .hero {
        display: table;
    }
    .hero-image {
        display: table-cell;
        width: 50%}
}@media screen and (min-width:64em) {
    .alert-box-action {
        margin-top: 2.56215rem;
    }
    .hero-image {
        width: 67%}
}.hero-content {
     -webkit-box-ordinal-group: 3;
     -ms-flex-order: 2;
     order: 2;
     width: 100%;
     padding-top: 1.96875rem;
     padding-bottom: 3.9375rem;
 }
@media screen and (min-width:31.25em) {
    .alert-box-action {
        margin-top: 2.56215rem;
    }
    .hero-content {
        padding-top: 1.9059rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .hero-content {
        padding-top: 1.9059rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .alert-box-action {
        margin-top: 2.56215rem;
    }
    .hero-content {
        padding-top: 1.9059rem;
        padding-bottom: 3.87465rem;
    }
}.hero-content__inner {
     max-width: 80%;
     margin: 0 auto;
 }
.hero-content__title {
    color: #FFF;
    margin-bottom: 1.3125rem;
}
@media screen and (min-width:31.25em) {
    .hero-content__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:48em) {
    .hero-content {
        padding: 0 .47619rem 2.38095rem;
        width: 50%;
        display: table-cell;
        vertical-align: middle;
    }
    .hero-content__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:64em) {
    .hero-content {
        width: 33%}
    .hero-content__title {
        margin-bottom: 1.24965rem;
    }
}@media screen and (min-width:83.5em) {
    .hero-content__title {
        margin-bottom: 1.24965rem;
    }
}.hero-content__info {
     color: #FFF;
 }
.hero-image:before {
    display: block;
    width: 100%;
    padding-top: 56.25%}
.hero-image>.spot-image-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
@media screen and (min-width:48em) {
    .hero-image {
        position: relative;
        overflow: hidden;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
        margin-bottom: 0;
    }
    .hero-image:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 75%}
    .hero-image>.spot-image-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}@media screen and (min-width:64em) {
    .hero-image {
        position: relative;
        overflow: hidden;
    }
    .hero-image:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 42.85714%}
    .hero-image>.spot-image-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}.feature-image, .panel, .panel .active {
     position: relative;
 }
.feature-image {
    padding-top: 3.9375rem;
    padding-bottom: 3.9375rem;
    text-align: center;
}
@media screen and (min-width:31.25em) {
    .feature-image {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .feature-image {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
    .filter-icon__svg {
        margin-right: .47619rem;
    }
}@media screen and (min-width:64em) {
    .feature-image {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .feature-image {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}.filter {
     background-color: #CACDCF;
     border-radius: 4px;
 }
.filter:after, .filter:before {
    display: table;
}
.filter-icon, .filter__select {
    float: left;
    display: block;
    background-color: #FFF;
}
.filter:after {
    clear: both;
}
.filter-icon, .filter__select {
    height: 40px;
    height: 3.80952rem;
    padding-left: 1.42857rem;
    padding-right: 1.42857rem;
}
.filter-paging, .product-view {
    padding-right: 1.33333rem;
    margin-bottom: 0;
    padding-top: 0;
    color: #2A2A2A;
}
.filter__select {
    width: 100px;
    border: 1px solid #CACDCF;
    border-left: 0;
    border-radius: 0;
}
.filter__select:first-child {
    border-left: 1px #CACDCF solid;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.filter-icon {
    border: 1px solid #CACDCF;
    border-left: 0;
    margin-bottom: 0;
    color: #2A2A2A;
}
.filter-icon__svg {
    width: 18px;
    height: 90%;
    display: inline-block;
    vertical-align: middle;
}
.filter-icon__text {
    display: none;
}
@media screen and (min-width:48em) {
    .filter-icon__text {
        display: inline-block;
        vertical-align: middle;
    }
}.filter__col-two {
     text-align: right;
 }
.filter-paging {
    display: inline-block;
}
.filter-paging__text {
    display: inline-block;
    line-height: 40px;
    line-height: 3.80952rem;
    margin-left: .57143rem;
}
.product-view {
    display: none;
    border-right: 1px #fff solid;
}
@media screen and (min-width:48em) {
    .product-view {
        display: inline-block;
    }
}.product-view__size {
     display: inline-block;
     line-height: 40px;
     line-height: 3.80952rem;
     margin-left: .57143rem;
 }
.product-view__link {
    display: inline-block;
    text-decoration: underline;
    color: #2A2A2A;
}
.product-view__link:active, .product-view__link:hover, .product-view__link:visited {
    color: #2A2A2A;
}
.page-header {
    padding-top: 2.625rem;
    padding-bottom: 3.28125rem;
}
.page-header:after, .page-header:before {
    display: table;
}
.page-header:after {
    clear: both;
}
@media screen and (min-width:31.25em) {
    .page-header {
        padding-top: 2.56215rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:48em) {
    .page-header {
        padding-top: 2.56215rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:83.5em) {
    .page-header {
        padding-top: 2.56215rem;
        padding-bottom: 3.2184rem;
    }
}@media screen and (min-width:64em) {
    .page-header {
        padding-top: 2.56215rem;
        padding-bottom: 3.2184rem;
    }
    .page-header__col-one {
        width: 40%;
        float: left;
        margin-right: 2.85714%}
    .page-header__col-two {
        width: 57.14286%;
        float: right;
        margin-right: 0;
    }
}.page-header__title {
     line-height: 3.5rem;
     margin-bottom: .16761rem;
     padding-top: .83239rem;
 }
.page-header__text {
    margin-top: 1.64062rem;
}
@media screen and (min-width:31.25em) {
    .page-header__title {
        line-height: 3.5rem;
        margin-bottom: .16761rem;
        padding-top: .83239rem;
    }
    .page-header__text {
        margin-top: 1.57778rem;
    }
}@media screen and (min-width:48em) {
    .page-header__title {
        line-height: 3.5rem;
        margin-bottom: .28493rem;
        padding-top: .71507rem;
    }
    .page-header__text {
        margin-top: 1.57778rem;
    }
}@media screen and (min-width:64em) {
    .page-header__title {
        line-height: 3.5rem;
        margin-bottom: .28493rem;
        padding-top: .71507rem;
    }
    .page-header__text {
        margin-top: 1.57778rem;
    }
}@media screen and (min-width:83.5em) {
    .page-header__title {
        line-height: 3.5rem;
        margin-bottom: .28493rem;
        padding-top: .71507rem;
    }
    .page-header__text {
        margin-top: 1.57778rem;
    }
}.page-header__product-count {
     font-weight: 300;
 }
.page-hero {
    background-color: rgba(200, 0, 42, .9);
}
.page-hero:after, .page-hero:before {
    display: table;
}
.page-hero:after {
    clear: both;
}
.page-hero-content {
    display: table;
    width: 100%;
    height: 100%}
.page-hero-content__inner {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
}
@media screen and (min-width:31.25em) {
    .page-hero-content__inner {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .page-hero-content__inner {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .page-hero-content__inner {
        padding-top: 2.56215rem;
        padding-bottom: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .page-hero-content__inner {
        padding-top: 3.9375rem;
        padding-bottom: 3.9375rem;
    }
}@media screen and (min-width:48em) and (min-width:31.25em) {
    .page-hero-content__inner {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:48em) {
    .page-hero-content__inner {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:64em) {
    .page-hero-content__inner {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) and (min-width:83.5em) {
    .page-hero-content__inner {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .page-hero-content__inner {
        padding-top: 5.25rem;
        padding-bottom: 5.25rem;
    }
}@media screen and (min-width:64em) and (min-width:31.25em) {
    .page-hero-content__inner {
        padding-top: 5.18715rem;
        padding-bottom: 5.18715rem;
    }
}@media screen and (min-width:64em) and (min-width:48em) {
    .page-hero-content__inner {
        padding-top: 5.18715rem;
        padding-bottom: 5.18715rem;
    }
}@media screen and (min-width:64em) and (min-width:64em) {
    .page-hero-content__inner {
        padding-top: 5.18715rem;
        padding-bottom: 5.18715rem;
    }
}@media screen and (min-width:64em) and (min-width:83.5em) {
    .page-hero-content__inner {
        padding-top: 5.18715rem;
        padding-bottom: 5.18715rem;
    }
}.page-hero-content__title {
     color: #FFF;
     max-width: 80%;
     text-align: center;
     margin: 0 auto 1.89533rem;
     line-height: 1rem;
     font-size: 1.198rem;
     padding-top: .10467rem;
 }
@media screen and (min-width:31.25em) {
    .page-hero-content__title {
        font-size: 1.198rem;
        margin-bottom: 1.89533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:64em) {
    .page-hero-content__title {
        font-size: 1.198rem;
        margin-bottom: 1.89533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:83.5em) {
    .page-hero-content__title {
        font-size: 1.198rem;
        margin-bottom: 1.89533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:48em) {
    .page-hero-content__title {
        font-size: 1.198rem;
        margin-bottom: 1.89533rem;
        padding-top: .10467rem;
        width: 50%}
    .page-hero-content__info {
        width: 50%}
}.page-hero-content__info {
     font-weight: 400;
     color: #FFF;
     text-transform: uppercase;
     max-width: 80%;
     text-align: center;
     margin: 0 auto;
 }
.promotion-content__title, .section-header__caption {
    font-weight: 700;
    text-transform: uppercase;
}
.page-hero-action {
    margin-top: 5.25rem;
    text-align: center;
}
.panel--bg {
    background: #F4F5F5;
}
.panel .active, .panel-content {
    background: #FFF;
    border-left: #C8002A solid 1px;
    border-right: #C8002A solid 1px;
    border-top: #C8002A solid 1px;
    z-index: 9999;
}
.panel:first-child {
    display: none;
}
.panel:first-child .panel-header__link {
    text-decoration: none;
    color: #C8002A;
}
.panel:last-child {
    margin-bottom: 2.625rem;
}
@media screen and (min-width:31.25em) {
    .page-hero-action {
        margin-top: 5.18715rem;
    }
    .panel:last-child {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .page-hero-action {
        margin-top: 5.18715rem;
    }
    .panel:last-child {
        margin-bottom: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .page-hero-action {
        margin-top: 5.18715rem;
    }
    .panel:last-child {
        margin-bottom: 2.56215rem;
    }
}.panel .active .link-button__svg {
     -webkit-transform: rotate(90deg);
     transform: rotate(90deg);
 }
@media screen and (min-width:48em) {
    .page-hero-action {
        margin-top: 5.18715rem;
    }
    .panel--order-items .panel-header__col-one, .panel--order-items .panel-header__col-three, .panel--order-items .panel-header__col-two {
        width: 15%}
    .panel--order-items .panel-header__col-four {
        width: 40%}
    .panel--order-items .panel-header__col-five {
        width: 15%}
    .panel--prev-order-items .panel-header__col-one, .panel--prev-order-items .panel-header__col-three, .panel--prev-order-items .panel-header__col-two {
        width: 33.3%}
    .panel--ticket-items .panel-header__col-one {
        width: 15%}
    .panel--ticket-items .panel-header__col-two {
        width: 55%}
    .panel--ticket-items .panel-header__col-four, .panel--ticket-items .panel-header__col-three {
        width: 15%}
    .panel:first-child {
        display: block;
    }
    .panel:last-child {
        margin-bottom: 2.56215rem;
    }
    .panel-header {
        display: table;
        width: 100%}
}.panel-header--inline {
     display: inline-block;
     padding: .95238rem 1.42857rem;
     width: auto;
 }
.panel-header__info {
    color: #000;
    text-align: left;
    line-height: 1.5rem;
    font-size: 1.198rem;
    margin-bottom: .14533rem;
    padding-top: .35467rem;
}
@media screen and (min-width:31.25em) {
    .panel-header__info {
        font-size: 1.198rem;
        margin-bottom: .14533rem;
        padding-top: .35467rem;
    }
}@media screen and (min-width:48em) {
    .panel-header__info {
        font-size: 1.198rem;
        margin-bottom: .14533rem;
        padding-top: .35467rem;
    }
}@media screen and (min-width:64em) {
    .panel-header__info {
        font-size: 1.198rem;
        margin-bottom: .14533rem;
        padding-top: .35467rem;
    }
}@media screen and (min-width:83.5em) {
    .panel-header__info {
        font-size: 1.198rem;
        margin-bottom: .14533rem;
        padding-top: .35467rem;
    }
    .panel-header__title {
        font-size: 1.198rem;
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}.panel-header__title {
     color: #C8002A;
     text-align: left;
     line-height: 1rem;
     font-size: 1.198rem;
     margin-bottom: .39533rem;
     padding-top: .10467rem;
 }
.section-block--center, .section-footer--center {
    text-align: center;
}
@media screen and (min-width:31.25em) {
    .panel-header__title {
        font-size: 1.198rem;
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:48em) {
    .panel-header__title {
        font-size: 1.198rem;
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}@media screen and (min-width:64em) {
    .panel-header__title {
        font-size: 1.198rem;
        margin-bottom: .39533rem;
        padding-top: .10467rem;
    }
}.panel-header__col-five, .panel-header__col-four, .panel-header__col-one, .panel-header__col-three, .panel-header__col-two {
     padding: 1.42857rem;
 }
.panel-header .panel-header__title.mob {
    display: block;
}
@media screen and (min-width:48em) {
    .panel-header__col-one, .panel-header__col-three, .panel-header__col-two {
        display: table-cell;
        vertical-align: top;
    }
}.panel-header__col-four span {
     display: block;
 }
.panel-content {
    padding: 0 1.90476rem;
    border-bottom: #C8002A solid 1px;
    position: absolute;
    top: 66px;
    left: 0;
    width: 100%}
.panel-content__title {
    padding-top: 2.28571rem;
    margin-bottom: 0;
}
.panel-content-text {
    padding-top: 1.96875rem;
    padding-bottom: 1.96875rem;
    margin-bottom: 0;
    border-bottom: solid 1px #C9CDCF;
}
@media screen and (min-width:31.25em) {
    .panel-content-text {
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
        margin-bottom: -.06285rem;
    }
}@media screen and (min-width:48em) {
    .panel-header__col-five, .panel-header__col-four {
        display: table-cell;
        vertical-align: top;
    }
    .panel-content {
        position: relative;
        top: auto;
        left: auto;
        border-top: none;
        z-index: 999;
    }
    .panel-content:before {
        content: "";
        height: 1px;
        width: 100%;
        background: #C8002A;
        position: absolute;
        top: -1px;
        left: 0;
    }
    .panel-content-text {
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
        margin-bottom: -.06285rem;
    }
}@media screen and (min-width:64em) {
    .panel-content-text {
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
        margin-bottom: -.06285rem;
    }
}@media screen and (min-width:83.5em) {
    .panel-content-text {
        padding-top: 1.9059rem;
        padding-bottom: 1.9059rem;
        margin-bottom: -.06285rem;
    }
}.panel-content-text:last-child {
     border-bottom: none;
     margin-bottom: 0;
 }
.panel-content-text__message {
    margin-top: .65625rem;
    margin-left: .47619rem;
    padding-left: 1.71429rem;
}
.pop-up {
    overflow-y: scroll;
    -webkit-box-shadow: -5px 5px 30px rgba(0, 0, 0, .5);
    box-shadow: -5px 5px 30px rgba(0, 0, 0, .5);
    position: fixed;
    top: 10%;
    bottom: 10%;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    min-height: 10em;
    width: 90%;
    max-width: 750px;
    background: #FFF;
    z-index: 999999;
}
.spot__link, .teaser, .teaser-image {
    position: relative;
}
.spot__link, .teaser__link {
    z-index: 1;
    text-decoration: none;
}
.dark {
    background: rgba(0, 0, 0, .2);
}
.promotion {
    display: table;
    width: 100%}
.promotion-icon {
    width: 50px;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-top: .60467rem;
}
.promotion-icon__svg {
    height: 64px;
    width: 64px;
}
.promotion-content {
    width: calc(100% - 70px);
    padding-left: 20px;
    display: table-cell;
    height: 100%}
.spot:hover:before, .spot__link, .teaser__link {
    display: block;
}
.promotion-content__text {
    color: #666;
}
.section-block {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
}
@media screen and (min-width:31.25em) {
    .panel-content-text__message {
        margin-top: .5934rem;
    }
    .section-block {
        padding-top: 6.49965rem;
        padding-bottom: 6.49965rem;
    }
}@media screen and (min-width:48em) {
    .panel-content-text__message {
        margin-top: .5934rem;
    }
    .section-block {
        padding-top: 6.49965rem;
        padding-bottom: 6.49965rem;
    }
}@media screen and (min-width:83.5em) {
    .panel-content-text__message {
        margin-top: .5934rem;
    }
    .section-block {
        padding-top: 6.49965rem;
        padding-bottom: 6.49965rem;
    }
}@media screen and (min-width:64em) {
    .panel-content-text__message {
        margin-top: .5934rem;
    }
    .section-block {
        padding-top: 6.49965rem;
        padding-bottom: 6.49965rem;
    }
    .section-block__content {
        padding-left: 17.14286%;
        padding-right: 17.14286%}
}.section-block--bg-light {
     background: #EEE;
 }
.section-block--bg-dark {
    background: #2A383F;
}
.section-footer {
    padding-top: 3.9375rem;
    padding-bottom: 3.9375rem;
}
@media screen and (min-width:31.25em) {
    .section-footer {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .section-footer {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .section-footer {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .section-footer {
        padding-top: 3.87465rem;
        padding-bottom: 3.87465rem;
    }
}.section-header {
     padding-top: 3.28125rem;
     padding-bottom: 3.9375rem;
 }
@media screen and (min-width:31.25em) {
    .section-header {
        padding-top: 3.2184rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:48em) {
    .section-header {
        padding-top: 3.2184rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:64em) {
    .section-header {
        padding-top: 3.2184rem;
        padding-bottom: 3.87465rem;
    }
}@media screen and (min-width:83.5em) {
    .section-header {
        padding-top: 3.2184rem;
        padding-bottom: 3.87465rem;
    }
    .section-header__caption {
        font-size: .94181rem;
        margin-bottom: -.18921rem;
        padding-top: .18921rem;
    }
}.section-header__caption {
     text-align: center;
     color: #7F7F7F;
     line-height: 1rem;
     font-size: .94181rem;
     margin-bottom: -.18921rem;
     padding-top: .18921rem;
 }
@media screen and (min-width:31.25em) {
    .section-header__caption {
        font-size: .94181rem;
        margin-bottom: -.18921rem;
        padding-top: .18921rem;
    }
}@media screen and (min-width:48em) {
    .section-header__caption {
        font-size: .94181rem;
        margin-bottom: -.18921rem;
        padding-top: .18921rem;
    }
}@media screen and (min-width:64em) {
    .section-header__caption {
        font-size: .94181rem;
        margin-bottom: -.18921rem;
        padding-top: .18921rem;
    }
    .section-header__sub-title {
        font-size: 1.93829rem;
        margin-bottom: .13962rem;
    }
}.section-header__caption--left {
     text-align: left;
 }
.section-header__title {
    text-align: center;
}
.section-header__title--left {
    text-align: left;
}
.section-header__sub-title {
    font-weight: 400;
    text-align: center;
    line-height: 1rem;
    font-size: 1.93829rem;
    margin-bottom: .13962rem;
}
.section-header__sub-title--left {
    text-align: left;
}
@media screen and (min-width:31.25em) {
    .section-header__sub-title {
        font-size: 1.93829rem;
        margin-bottom: .13962rem;
    }
}@media screen and (min-width:48em) {
    .section-header__sub-title {
        font-size: 1.93829rem;
        margin-bottom: .13962rem;
    }
}@media screen and (min-width:83.5em) {
    .section-header__sub-title {
        font-size: 1.93829rem;
        margin-bottom: .13962rem;
    }
}.spot-image {
     margin-bottom: .82031rem;
 }
@media screen and (min-width:31.25em) {
    .spot-image {
        margin-bottom: .75747rem;
    }
}@media screen and (min-width:48em) {
    .spot-image {
        margin-bottom: .75747rem;
    }
}@media screen and (min-width:64em) {
    .spot-image {
        margin-bottom: .75747rem;
    }
}@media screen and (min-width:83.5em) {
    .spot-image {
        margin-bottom: .75747rem;
    }
}.spot-content__title {
     padding-top: 0;
     margin-bottom: 0;
     font-weight: 700;
 }
.spot-content__read-more {
    color: #C8002A;
    font-weight: 700;
}
.spot-content__svg {
    height: 8px;
    width: 6px;
}
.teaser-image {
    overflow: hidden;
}
.teaser-image:before {
    display: block;
    width: 100%;
    padding-top: 75%}
.teaser-image>.teaser-image-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.teaser__link {
    height: 100%;
    width: 100%}
.teaser-content {
    padding: 1.90476rem;
    background: rgba(42, 56, 63, .89);
    position: absolute;
    bottom: 4%;
    left: 4%;
    right: 4%;
    border-radius: 4px;
    text-align: center;
}
.teaser-content__title {
    color: #FFF;
    margin-bottom: 0;
}
.teaser-content__info {
    color: #FFF;
    font-weight: 300;
    margin-bottom: 2.625rem;
}
.teaser-content__read-more {
    color: #C8002A;
    font-weight: 700;
}
.teaser-content__svg {
    height: 8px;
    width: 6px;
}
#google_translate_element {
    position: absolute;
    right: 1.96078%;
    top: 2.625rem;
}
@media screen and (min-width:31.25em) {
    .teaser-content__info {
        margin-bottom: 2.56215rem;
    }
    #google_translate_element {
        top: 2.56215rem;
    }
}@media screen and (min-width:48em) {
    .teaser-content__info {
        margin-bottom: 2.56215rem;
    }
    #google_translate_element {
        top: 2.56215rem;
    }
}@media screen and (min-width:64em) {
    .teaser-content__info {
        margin-bottom: 2.56215rem;
    }
    #google_translate_element {
        top: 2.56215rem;
    }
}@media screen and (min-width:83.5em) {
    .teaser-content__info {
        margin-bottom: 2.56215rem;
    }
    #google_translate_element {
        top: 2.56215rem;
    }
}.goog-te-gadget-simple, .goog-te-menu-value span {
     border: none!important;
 }
.goog-te-menu-value span {
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    font-size: 1.45238rem;
    margin-bottom: .47928rem;
    padding-top: .52072rem;
}
.hamburger {
    padding: 5px 10px 2px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition-property: opacity, -webkit-filter;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}
.hamburger:hover {
    opacity: .7;
}
.hamburger-box {
    width: 20px;
    height: 14px;
    display: inline-block;
    position: relative;
}
.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
    width: 20px;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}
.hamburger-inner::after, .hamburger-inner::before {
    content: "";
    display: block;
}
.hamburger-inner::before {
    top: -6px;
}
.hamburger-inner::after {
    bottom: -6px;
}
.hamburger--squeeze .hamburger-inner {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
    transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}
.hamburger--squeeze .hamburger-inner::before {
    -webkit-transition: top 75ms .12s ease, opacity 75ms ease;
    transition: top 75ms .12s ease, opacity 75ms ease;
}
.hamburger--squeeze .hamburger-inner::after {
    -webkit-transition: bottom 75ms .12s ease, -webkit-transform 75ms cubic-bezier(.55, .055, .675, .19);
    transition: bottom 75ms .12s ease, -webkit-transform 75ms cubic-bezier(.55, .055, .675, .19);
    transition: bottom 75ms .12s ease, transform 75ms cubic-bezier(.55, .055, .675, .19);
    transition: bottom 75ms .12s ease, transform 75ms cubic-bezier(.55, .055, .675, .19), -webkit-transform 75ms cubic-bezier(.55, .055, .675, .19);
}
.hamburger--squeeze.is-active .hamburger-inner {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-delay: .12s;
    transition-delay: .12s;
    -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 75ms ease, opacity 75ms .12s ease;
    transition: top 75ms ease, opacity 75ms .12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: bottom 75ms ease, -webkit-transform 75ms .12s cubic-bezier(.215, .61, .355, 1);
    transition: bottom 75ms ease, -webkit-transform 75ms .12s cubic-bezier(.215, .61, .355, 1);
    transition: bottom 75ms ease, transform 75ms .12s cubic-bezier(.215, .61, .355, 1);
    transition: bottom 75ms ease, transform 75ms .12s cubic-bezier(.215, .61, .355, 1), -webkit-transform 75ms .12s cubic-bezier(.215, .61, .355, 1);
}

div.cookie-warning.react-cookie-banner {
    padding: 15px 30px!important;
    align-items: center;
    .cookie-message, button {
        font-size: 12px!important;
        @media screen and (min-width:1024px ) {
            font-size: 14px!important;
        }
    }
    button {
        min-width: 130px;
        @media screen and (max-width: 768px)  {
            width: 100%;
        }
    }
}

body.overflow {
    overflow: hidden;
}
