.synlab {
    .hero {
        background-color: #ffffff;
        background-image: none;
        max-width: 92.5%;
        margin-left: auto;
        margin-right: auto;


        @media (min-width: 31.25em) {
            max-width: 80%;
        }

        @media (min-width: 48em) {
            max-width: 95%; 
        }

        @media (min-width: 64em) {
            max-width: 90%
        }

        @media (min-width: 83.5em) {
            max-width: 80%;
        }


        @media (min-width: 100em) {
            max-width: 1268px;
        }


        &-content {  
            &__inner {
                margin: 0;
            }   
              &__title {
                font-weight: 600;
                color: #252836;
                letter-spacing: -1.08px;
            }

              &__info {
                color: #737688;
                letter-spacing: -0.36px;
                line-height: 26px;
            }

            &__certificate {
                display: none;
            }
        }

        &-image {
            .prev-thumb svg, .next-thumb svg{
                height: 25px;
                width: 25px;
            }

            &__slide {
                background-size: contain;
            }
        }
    }
}