.synlab {
    .product-card, .product {
        border-color: #E5EAEB;
        border-radius: 14px;

        &-content {
            color: #252836;

            &__title {
            letter-spacing: -0.54px;
            font-weight: 600;
            color: #252836;
            }

            &__text {
                letter-spacing: -0.45px;
                color: #737688;
            }

            &__price {
                color: #0077AD;
                letter-spacing: -0.63px;
                font-weight: 600;
            }
        }

        &-actions__col-one {
            border: 1px solid
        }
        &-actions__col-two {
            background-color: #0077AD;
            color: #ffffff;
            border-color: #0077AD;
            border-radius: 4px;
            transform: translateX(-5px)
        }

        &-actions {
            &__select {
                border-right: 1px solid #2A383F;
            }
            &__current-price {
                background-color: #ffffff;
                color: #252836;
                letter-spacing: -0.25px;
                font-weight: 600;
                font-size: 11px;
            }          
        }
    }
}