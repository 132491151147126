.synlab {
    .order-summary {
        background-color: #0077AD;
    }

    .order-actions {
        padding-left: 0;
        padding-right: 0;
        background-color: #ffffff;
        &__title {
            color: #252836;
        }

        &-section {
            &__title, &__text {
                color: #252836;
            }

            &__text {
                color: #737688;
            }
        }
    }
}