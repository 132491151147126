/*section text*/
.synlab {
    .section-header {
        &__caption {
            text-transform: uppercase;
            color: #737688;
            letter-spacing: 1.3px;
            font-weight: 600;
        }
    
        &__title {
           line-height: 44px;
           font-weight: 500; 
           letter-spacing: -1.08px;
           color: #252836;
        }
    } 

    /*breadcrumb */
    .breadcrumb__link:active, 
    .breadcrumb__link:hover, 
    .breadcrumb__link:visited, 
    .breadcrumb__link .breadcrumb__name,
    .product-table-item__price, 
    .checkout-step-content__subtitle, 
    .field-wrapper__title, 
    .btn-link.btn-link__active, 
    .cart-item-price__text, 
    .cart-item-info__key,
    .panel-header__title .panel-header__link,
    .mobile-menu__link:hover, .mobile-menu__link:active {
        color: #0077AD;
    }

    h1, h2.h5, h5, h4, .cart-item-info__key, .cart-item-price__text, .breadcrumb, .product-table-item-title__link, .sidecart-header h2, .ant-collapse-header, .ant-collapse-header {
        font-weight: 500;
        color: #252836;
    }

    h2 {
        font-weight: 500;
    }

    /*Fonts*/

    .product-content__subtitle,
    .ant-collapse-content-box p,
    .main-content__primary p,
    .info-item p{
        color: #737688;
    }
}