.synlab {
    .react-tabs__tab-list,
    .react-tabs__tab--selected {
        border-color: #E5EAEB;
    }

    .filter {
        &__col-one {
            .filter-icon{
                border-color: #E5EAEB;
                color: #252836;
            }
        }
        &__col-two {
            background-color: #f5f5f5;
            border: 1px solid #E5EAEB;
            border-radius: 4px;
        }

        .product-view__link:active, .product-view__link:hover, .product-view__link:visited {
            color: #252836;
        }
    }

    //collapse 
    .main-content .ant-collapse {
        padding: 10px;
        border-radius: 14px;
        border: none;
        background-color: #F4F5F5;

        &-content-box {
            border: none;
            background-color: #F4F5F5;
        }
    }
    
    //Description 
    .product-information {
        border: none;
        background-color: #F5F5F5;
        border-radius: 14px;

        .ant-collapse-borderless {
            background-color: #F5F5F5;

            .ant-collapse-item {
                border: none;
            }
        }
    }
}