.synlab {
    .checkout-step {
        &-header {
            background-color: #F5F5F5;
            border-radius: 14px 14px 0 0;
        }

        &-content {
            border-color: #E5EAEB;
            border-radius: 0 0 14px 14px;
        }

        .ant-card.ant-card-bordered {
            border-radius: 14px 14px 14px 14px;
        }

        .ant-select-selection.ant-select-selection--single,
        .form textarea.ant-input {
            border-radius: 4px;
        }
    }
}