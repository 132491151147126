.synlab {
    .footer {
        background-color: #ffffff;
        margin-top: 10px;
        margin-bottom: 10px;
            .preamble {
                color: #252836 !important;
                letter-spacing: -0.44px;
                line-height: 34px;
                font-weight: 500;
            }

        &-list {
            &__link {
                color: #737688;
                letter-spacing: -0.26px;
                line-height: 20px;
                font-weight: 400;
            }
        }

        &-contact {
            border-color: #E5EAEB;
            &-list{
                &__link, &-icon{
                    color: #737688 !important;
                    letter-spacing: -0.3px;
                    line-height: 23px;
                    font-weight: 400;
                }
            }
        }

        &-copyright, &-copyright a, &-about__text, &-about__links {
            color: #737688;
            letter-spacing: -0.3px;
            line-height: 23px;
            border-color: #E5EAEB;
            font-weight: 400;
        }

        &__col-two {
            background-color: #ffffff;
            border-left: 1px solid #E5EAEB;
        }
    }
}