/*promotions start*/

.synlab {
    .promotions {
        max-width: 92.5%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 14px;
        background-color: #F5F5F5;

        @media (min-width: 31.25em) {
            max-width: 80%;
        }

        @media (min-width: 48em) {
            max-width: 95%; 
        }

        @media (min-width: 64em) {
            max-width: 90%
        }

        @media (min-width: 83.5em) {
            max-width: 80%;
        }


        @media (min-width: 100em) {
            max-width: 1268px;
        }

        .c {
          margin: 20px; 

          @media (min-width: 48em) {
            margin: 46px;
            }
        }
        &-content {
            margin-top: 1em;
            &__col-one {
                border-right: none;
            }
        }

        &__title {
            font-weight: 500;
            letter-spacing: -0.96px;
            line-height: 39px;
            color: #252836;
        }

        &__text {
            color: #737688;
            letter-spacing: -0.3px;
            line-height: 24px;
        }

        .promotion {
            &-content {
                &__title {
                    color: #252836;
                    letter-spacing: -0.36px;
                    line-height: 29px;
                    margin: 0;
                }
                &__text {
                    color: #737688;
                    letter-spacing: -0.3px;
                    line-height: 24px;
                }
            }
        }
    }

    
.bottom-promotion {
    background-color: #0077AD;

    &-list {
        &__item {
            font-weight: 500;
            letter-spacing: -0.26px;
        }

        &__svg {
            margin-right: 5px;
        }
    }
}

/*promotions end*/
}