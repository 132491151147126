.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px;
  background: #000000;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.2;
  display: none;

  justify-content: space-between;
  z-index: 999;
  @media (min-width: 960px) {
    flex-wrap: nowrap;
    align-items: center;
  }
}

.open {
  display: flex;
}

.content {
  margin-bottom: 20px;

  @media (min-width: 960px) {
    margin-right: 20px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.buttons {
   display: flex;
   width: 100%;
   margin-top: 20px;
  
   
  @media (min-width: 960px) {
    width: auto;
    margin-left: 10px;
    margin-top: 0;
  }
  button {
    min-width: 150px;
    background: #ffffff;
    color: #000000;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    border-radius: 26px;
    cursor: pointer;
    padding: 5px 10px;
  }
}

button.outlined {
  background: #000000;
  color: #ffffff;
}

.closeButtonContainer {
  display: flex;
  margin-left: 15px;

  @media (min-width: 960px) {
    margin-left: 10px;
  }

  cursor: pointer;
}
