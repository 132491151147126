.synlab {
    .logo {
         &__link {
             display: flex;
             align-items: center;
         }

         &__img {
            @media (min-width: 48em) {
                min-width: 200px;
            }
         }

         @media (min-width: 48em) {
             min-width: 200px;
         }
    }
}