.synlab {
    .ant-btn-primary, 
    .ant-btn-primary:focus, 
    .ant-btn-primary:hover, 
    .ant-btn-primary:active, 
    .field-content__button, 
    .sidecart-content-action__button, 
    .confirm-content__button, 
    .field-content__button {
        background-color: #0077AD;
        border-color: #0077AD;;
        border-radius: 4px;
        &:hover {
            color: #ffffff;
        }
    }

    .product-choices-list__item--active, 
    .product-choices-list__item:hover,
    .cookie-warning.react-cookie-banner .button-close {
        background-color: #0077AD !important;
    }

    .link-button {
        white-space: nowrap;
        text-align: center;
        border: 1px solid transparent;
        cursor: pointer;
        font-size: 14px;
        border-radius: 4px;
        background-color:  #0077AD;
        color: #ffffff;
        padding: 0 !important;
        margin-right: 10px;

        

        &__name {
            display: inline-block;
            padding: 10px 15px;
            font-weight: 500;
            &:hover {
                color: #ffffff;
            }
        }
    }
}