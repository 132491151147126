.synlab {
    background-color: #ffffff;
    .header {
        background-color: #ffffff;
        &__bottom {
            background-color: #ffffff;
        }
    } 
    .help-nav-list {
        &__item, .language-list a {
            color: #434652;
        }
        
        .cart-badge {
            background-color: #3D96BF;
            color: #ffffff;
        }
        &__text {
            font-weight: 600;
        }
        &-icon {
            &__svg {
                stroke: #252836 !important;
            }
        }
    }
    .main-nav-list {
        &__link {
            color: #434652;
            font-weight: 500;
        }
        &__link.active {
            background-color: #f5f5f5;
            color: #3D96BF;
        }
    }

    //search

    .search-form-wrapper {
        .search-form {
            &__screen-reader-text {
                color: #252836 !important;
            }
           .search-field {
               background-color: #0077AD;
               border-radius: 4px;
           } 

           .search-submit{
            background-color: #0077AD !important;
            border-radius: 4px;
           }
        }
    }


    //hamburger

    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        background-color: #252836;
    }
}